import CheckIcon from "@nodes/@material-ui/icons/Check";
import Card from "@nodes/@material-ui/core/Card";
import Typography from "@nodes/@material-ui/core/Typography";
import Box from "@nodes/@material-ui/core/Box";
import PersonIcon from "@nodes/@material-ui/icons/Person";
import { MdRestore } from "@nodes/react-icons/md";
import { Link } from "@nodes/react-router-dom";
import RenderPhoneNumber from "./RenderPhoneNumber";
import { printAbsAmount, getBadgeColor } from "../functions";
// import PhoneIcon from "@nodes/@material-ui/icons/Phone";

const XcobrarLoan = ({ loan }) => {
  return (
    <Box paddingBottom={1}>
      <Card raised>
        <table className="table table-sm">
          <tbody>
            <tr>
              <td colSpan="3">
                <Typography variant="subtitle2" component="p" className="font-weight-bold">
                  {/* <Link style={{ color: "#000" }} to={`/loan/detail/${loan.money_id}/${loan.debtor_id}`}> */}
                  <Link style={{ color: "#000" }} to={`/payment/create/${loan.money_id}/${loan.debtor_id}`}>
                    <PersonIcon /> {loan.name} {loan.nickname && `(${loan.nickname})`}
                  </Link>
                </Typography>
              </td>
            </tr>

            <tr>
              <td colSpan="2">
                <RenderPhoneNumber phone={loan.phone} />
              </td>
              <td align="right">
                {loan.new_loan && (
                  <Typography variant="body2" component="p" className="badge badge-pill badge-primary">
                    <small>Nuevo!</small>
                  </Typography>
                )}

                {loan.xRenewal === true && !loan.sale_hoy && (
                  <span className="badge badge-pill badge-dark">
                    <Typography variant="body1" component="p" style={{ fontSize: 13 }}>
                      <span style={{ padding: 2 }}>{loan.current_week}</span>
                    </Typography>
                  </span>
                )}

                {loan.sale_hoy && (
                  <Typography variant="body2" component="p" className="badge badge-pill badge-dark">
                    Sale Hoy!
                  </Typography>
                )}

                {(loan.paymentAmount > 0 || loan.paymentMora > 0) && (
                  <span className="text-success pl-2">
                    <CheckIcon />
                  </span>
                )}
                {loan.postPoned === true && (
                  <span className="pl-2 text-primary">
                    <MdRestore size="1.6em" />
                  </span>
                )}
              </td>
            </tr>

            <tr>
              <td style={{ width: 110 }}>
                {loan.statusText === "Al Dia" ? (
                  <Typography variant="body2" component="p" className="badge badge-pill badge-success">
                    Al Dia
                  </Typography>
                ) : (
                  <Typography variant="body2" component="p" className={getBadgeColor(loan.statusText)}>
                    <small>
                      {loan.statusText} {printAbsAmount(loan.statusAmount)}
                    </small>
                  </Typography>
                )}
              </td>
              <td>
                <Typography variant="body2" component="p">
                  {printAbsAmount(loan.wPayment)}
                  {" - "}
                  {loan.completed}/{loan.npayments}
                </Typography>
              </td>
              <td>
                <Typography variant="body2" component="p">
                  Balance {printAbsAmount(loan.balance)}
                </Typography>
              </td>
            </tr>
          </tbody>
        </table>
      </Card>
    </Box>
  );
};

export default XcobrarLoan;
