import { useParams } from "@nodes/react-router";
import Card from "@nodes/@material-ui/core/Card";
import CardContent from "@nodes/@material-ui/core/CardContent";
import CardHeader from "@nodes/@material-ui/core/CardHeader";
import Box from "@nodes/@material-ui/core/Box";
import { mixed, addMethod, string, object } from "@nodes/yup";
import { Form, RenderInput } from "@shared/components/Form";
import RenderBackButton from "@shared/components/RenderBackButton";
import { validateCedula } from "@shared/functions";
import Loading from "@shared/components/Loading";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "@nodes/react-router-dom";
import { useGetUserQuery } from "../store/slices/api";
import { useAlert } from "react-alert";
import { updatePersonal } from "@shared/api";

const UserPersonal = () => {
  const history = useHistory();
  const alert = useAlert();

  const { id } = useParams();

  const { data: user, refetch } = useGetUserQuery(id);

  if (!user) return <Loading />;

  const initialPersonalValues = {
    user_id: user.user_id,
    fname: user.fname,
    lname: user.lname,
    cedula: user.cedula,
    phone: user.phone,
    email: user.email,
    oldPhone: user.phone,
  };

  addMethod(mixed, "validateCedula", validateCedula);
  const validationPersonal = object({
    fname: string()
      .required("Nombre es requerido!")
      .min(3, "Nombre es requerido")
      .matches(/^([^0-9]*)$/, "Nombre no puede tener numeros!"),
    lname: string()
      .required("Apellido es requerido!")
      .min(3, "Apellido es requerido")
      .matches(/^([^0-9]*)$/, "Apellido no puede tener numeros!"),
    phone: string().required("Telefono es requerido").min(10, "Telefono invalido"),
    cedula: mixed().validateCedula(),
    email: string().email("Correo Electronico Invalido").max(35, "Correo Electronico es muy largo!"),
  });

  const buttonPersonal = {
    label: "Actualizar Informacion",
    variant: "contained",
    color: "primary",
  };

  const submithandlerPersonal = async (data) => {
    try {
      await updatePersonal(data);
      refetch();

      history.replace("/profile");
      alert.info("Informacion Guardada!");
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div className="mt-5">
      <Card raised>
        <CardHeader title="Informacion Personal" />
        <CardContent>
          <Typography variant="body1" paragraph>
            En esta pagina puede cambiar la Informaci&oacute;n personal!
          </Typography>
          <div>
            <Form
              initialValues={initialPersonalValues}
              validation={validationPersonal}
              submithandler={submithandlerPersonal}
              button={buttonPersonal}>
              <Box paddingBottom={2}>{RenderInput("fname", "Nombre")}</Box>
              <Box paddingBottom={2}>{RenderInput("lname", "Apellido")}</Box>
              <Box paddingBottom={2}>{RenderInput("phone", "Telefono", "tel")}</Box>
              <Box paddingBottom={2}>{RenderInput("email", "Correo")}</Box>
              <Box paddingBottom={4}>{RenderInput("cedula", "Cedula", "tel")}</Box>
            </Form>
            <div className="mt-4 mb-2">
              <RenderBackButton />
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default UserPersonal;
