import { useEffect } from "react";
import { UseCobroDeltaChanges } from "@shared/server/fetcher";
import { useSelector, useDispatch } from "react-redux";
import { selectCommon, updateLastCheckUpdate } from "../store/slices/common";
import { deleteNewLoan, modifyNewLoan, selectNewLoans } from "../store/slices/newLoans";
import { deletePayment, selectCollected } from "../store/slices/payments";
import { selectLoans, deleteLoan, updateLoan, createLoan } from "../store/slices/loans";
import { toast } from "react-toastify";
import backend from "@shared/server/backend";

const DeltaChanges = () => {
  const dispatch = useDispatch();
  const common = useSelector(selectCommon);
  const { data } = UseCobroDeltaChanges(common.creditor_id, common.lastCheckUpdate);

  const collected = useSelector(selectCollected);
  const loans = useSelector(selectLoans);
  const newLoans = useSelector(selectNewLoans);

  useEffect(() => {
    // console.log("UseEffect is being triggered", data);
    if (!data) return;
    async function proccessDeltas() {
      const deltasLength = data?.deltas?.length;

      for (let i = 0; i < deltasLength; i++) {
        try {
          const delta = data.deltas[i];

          const { data: rData } = await backend.get(
            `/cobro/loan/syncv2/${delta.mUniqueId}/${common.user_id}/${common.creditor_id}`
          );

          if (!rData) continue;

          const loan = loans.find((x) => x.mUniqueId === delta.mUniqueId && +x.is_active == 1);

          if (loan) {
            if (+rData.is_active === 0 && loan) {
              //Loan was deleted
              const filteredCollected = collected.filter((x) => x.money_id === rData.money_id);
              const collectedLength = filteredCollected.length;

              for (let i = 0; i < collectedLength; i++) {
                const payment2 = filteredCollected[i];
                dispatch(deletePayment(payment2));
                toast.warning(`App Central - Pago ${rData.name} borrado!`, {
                  autoClose: 9000,
                  hideProgressBar: true,
                });
              }

              dispatch(deleteLoan({ money_id: rData.money_id, is_active: rData.is_active }));
              toast.warning(`App Central - Prestamo de ${rData.name} borrado!`, {
                autoClose: 9000,
                hideProgressBar: true,
              });
            } else {
              if (delta.pUniqueId) {
                //Deleting a payment that was deleted at Central App
                const payment = collected.find((x) => x.pUniqueId === delta.pUniqueId);
                if (payment) {
                  dispatch(deletePayment(payment));
                  toast.warning(`App Central - Pago ${rData.name} borrado!`, {
                    autoClose: 9000,
                    hideProgressBar: true,
                  });
                }

                dispatch(updateLoan(rData));
              } else {
                //Loan was was modified, payments applied or actual modification
                dispatch(updateLoan(rData));
                toast.info(`App Central - Prestamo de ${rData.name} modificado!`, {
                  autoClose: 9000,
                  hideProgressBar: true,
                });
              }
            }
          } else {
            const newLoan = newLoans.find((x) => x.mUniqueId === delta.mUniqueId);
            if (newLoan) {
              if (+rData.is_active === 0) {
                dispatch(deleteNewLoan(rData));
                toast.warning(`App Central - Entrega de ${rData.name} borrada!`, {
                  autoClose: 9000,
                  hideProgressBar: true,
                });
              } else {
                dispatch(modifyNewLoan(rData));
                toast.info(`App Central - Entrega de ${rData.name} modificada!`, {
                  autoClose: 9000,
                  hideProgressBar: true,
                });
              }
            } else {
              if (rData.given_date !== common.collect_date) {
                dispatch(createLoan(rData));
                toast.success(`App Central - Prestamo de ${rData.name} creado!`, {
                  autoClose: 9000,
                  hideProgressBar: true,
                });
              }
            }
          }
        } catch (error) {
          console.log(error);
        }
      }

      dispatch(updateLastCheckUpdate({ lastCheckUpdate: data.timestamp }));
    }

    proccessDeltas();
    //eslint-disable-next-line
  }, [data]);

  return null;
};

export default DeltaChanges;
