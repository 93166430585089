
import Tooltip from "@nodes/@material-ui/core/Tooltip";
import Zoom from "@nodes/@material-ui/core/Zoom";
import Fab from "@nodes/@material-ui/core/Fab";
import { makeStyles } from "@nodes/@material-ui/core";
import { useHistory } from "@nodes/react-router-dom";

const useStyles = makeStyles(() => ({
  fab: {
    position: "fixed",
    bottom: "3%",
    right: "3%",
  },
}));

const handleOnClick = (history, goto) => {
  return goto ? history.push(goto) : null;
};

const FloatingButton = ({ icon, goto, tooltip }) => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <Zoom in>
      <Tooltip title={tooltip}>
        <Fab onClick={() => handleOnClick(history, goto)} color="primary" className={classes.fab}>
          {icon}
        </Fab>
      </Tooltip>
    </Zoom>
  );
};

export default FloatingButton;
