import SubmitButton from "@shared/components/SubmitButton";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { BiCloudDownload } from "react-icons/bi";

const LoansSync = () => {
  const submithandler = async () =>
    await cobroSyncLoans(user.user_id, route.creditor_id, loans, () => history.goBack());

  return (
    <div className="container mt-4 mb-5">
      <Card className="mt-3 mb-5" raised>
        <CardContent>
          <div>
            <Typography className="mt-3" component="h3" variant="h5">
              Sincronizar Datos!
            </Typography>

            <Typography className="mt-3 mb-3" component="p" variant="subtitle1">
              Actualizar los prestamos desde la aplicacion Central a la de Cobro.
            </Typography>

            <Typography className="mt-3 mb-3" component="p" variant="body1">
              Al sincronizar los datos desde la central, cualquier cambio realizado, nuevo prestamos, o
              cliente/prestamos borrados seran actualizado en esta applicacion.
            </Typography>

            <div className="mt-4">
              <SubmitButton
                text="Sincronizar Datos!"
                startIcon={<BiCloudDownload />}
                callBack={submithandler}
                autoFetch={false}
              />
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default LoansSync;
