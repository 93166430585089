import { Form, RenderInput } from "./Form";
import { string, object, addMethod, mixed } from "@nodes/yup";
import Grid from "@nodes/@material-ui/core/Grid";
import Typography from "@nodes/@material-ui/core/Typography";
import Box from "@nodes/@material-ui/core/Box";
import { validateCedula } from "../functions";

const RenderDebtorForm = ({ title, initialValues, submithandler, button, cedula }) => {
  addMethod(mixed, "validateCedula", validateCedula);
  const validation = object({
    name: string()
      .required("Nombre es requerido!")
      .min(6, "Nombre es muy corto!")
      .matches(/^([^0-9]*)$/, "Nombre no puede tener numeros!"),
    phone: string().required("Telefono es requerido!").min(10, "Telefono es muy corto!"),
    cedula: mixed().validateCedula(cedula === false ? false : true),
  });

  return (
    <div>
      <Typography variant="h5" component="h3" gutterBottom>
        {title}
      </Typography>

      <Form initialValues={initialValues} validation={validation} submithandler={submithandler} button={button}>
        <Box paddingBottom={2}>{RenderInput("name", "Nombre Completo", "text", true)}</Box>

        <Grid container>
          <Grid item xs={6}>
            <Box paddingBottom={2}>{RenderInput("cedula", "Cedula", "tel")}</Box>
          </Grid>
          <Grid item xs={6}>
            <Box paddingBottom={2}>{RenderInput("nickname", "Apodo")}</Box>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={6}>
            <Box paddingBottom={2}>{RenderInput("phone", "Telefono", "tel")}</Box>
          </Grid>
          <Grid item xs={6}>
            <Box paddingBottom={2}>{RenderInput("phone2", "Telefono2", "tel")}</Box>
          </Grid>
        </Grid>

        <Box paddingBottom={2}>{RenderInput("address", "Dir. Casa")}</Box>
        <Box paddingBottom={2}>{RenderInput("occupation", "Ocupacion")}</Box>
        <Box paddingBottom={2}>{RenderInput("work_address", "Dir. Trabajo")}</Box>
        <Box paddingBottom={2}>{RenderInput("next_to", "Proximo A")}</Box>
        <Box paddingBottom={2}>{RenderInput("cosigner", "Garante")}</Box>
        <Box paddingBottom={3}>{RenderInput("note", "Nota / Info")}</Box>
      </Form>
    </div>
  );
};

export default RenderDebtorForm;
