import esLocale from "@nodes/date-fns/locale/es";
import DateFnsUtils from "@nodes/@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@nodes/@material-ui/pickers";
import { newDate } from "@shared/functions";

function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();
  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  return [year, month, day].join("-");
}

function BasicDatePicker({ label, date, setDate, openTo = "date", disableFuture = true }) {
  const handleChange = (e) => {
    setDate(formatDate(e));
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
      <DatePicker
        disableFuture={disableFuture}
        fullWidth
        openTo={openTo}
        format="dd MMMM, yyyy"
        label={label}
        views={["year", "month", "date"]}
        value={date ? new Date(newDate(date)) : null}
        onChange={handleChange}
      />
    </MuiPickersUtilsProvider>
  );
}

export default BasicDatePicker;
