import { useState, useMemo } from "react";
import { useParams } from "react-router";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
// import Calculator from "@shared/components/Calculator";
// import { getDebtorSelector } from "@shared/context/Selectors";
import { getUniqueID, getTimestamp, printAmount, replaceNonDigits } from "@shared/functions";
import Alert from "@material-ui/lab/Alert";
import Grow from "@material-ui/core/Grow";
import { printEntregaHandler, printLoanContractHandler } from "@shared/functions/print";
// import BorderColorIcon from "@material-ui/icons/BorderColor";
import { MdOutlineVerified } from "react-icons/md";
// import DebtorFetch from "@shared/components/DebtorFetch";
// import DebtorNotAvailable from "@shared/components/DebtorNotAvailable";
import DebtorNameHeader from "@shared/components/DebtorNameHeader";
import RenderNewLoanCalculation from "@shared/components/RenderNewLoanCalculation";
import RenderNewLoanForm from "@shared/components/RenderNewLoanForm";
import LoanDetailMenu from "@shared/components/LoanDetailMenu";
import Loading from "@shared/components/Loading";
import LoadingInspect from "@shared/components/LoadingInspect";
import { useSelector, useDispatch } from "react-redux";
import { selectNewLoanByDebtorId } from "../store/slices/newLoans";
import { useGetDebtorsQuery, useGetUserConfigQuery } from "../store/slices/api";
// import CedulaWarning from "@shared/components/CedulaWarning";
import { useHistory, Link } from "react-router-dom";
// import { alertError, alertSuccess } from "../store/slices/snackbar";
import { useGetOwnerConfigQuery, useGetRouteQuery, useGetUserQuery } from "../store/slices/api";
import { useAlert } from "react-alert";
import { addDebtorsReordered } from "../store/slices/loans";
import usePouch from "../Hooks/usePouch";
import { pouches_db } from "../config";
import useData from "../Hooks/useData";
import usePouchQueues from "../Hooks/usePouchQueues";
import { syncTypes } from "../components/sync/getDataType";

const calcMonto = (percentage = 0, amount = 0, npayments = 0) => {
  const amount2 = amount * 1;
  const npayments2 = npayments * 1;
  const percentage2 = percentage * 1;
  if (npayments2 === 0) return 0;

  return Math.ceil(((percentage2 / 100) * amount2 + amount2) / npayments2);
};

const calcEntrega = (calc) => {
  return +calc.amount - +calc.actanotarial - +calc.balance - +calc.adelanto - +calc.discount;
};

const calcProfits = (npayments = 0, monto = 0, amount = 0) => {
  const npayments2 = npayments * 1;
  const monto2 = monto * 1;
  const amount2 = amount * 1;
  return npayments2 * monto2 - amount2;
};

const initialFormValues = { amount: "", percentage: "", npayments: "", actanotarial: "", adelanto: "", discount: "" };

const LoanCreateV2 = () => {
  const loans_db = usePouch(pouches_db.LOANS);
  const sync_db = usePouchQueues(pouches_db.SYNC_QUEUES);
  const history_db = usePouchQueues(pouches_db.SYNC_HISTORY);
  const alert = useAlert();
  const history = useHistory();
  const { id: dUniqueId } = useParams();

  const dispatch = useDispatch();

  const [money_id, setMoneyId] = useState("n/a");

  const { data: common, isLoading: c_loading } = useData(pouches_db.OFFLINE_DATA, {
    _id: "common",
    defaultValue: null,
  });

  console.log(common, c_loading);
  const existingLoan = useSelector((state) => selectNewLoanByDebtorId(state, dUniqueId));

  const { data: debtors, isLoading: d_loading } = useData(pouches_db.DEBTORS);

  const { data: currentPouchDebtor, isLoading: d2_loading } = useData(pouches_db.NEW_DEBTORS, {
    _id: dUniqueId,
    defaultValue: null,
  });

  const [formValues, setFormValues] = useState(initialFormValues);
  const [adelantoWarningAmount, setAdelantoWarningAmount] = useState(null);

  const { data: route } = useGetRouteQuery({
    creditor_id: common.creditor_id,
    user_id: common.user_id,
    collect_date: common.collect_date,
  });

  const { data: user } = useGetUserQuery(common.user_id);
  const { data: ownerConfig } = useGetOwnerConfigQuery(common.owner_id);
  const { data: userConfig } = useGetUserConfigQuery(common.user_id);

  const monto = useMemo(() => {
    return calcMonto(formValues.percentage, formValues.amount, formValues.npayments);
  }, [formValues.percentage, formValues.amount, formValues.npayments]);

  const entrega = useMemo(() => {
    return calcEntrega({ ...formValues, balance: 0 });
  }, [formValues.amount, formValues.actanotarial, formValues.adelanto, formValues.discount]);

  const profit = useMemo(() => {
    return calcProfits(formValues.npayments, monto, formValues.amount);
  }, [formValues.npayments, monto, formValues.amount]);

  if (d_loading || d2_loading) return <Loading />;

  const currentDebtor2 = debtors.find((x) => x.dUniqueId === dUniqueId);

  const currentDebtor = currentDebtor2 ?? currentPouchDebtor;

  if (+common.owner_id === 2587 && (!currentDebtor?.cedula || replaceNonDigits(currentDebtor?.cedula) < 1))
    return (
      <Box component="div" p={1} paddingTop={8}>
        <Alert variant="outlined" severity="warning">
          <Typography variant="h6" component="h4" gutterBottom>
            cliente Perfil sin C&eacute;dula!
          </Typography>
          <Typography variant="body1" component="p">
            Es requerido modificar el perfil de este cliente con su c&eacute;dula para poder hacer la entrega!
          </Typography>

          <Box paddingTop={3}>
            <Link to={`/debtor/edit/${currentDebtor.dUniqueId}`}>
              <Typography variant="body1">Modificar Perfil de cliente</Typography>
            </Link>
          </Box>
        </Alert>
      </Box>
    );

  if (!currentDebtor || !debtors || !ownerConfig || !userConfig || !route || !user)
    return (
      <LoadingInspect
        data={[
          {
            loaded: currentDebtor && Object.keys(currentDebtor).length > 0,
            label: "Current Debtor",
          },
          {
            loaded: debtors && debtors.length >= 0,
            label: "Debtors",
          },
          {
            loaded: ownerConfig && Object.keys(ownerConfig).length > 0,
            label: "OwnerConfig",
          },
          {
            loaded: route && Object.keys(route).length > 0,
            label: "Route",
          },
          {
            loaded: userConfig && Object.keys(userConfig).length > 0,
            label: "User Config",
          },
        ]}
      />
    );

  const initialValues = {
    user_id: common.user_id,
    debtor_id: currentDebtor.debtor_id,
    dUniqueId: currentDebtor.dUniqueId,
    name: currentDebtor.name,
    cedula: currentDebtor?.cedula,
    percentage: currentDebtor.percentage || route.rPerc || "",
    npayments: currentDebtor.npayments || route.rWeeks || "",
    amount: "",
    actanotarial: "",
    new_debtor: currentDebtor.new_debtor,
    creditor_id: common.creditor_id,
    given_date: common.collect_week_date,
    start_date: common.next_week_date,
    payoffBalance: 0,
    adelanto: 0,
    discount: 0,
    type: 7,
    confirmNewLoan: false,
  };

  const submithandler = (data) => submitnewLoanV2(data);

  let button = { label: "Guardar Prestamo", variant: "contained", color: "primary" };
  if (ownerConfig.digitalContract === "1") {
    //|| ownerConfig.loanIdentification === "1"
    button = {
      icon: <MdOutlineVerified />,
      label: "Guardar Prestamo y Continuar!",
      color: "primary",
      variant: "outlined",
    };
  }

  const submitnewLoanV2 = async (data2) => {
    if ([837, 933].includes(+data2.creditor_id) && +data2.npayments !== 10) {
      alert.error("Solo prestamos a 10 Cuotas permitido!", { timeout: 7000, position: "top center" });
      return;
    }

    const data = { ...data2, confirmNewLoan: undefined };

    if (entrega < 1) {
      // dispatch(alertError({ duration: 7000, message: "Cantidad a entregar es Invalida!" }));
      alert.error("Cantidad a entregar Invalida!", { timeout: 7000, position: "top center" });
      return;
    }

    if (data.actanotarial > (30 * data.amount) / 100) {
      // dispatch(alertError({ duration: 7000, message: "Acta Notarial muy alta!" }));
      alert.error("Actanotarial muy alta!", { timeout: 7000, position: "top center" });
      return;
    }

    if (+data.adelanto !== 0 && +data.adelanto !== adelantoWarningAmount) {
      setAdelantoWarningAmount(data.adelanto);
      return;
    }

    const timestamp = getTimestamp();
    const mUniqueId = data.new_debtor === true ? timestamp + data.user_id : timestamp + data.debtor_id;

    const newData = {
      ...data,
      money_id,
      timestamp,
      mUniqueId,
      wPayment: monto,
      creditor_id: common.creditor_id,
      collect_date: common.collect_date,
    };

    let loanCreateResult = false;

    let directUrl;

    if (ownerConfig.digitalContract === "1") {
      directUrl = `/loan/signature/${money_id}`;

      loanCreateResult = await createLoanWithPouch(data);

      dispatch(addDebtorsReordered({ dUniqueId: newData.dUniqueId, name: newData.name }));

      if (ownerConfig.autoPrintRenewal === "1") {
        printEntregaHandler(newData, { ...route, ...common }, { ...user, ...userConfig });
      }

      if (ownerConfig.autoPrintLoanContract === "1") {
        printLoanContractHandler(newData, { ...route, ...common }, { ...user, ...userConfig });
      }
    } else {
      directUrl = `/entregas`;

      loanCreateResult = await createLoanWithPouch(data);

      dispatch(addDebtorsReordered({ dUniqueId: newData.dUniqueId, name: newData.name }));

      if (ownerConfig.autoPrintRenewal === "1") {
        printEntregaHandler(newData, { ...route, ...common }, { ...user, ...userConfig });
      }

      if (ownerConfig.autoPrintLoanContract === "1") {
        printLoanContractHandler(newData, { ...route, ...common }, { ...user, ...userConfig });
      }
    }

    if (loanCreateResult) history.replace(directUrl);
  };

  async function createLoanWithPouch(data) {
    let operation1 = false;
    let operation2 = false;

    const timestamp = getTimestamp();
    const mUniqueId = data.new_debtor === true ? timestamp + data.user_id : timestamp + data.debtor_id;

    const syncronization_id = `R${common.creditor_id}_${common.collect_date}_${getUniqueID()}`;

    try {
      const newData = { ...data, money_id, timestamp, mUniqueId, wPayment: monto };

      let syncQueueData = {
        _id: syncronization_id,
        syncPriority: 1,
        dependsOn: null,
        syncType: syncTypes.CREATE_LOAN,
        postData: newData,
      };

      let syncHistoryData = {
        _id: syncronization_id,
        data_id: mUniqueId,
        syncType: syncTypes.CREATE_LOAN,
        syncAmount: data.amount,
        syncTitle: "Prestamo Registrado",
        syncName: `Cliente > ${data.name}`,
      };

      if (data.new_debtor) {
        syncQueueData = {
          ...syncQueueData,
          syncPriority: 2,
          postData: { debtor: currentDebtor, loan: newData },
          syncType: syncTypes.CREATE_DEBTOR_LOAN,
        };
        syncHistoryData = {
          ...syncHistoryData,
          syncType: syncTypes.CREATE_DEBTOR_LOAN,
          syncTitle: "Cliente Registrado",
          syncName: `Nuevo Cliente > ${data.name}`,
        };
      }

      await loans_db.putDoc({ _id: money_id, ...newData, syncronization_id, mUniqueId, timestamp });
      operation1 = true;

      await sync_db.putSertQueue(syncQueueData);
      operation2 = true;

      await history_db.putSertHistory(syncHistoryData);

      alert.success("Prestamo Guardado!", { position: "bottom center" });
      return true;
    } catch (err) {
      console.error(err);

      // Rollback if any fails
      if (operation1) {
        await loans_db.deleteDoc(data.mUniqueId);
        console.log("Rolled back the record 1.");
      }

      if (operation2) {
        await sync_db.deleteDoc(syncronization_id);
        console.log("Rolled back the record 2.");
      }

      alert.error("Error Guardando Prestamo!");
      return false;
    }
  }

  return (
    <div className="mb-5">
      <DebtorNameHeader debtor={currentDebtor}>
        <LoanDetailMenu loan={currentDebtor} />
      </DebtorNameHeader>

      {/* {(!currentDebtor?.cedula || replaceNonDigits(currentDebtor?.cedula) < 1) && (
        <CedulaWarning
        debtor={currentDebtor}
        updateDebtorCedula={updateDebtorCedula}
        mutate={mutate}
          redirect={`/loan/create/${currentDebtor.dUniqueId}`}
        />
      )} */}

      <Box component="div" p={1}>
        <Grid container>
          <Grid item xs={10}>
            <Typography variant="h6" component="h4">
              Nuevo Prestamo!
            </Typography>
          </Grid>
          <Grid item xs={2}>
            {/* <Calculator /> */}
          </Grid>
        </Grid>

        <Box paddingBottom={1}>
          <Divider />
        </Box>

        {existingLoan ? (
          <div className="mt-2 mb-5">
            <Box paddingBottom={1}>
              <Grow direction="up" in>
                <Alert variant="outlined" severity="error">
                  <Typography variant="body1" component="p" className="font-weight-bold">
                    Este cliente, ya tiene un nuevo prestamo registrado!
                  </Typography>
                </Alert>
              </Grow>
            </Box>
          </div>
        ) : (
          <>
            <Box div="div" p={1}>
              <RenderNewLoanCalculation data={{ monto, entrega, profit }} />
            </Box>

            <Box div="div" p={1}>
              <RenderNewLoanForm
                setFormValues={setFormValues}
                initialValues={initialValues}
                submithandler={submithandler}
                button={button}>
                {adelantoWarningAmount && (
                  <Box paddingBottom={1}>
                    <Grow direction="up" in>
                      <Alert variant="outlined" severity="warning">
                        <Typography variant="body1" component="p">
                          Esta aplicado <b>un pago de adelanto de {printAmount(adelantoWarningAmount)}</b>, si quiere
                          aplicar le este adelanto de {printAmount(adelantoWarningAmount)} trate nuevamente!
                        </Typography>
                      </Alert>
                    </Grow>
                  </Box>
                )}
              </RenderNewLoanForm>
            </Box>
          </>
        )}
      </Box>
    </div>
  );
};

export default LoanCreateV2;
