import { useReducer, useEffect } from "react";
import PouchDB from "pouchdb-browser";

const changesReducer = (state, action) => {
  switch (action.type) {
    case "ADD_CHANGE":
      return [...state, action.payload];
    default:
      return state;
  }
};

export default function usePouchChanges(dbName, options = {}) {
  const [changes, dispatch] = useReducer(changesReducer, []);

  useEffect(() => {
    const db = new PouchDB(dbName);

    const changesFeed = db
      .changes({
        since: "now",
        live: true,
        include_docs: true,
        ...options,
      })
      .on("change", (change) => {
        dispatch({ type: "ADD_CHANGE", payload: change });
      });

    return () => {
      changesFeed.cancel();
    };
  }, [dbName, options]);

  return changes;
}
