import { useState } from "react";
import { Form, RenderInput, RenderCheckboxLabel } from "./Form";
import Password from "./Password";
import Box from "@nodes/@material-ui/core/Box";
import { string, object } from "@nodes/yup";
import { Link } from "@nodes/react-router-dom";
import Divider from "@nodes/@material-ui/core/Divider";
import Button from "@nodes/@material-ui/core/Button";
import Typography from "@nodes/@material-ui/core/Typography";
import useMediaQuery from "../hooks/useMediaQuery";
import DisplayAppEnvName from "../components/DisplayAppEnvName";
import { BiLogIn } from "@nodes/react-icons/bi";
import { freeTrialsMonth } from "../constants";
import { mainApp, localStorageRememberUser } from "@/config";

const RenderLoginForm = ({ submithandler, username = "" }) => {
  const fullScreen = useMediaQuery("min-width:820px");
  const [password, setPassword] = useState("");

  const validation = object({
    username: string().required("Usuario es requerido!"),
  });

  let jsonValue = localStorage.getItem(localStorageRememberUser);
  const deviceObj = jsonValue != null ? JSON.parse(jsonValue) : null;

  const initialValues = {
    rememberUsername: true,
    env: import.meta.env.VITE_ENV,
    appVersion: import.meta.env.VITE_VERSION,
    foceAppUpdate: import.meta.env.VITE_foceAppUpdateNumber,
    clientUrl: window.location.origin,
    username: username || deviceObj?.username || "",
  };

  const handleSubmit = async (data) => {
    if (!password) return;
    await submithandler({ ...data, password });
  };

  const button = { icon: <BiLogIn />, label: "Iniciar Sesion", variant: "contained", color: "primary" };

  return (
    <div>
      <div className="mt-2 mb-1 text-center">
        <img alt="" src="/../../apple-touch-icon-144x144.png" />
      </div>

      {deviceObj?.firstname ? (
        <>
          <Typography variant="h5" component="h3" gutterBottom>
            Iniciar Sesion
          </Typography>
          <Typography variant="h6" component="h4" paragraph>
            Bienvenido, {deviceObj.firstname}!
          </Typography>
        </>
      ) : (
        <Typography variant="h5" component="h3" gutterBottom>
          Iniciar Sesion
        </Typography>
      )}

      <Form initialValues={initialValues} validation={validation} submithandler={handleSubmit} button={button}>
        <Box paddingBottom={2}>{RenderInput("username", "Usuario", "text", false, "outlined")}</Box>
        <Box paddingBottom={1}>
          <Password name="password" label="Contraseña" password={password} setPassword={setPassword} />
        </Box>
        <Box paddingBottom={1}>{RenderCheckboxLabel("rememberUsername", "Recordar mi usuario!")}</Box>
      </Form>

      <br />
      {mainApp === 1 && (
        <Link style={{ color: "#000" }} to="/user/lookup">
          <Typography variant="subtitle1" component="p">
            <u>Olvido su contraseña?</u>
          </Typography>
        </Link>
      )}

      <Link style={{ color: "#000" }} to="/contact">
        <Typography variant="subtitle1" component="p">
          <u>Tiene alguna pregunta?</u>
        </Typography>
      </Link>

      <Divider className="mt-4" />

      {mainApp === 1 && (
        <Box component="div" paddingTop={4}>
          <Link className="text-primary" to="/signup">
            <Button fullWidth={!fullScreen} variant="outlined" color="primary">
              Quiero ser cliente
            </Button>
          </Link>

          <Typography className="mt-3" variant="subtitle1" component="p">
            Haga clic en Quiero ser cliente para empezar su Demo gratuito de {freeTrialsMonth} meces!
          </Typography>
        </Box>
      )}

      <br />
      <DisplayAppEnvName />
    </div>
  );
};

export default RenderLoginForm;
