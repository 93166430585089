import { createSlice } from "@reduxjs/toolkit";

const initialState = { open: false, type: null, variant: "filled", duration: 500, message: "" };

export const snackbarSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    alertSuccess: (state, { payload }) => {
      state.open = true;
      state.type = "success";
      state.duration = payload.duration;
      state.message = payload.message;
    },
    alertWarning: (state, { payload }) => {
      state.open = true;
      state.type = "warning";
      state.duration = payload.duration;
      state.message = payload.message;
    },
    alertInfo: (state, { payload }) => {
      state.open = true;
      state.type = "info";
      state.duration = payload.duration;
      state.message = payload.message;
    },
    alertError: (state, { payload }) => {
      state.open = true;
      state.type = "error";
      state.duration = payload.duration;
      state.message = payload.message;
    },
    removeAlert: (state, action) => {
      state.open = false;
      state.type = null;
    },
  },
});

export const selectSnackbar = (state) => state.snackbar;

export const { alertSuccess, alertInfo, alertWarning, alertError, removeAlert } = snackbarSlice.actions;
export default snackbarSlice.reducer;
