import { useRef } from "react";
import Button from "@nodes/@material-ui/core/Button";
import Box from "@nodes/@material-ui/core/Box";
import Typography from "@nodes/@material-ui/core/Typography";
// import DialogActions from "@nodes/@material-ui/core/DialogActions";
import ClearIcon from "@nodes/@material-ui/icons/Clear";
import CheckIcon from "@nodes/@material-ui/icons/Check";
import SignatureCanvas from "@nodes/react-signature-canvas";
import "./styles/signaturePad.css";

const SignaturePad = ({ setSignature, name }) => {
  let sigPad = useRef({});

  const clear = () => sigPad.current.clear();
  const save = () => {
    if (!sigPad.current.isEmpty()) {
      // setSignature(sigPad.current.toDataURL());
      setSignature(sigPad.current.getTrimmedCanvas().toDataURL("image/png"));
    }
  };

  return (
    <div className="signaturePad">
      <Typography variant="h6" component="h5" align="center">
        Por favor, firme
      </Typography>
      <SignatureCanvas canvasProps={{ className: "sigPad" }} penColor="blue" ref={sigPad} />
      <br />

      <Box component="div" p={1} align="center">
        <Button startIcon={<ClearIcon />} size="small" variant="outlined" color="secondary" onClick={clear}>
          Limpiar
        </Button>
        <Button
          startIcon={<CheckIcon />}
          size="small"
          className="ml-2"
          variant="contained"
          color="primary"
          onClick={save}>
          Aceptar
        </Button>
      </Box>
    </div>
  );
};

export default SignaturePad;
