import { useParams } from "@nodes/react-router";
import Card from "@nodes/@material-ui/core/Card";
import CardContent from "@nodes/@material-ui/core/CardContent";
import CardHeader from "@nodes/@material-ui/core/CardHeader";
import Box from "@nodes/@material-ui/core/Box";
import Typography from "@nodes/@material-ui/core/Typography";
import { string, object } from "@nodes/yup";
import { Form, RenderInput } from "@shared/components/Form";
import Loading from "@shared/components/Loading";
import { useHistory } from "@nodes/react-router-dom";
import RenderBackButton from "@shared/components/RenderBackButton";
import { useAlert } from "react-alert";
import { useGetUserQuery } from "../store/slices/api";
import { updateUsername } from "@shared/api";

const UserUsername = () => {
  const alert = useAlert();
  const history = useHistory();
  const { id } = useParams();

  const { data: user, refetch } = useGetUserQuery(id);

  if (!user) return <Loading />;

  const initialUsernameValues = {
    user_id: id,
    username: "",
    currentPassword: "",
  };

  const validationUsername = object({
    currentPassword: string().required("Ingrese su contraseña actual!"),
    username: string()
      .required("Usuario es requerido!")
      .min(4, "Su usuario debe ser minimos 4 letras/ numero!")
      .max(15, "Su usuario debe ser maximo 15 letras / numeros!")
      .matches(/^([a-zA-Z0-9_-]+)$/, "Su usuario solo puede tener letras y numeros!"),
  });

  const buttonUsername = {
    label: "Actualizar Usuario",
    variant: "contained",
    color: "primary",
  };

  const submithandlerUsername = async (data) => {
    try {
      await updateUsername(data);
      refetch();
      history.replace("/profile");
      alert.info("Usuario Actualizado!");
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div className="mt-5">
      <Card raised>
        <CardHeader title="Usuario" />
        <CardContent>
          <Typography variant="body1" paragraph>
            En esta pagina puede cambiar el usuario!
          </Typography>
          <div>
            <Typography variant="h6" component="h4" gutterBottom>
              Usuario Actual: <i>{user.username}</i>
            </Typography>
            <Form
              initialValues={initialUsernameValues}
              validation={validationUsername}
              submithandler={submithandlerUsername}
              button={buttonUsername}>
              <Box paddingBottom={3}>{RenderInput("username", "Nuevo Usuario")}</Box>
              <Box paddingBottom={4}>{RenderInput("currentPassword", "Contraseña Actual", "password")}</Box>
            </Form>
            <div className="mt-4">
              <RenderBackButton />
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default UserUsername;
