import { useState } from "@nodes/react";
import Box from "@nodes/@material-ui/core/Box";
import Typography from "@nodes/@material-ui/core/Typography";
import Button from "@nodes/@material-ui/core/Button";
import Grid from "@nodes/@material-ui/core/Grid";
import Divider from "@nodes/@material-ui/core/Divider";
import Alert from "@nodes/@material-ui/lab/Alert";
import { FcDebt } from "@nodes/react-icons/fc";
import { UseCustomerBalance } from "../server/fetcher";

const CustomerBalance = ({ history, owner_id, mainOnly = 1 }) => {
  const [show, setShow] = useState(true);
  const { data } = UseCustomerBalance(owner_id, mainOnly);

  const localName = "custBalanceHiddenDay";
  const balanceHiddenDay = localStorage.getItem(localName);

  const d = new Date();
  let day = d.getDay();

  if (!show || `${owner_id}-${day}` === balanceHiddenDay) return null;

  const handleClick = (action) => {
    if (action === "view") {
      history.push(`/customer/balance/${owner_id}/${mainOnly}`);
    } else {
      setShow(false);
      localStorage.setItem(localName, `${owner_id}-${day}`);
    }
  };

  const getDisplay = (data) => {
    if (data.isDisabled) {
      return {
        variant: "filled",
        severity: "error",
        title: "Cuenta bloqueada",
        text: "Cuenta bloqueada por falta de pagos!",
        buttonVariant: "contained",
        buttonColor: "primary",
      };
    } else if (data.isDisabledDays > 0) {
      return {
        variant: "filled",
        severity: "warning",
        title: "Balance Pendiente!",
        text: `El acceso a esta cuenta sera bloqueado en ${data.isDisabledDays} dias!`,
        buttonVariant: "contained",
      };
    } else {
      return {
        variant: "standard",
        severity: "warning",
        title: "Balance Pendiente!",
        text: "Usted tiene pago(s) vencido, por favor evite atraso!",
      };
    }
  };

  const RenderCustomerPaymentReminder = ({ data = {} }) => {
    const display = getDisplay(data);

    return (
      <>
        <Box>
          <Alert icon={<FcDebt size="1.4em" />} variant={display.variant} severity={display.severity}>
            <Typography variant="h5" component="p" gutterBottom>
              {display.title}
            </Typography>

            <Typography variant="h6" component="p" paragraph>
              {display.text}
            </Typography>

            <Divider className="mb-3" />

            <div className="text-right">
              <Grid container>
                <Grid item xs={6}>
                  <Button
                    onClick={() => handleClick("view")}
                    size="small"
                    variant={display.buttonVariant ?? "outlined"}
                    color="primary">
                    Ver Factura
                  </Button>
                </Grid>
                <Grid item xs={5}>
                  <Button
                    onClick={() => handleClick("close")}
                    size="small"
                    variant="contained"
                    color={display.buttonColor ?? "secondary"}>
                    Cerrar
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Alert>
        </Box>
      </>
    );
  };

  return +data?.balance > 0 ? <RenderCustomerPaymentReminder data={data} /> : null;
};

export default CustomerBalance;
