import FormGroup from "@nodes/@material-ui/core/FormGroup";
import FormControlLabel from "@nodes/@material-ui/core/FormControlLabel";
import Checkbox from "@nodes/@material-ui/core/Checkbox";
import { mainApp } from "@/config";

const RenderSwitchButton = ({ name, isDisabled = false, label, value, setHandler, variant = "primary" }) => {
  const handleValueChange = (val) => {
    setHandler(+val === 1 ? "0" : "1");
  };

  return (
    <FormGroup row className="mt-3">
      <FormControlLabel
        control={
          <Checkbox
            disabled={mainApp !== 1 && isDisabled ? true : false}
            checked={+value === 0 ? false : true}
            onChange={() => handleValueChange(value)}
            name={name}
            color={variant}
          />
        }
        label={label}
      />
    </FormGroup>
  );
};

export default RenderSwitchButton;
