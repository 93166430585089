import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@nodes/@material-ui/core/Card";
import CardContent from "@nodes/@material-ui/core/CardContent";
import Typography from "@nodes/@material-ui/core/Typography";
import { useSelector } from "react-redux";
import { selectCommon } from "../store/slices/common";
import { useGetDebtorsQuery } from "../store/slices/api";

const DebtorNotAvailable = () => {
  const common = useSelector(selectCommon);

  const { refetch, isFetching } = useGetDebtorsQuery({
    creditor_id: common.creditor_id,
    user_id: common.user_id,
    collect_date: common.collect_date,
  });

  const handleRefetch = () => {
    refetch();
  };

  return (
    <div className="mt-5 mb-5">
      <Card raised>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Cliente no disponible!
          </Typography>

          <Typography variant="body1" paragraph>
            Este cliente no esta disponible en este Dispositivo.
          </Typography>

          <Typography variant="body1" paragraph>
            Para ver el perfil de este cliente, necesita estar conectado al internet.
          </Typography>
        </CardContent>

        <Box p={1} paddingBottom={2}>
          <Button disabled={isFetching} onClick={handleRefetch} fullWidth variant="contained" color="primary">
            {isFetching ? "Descargando Cliete!" : "Descargar Cliente!"}
          </Button>
        </Box>
      </Card>
    </div>
  );
};

export default DebtorNotAvailable;
