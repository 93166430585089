import PouchDB from "pouchdb-browser";

export default function usePouch(dbName) {
  const getAllDocIds = async (options) => {
    try {
      const db = new PouchDB(dbName);
      const res = await db.allDocs({ include_docs: false, ...options });
      return res.rows.filter((x) => !x.deleted);
    } catch (err) {
      console.log("Error fetching docs ID", err);
      return [];
    }
  };

  const getAllDocs = async (options) => {
    try {
      const db = new PouchDB(dbName);
      const result = await db.allDocs({ include_docs: true, ...options });
      const docs = result.rows.map((row) => row.doc);

      if (options?.include_deleted) return docs;
      return docs.filter((x) => !x.deleted);
    } catch (error) {
      console.error("Error fetching all docs:", error);
      return [];
    }
  };

  const getDocById = async (docId, options = {}) => {
    try {
      const db = new PouchDB(dbName);
      return await db.get(docId, options);
    } catch (err) {
      // console.log("Error while fetching single Doc:", err);
      return null;
    }
  };

  const putDoc = async (doc, options = {}) => {
    try {
      const db = new PouchDB(dbName);
      const putRes = await db.put(doc, options);

      return putRes;
    } catch (err) {
      console.log("Error in putDoc function:", err);
      return err;
    }
  };

  const putSert = async (data, options = {}) => {
    try {
      const db = new PouchDB(dbName);
      const doc = await getDocById(data._id);
      if (doc) return await db.put({ ...doc, ...data }, options);

      return await db.put(data, options);
    } catch (err) {
      console.log("Error in putSert function", err);
      return err;
    }
  };

  const bulkDocs = async (docs, options = {}) => {
    try {
      const db = new PouchDB(dbName);
      const result = await db.bulkDocs(docs, options);
      return result;
    } catch (err) {
      console.log("Error in bulkDocs function", err);
      return err;
    }
  };

  const deleteDoc = async (docId) => {
    try {
      const db = new PouchDB(dbName);
      const doc = await db.get(docId);

      doc.deleted = true;
      const result = await db.put(doc);
      return result;
    } catch (err) {
      console.log("Error while deleting doc:", err);
      return err;
    }
  };

  const getDatabaseInfo = async () => {
    try {
      const db = new PouchDB(dbName);
      return await db.info();
    } catch (err) {
      console.log(err);
      return err;
    }
  };

  const compact = async () => {
    try {
      const db = new PouchDB(dbName);
      return await db.compact();
    } catch (err) {
      console.log(err);
    }
  };

  const deleteDatabase = async () => {
    try {
      const db = new PouchDB(dbName);
      return await db.destroy(dbName);
    } catch (err) {
      console.log(err);
      return err;
    }
  };

  return {
    getDocById,
    getAllDocIds,
    getAllDocs,
    putDoc,
    putSert,
    compact,
    deleteDoc,
    bulkDocs,
    getDatabaseInfo,
    deleteDatabase,
  };
}
