import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { printDate, calcNewLoanStatus, calcNewCompleted, newDate } from "@shared/functions";
import { addWeeks } from "date-fns";
import backend from "@shared/server/backend";
// import { logout } from "./auth";
import { fetchCommonThunk } from "./common";
import { createPayment, deletePayment } from "./payments";

export const fetchLoansThunk = createAsyncThunk("loans/fetch", async ({ user_id, creditor_id }) => {
  const { data } = await backend.get(`/cobro/loansv3/${user_id}/${creditor_id}`);
  return data;
});

const getNextDate = (loan, paymentAmount, newCompleted) => {
  // if (loan.paymentAmount + paymentAmount === 0) return loan.static.next_date;
  return printDate(addWeeks(newDate(loan.given_date), newCompleted + 1), "yyyy-MM-dd");
};

const initialState = {
  cuadre_id: null,
  loans: [],
  debtorsReorded: [],
  isDebtorReordered: false,
  updatedDebtorsReorded: false,
  status: "idle", //loading, succceed, failed
  error: null,
  lastFetch: null,
};

export const loansSlice = createSlice({
  name: "loans",
  initialState,
  reducers: {
    createLoan: (state, { payload }) => {
      state.loans.unshift({ ...payload, postData: undefined, syncProps: undefined });
    },
    applyPayment: (state, { payload }) => {
      //This reducer is not being used.
      state.loans.map((x) => {
        if (x.money_id === payload.money_id) {
          const loanStatus = calcNewLoanStatus(x, payload.paymentAmount);
          const completed = calcNewCompleted(x, payload.paymentAmount);
          const next_date = getNextDate(x, payload.paymentAmount, completed);

          x.paymentAmount = x.paymentAmount + payload.paymentAmount;
          x.paymentMora = x.paymentMora + payload.paymentMora;
          x.xCobrarAmountTotal = x.xCobrarAmountTotal - payload.paymentAmount;
          x.balance = x.balance - payload.paymentAmount;
          x.statusAmount = loanStatus.statusAmount;
          x.statusText = loanStatus.statusText;
          x.completed = completed;
          x.next_date = next_date;
          x.last_date = payload.last_date;
          x.postPoned = null;
        }
      });
    },
    moveLoanlast: (state, { payload }) => {
      const index = state.loans.findIndex((y) => y.money_id === payload.money_id);
      state.loans.push(state.loans.splice(index, 1)[0]);
    },
    setDebtorsReordered: (state, { payload }) => {
      state.isDebtorReordered = true;
      state.updatedDebtorsReorded = true;
      state.debtorsReorded = payload.data;
    },
    addDebtorsReordered: (state, { payload }) => {
      const exist = state.debtorsReorded.find((y) => y.dUniqueId === payload.dUniqueId);
      if (exist) return state;

      state.debtorsReorded.unshift(payload);
    },
    updateLoansOrder: (state, { payload }) => {
      state.updatedDebtorsReorded = false;
      state.loans = payload;
    },
    undoPayment: (state, { payload }) => {
      state.loans.map((y) => {
        if (y.money_id === payload.money_id) {
          const loanStatus = calcNewLoanStatus(y, 0 - payload.paymentAmount);
          const completed = calcNewCompleted(y, 0 - payload.paymentAmount);
          const next_date = getNextDate(y, 0 - payload.paymentAmount, completed);
          const newPaymentAmount = y.paymentAmount - payload.paymentAmount;

          y.paymentAmount = newPaymentAmount;
          y.paymentMora = y.paymentMora - payload.paymentMora;
          y.xCobrarAmountTotal = y.xCobrarAmountTotal + payload.paymentAmount;
          y.balance = y.balance + payload.paymentAmount;
          y.completed = completed;
          y.next_date = next_date;

          if (newPaymentAmount === 0) {
            y.last_date = y.staticlast_date;
            y.statusAmount = y.staticStatusAmount;
            y.statusText = y.staticStatusText;
          } else {
            y.last_date = y.last_date;
            y.statusAmount = loanStatus.statusAmount;
            y.statusText = loanStatus.statusText;
          }
        }
      });
    },
    updateLoanToggle: (state, { payload }) => {
      state.loans.map((x) => {
        if (x.money_id === payload.money_id) {
          x.isHidden = !x.isHidden;
        }
      });
    },
    deleteLoan: (state, { payload }) => {
      state.loans.map((x) => {
        if (x.money_id === payload.money_id) {
          x.is_active = payload.is_active;
        }
      });
    },
    updateLoan: (state, { payload }) => {
      state.loans.map((y) => {
        if (y.money_id === payload.money_id) {
          // const loanStatus = calcNewLoanStatus(payload, payload.paymentAmount);
          y.paymentAmount = payload.paymentAmount;
          y.paymentMora = payload.paymentMora;
          y.xCobrarAmountTotal = payload.xCobrarAmountTotal;
          y.xCobrarAmount = payload.xCobrarAmount;
          y.balance = payload.balance;
          y.completed = payload.completed;
          y.next_date = payload.next_date;
          y.pending = payload.pending;
          y.cedula = payload.cedula;
          y.cuota = payload.cuota ? payload.cuota : 0;

          y.amount = payload.amount;
          y.given_date = payload.given_date;
          y.start_date = payload.start_date;
          y.wPayment = payload.wPayment;
          y.npayments = payload.npayments;

          y.pinned = payload.pinned;
          y.statusAmount = payload.statusAmount;
          y.statusText = payload.statusText;
          // y.statusAmount = loanStatus.statusAmount;
          // y.statusText = loanStatus.statusText;
          y.last_date = payload.last_date;
          y.staticlast_date = payload.staticlast_date;
          y.staticStatusText = payload.staticStatusText;
          y.staticStatusAmount = payload.staticStatusAmount;
        }
      });
    },
    updateDebtorInfo: (state, { payload }) => {
      state.loans.map((x) => {
        if (x.dUniqueId === payload.dUniqueId) {
          x.name = payload.name;
          x.phone = payload.phone;
          x.nickname = payload.nickname;
          x.cedula = payload.cedula;
          x.loanSearch = x.money_id + " " + payload.debtorSearch;
        }
      });

      state.debtorsReorded.map((d) => {
        if (d.dUniqueId === payload.dUniqueId) {
          d.name = payload.name;
        }
      });
    },
    updatePostPone: (state, action) => {
      const otherLoans = state.loans.filter((loan) => loan.money_id !== action.payload.money_id);
      const nonPaidLoans = otherLoans.filter((loan) => loan.paymentAmount === 0 && loan.paymentMora === 0);
      const paidLoans = otherLoans.filter((loan) => loan.paymentAmount > 0 || loan.paymentMora > 0);
      state.loans = [...nonPaidLoans, { ...action.payload, postPoned: true }, ...paidLoans];
    },
  },
  extraReducers(builder) {
    builder
      .addCase(createPayment.type, (state, { payload }) => {
        state.loans.map((x) => {
          if (x.money_id === payload.money_id) {
            const loanStatus = calcNewLoanStatus(x, payload.paymentAmount);
            const completed = calcNewCompleted(x, payload.paymentAmount);
            const next_date = getNextDate(x, payload.paymentAmount, completed);

            x.paymentAmount = x.paymentAmount + payload.paymentAmount;
            x.paymentMora = x.paymentMora + payload.paymentMora;
            x.xCobrarAmountTotal = x.xCobrarAmountTotal - payload.paymentAmount;
            x.balance = x.balance - payload.paymentAmount;
            x.statusAmount = loanStatus.statusAmount;
            x.statusText = loanStatus.statusText;
            x.completed = completed;
            x.isHidden = payload.isHidden;
            x.next_date = next_date;
            x.last_date = payload.last_date;
            x.postPoned = null;
          }
        });

        const index = state.loans.findIndex((y) => y.money_id === payload.money_id);
        state.loans.push(state.loans.splice(index, 1)[0]);
      })
      .addCase(deletePayment.type, (state, { payload }) => {
        state.loans.map((y) => {
          if (y.money_id === payload.money_id) {
            const loanStatus = calcNewLoanStatus(y, 0 - payload.paymentAmount);
            const completed = calcNewCompleted(y, 0 - payload.paymentAmount);
            const next_date = getNextDate(y, 0 - payload.paymentAmount, completed);
            const newPaymentAmount = y.paymentAmount - payload.paymentAmount;

            y.paymentAmount = newPaymentAmount;
            y.paymentMora = y.paymentMora - payload.paymentMora;
            y.xCobrarAmountTotal = y.xCobrarAmountTotal + payload.paymentAmount;
            y.balance = y.balance + payload.paymentAmount;
            y.completed = completed;
            y.next_date = next_date;

            if (newPaymentAmount === 0) {
              y.last_date = y.staticlast_date;
              y.statusAmount = y.staticStatusAmount;
              y.statusText = y.staticStatusText;
            } else {
              y.last_date = y.last_date;
              y.statusAmount = loanStatus.statusAmount;
              y.statusText = loanStatus.statusText;
            }
          }
        });
      })
      .addCase(fetchCommonThunk.fulfilled, (state, { payload }) => {
        if (state.cuadre_id === payload.cuadre_id) return state;
        return { ...initialState, cuadre_id: payload.cuadre_id };
      })
      .addCase(fetchLoansThunk.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchLoansThunk.fulfilled, (state, action) => {
        state.status = "succceed";
        state.lastFetch = Date.now();
        state.loans = action.payload.loans;
        state.debtorsReorded = action.payload.debtorsReorded;
      })
      .addCase(fetchLoansThunk.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = "failed";
      });
  },
});

export const selectLastFetch = (state) => state.loans.lastFetch;
export const selectLoans = (state) => state.loans.loans;
export const selectDebtorsReorded = (state) => state.loans.updatedDebtorsReorded;
export const selectDebtorsReorder = (state) => state.loans.debtorsReorded;
export const selectLoanById = (state, money_id) => state.loans.loans.find((x) => x.money_id === money_id);
export const selectLoansByDebtorId = (state, dUniqueId) => state.loans.loans.filter((x) => x.dUniqueId === dUniqueId);

export const {
  createLoan,
  setDebtorsReordered,
  updateLoansOrder,
  addDebtorsReordered,
  deleteLoan,
  updateLoan,
  updateLoanToggle,
  updateDebtorInfo,
  updatePostPone,
} = loansSlice.actions;
export default loansSlice.reducer;
