import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  device: {},
  client: {},
};

export const miscDataSlice = createSlice({
  name: "miscData",
  initialState,
  reducers: {
    setDevice: (state, action) => {
      state.device = action.payload;
    },
    setClient: (state, action) => {
      state.client = action.payload;
    },
  },
});

export const selectDevice = (state) => state.miscData.device;
export const selectClient = (state) => state.miscData.client;

export const { setDevice, setClient } = miscDataSlice.actions;
export default miscDataSlice.reducer;
