import { createSlice } from "@reduxjs/toolkit";
import { createPayment } from "./payments";
import { createNewLoan } from "./newLoans";

const initialState = {
  isDatacreating: null,
  palette: {
    primary: {
      main: "#2d3c91",
    },
    secondary: {
      main: "#860c15",
    },
  },
  serviceWorker: { status: "" },
  DisplayNav: false,
};

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    resetUi: () => initialState,
    setDisplayNav: (state, { payload }) => {
      state.DisplayNav = payload.DisplayNav;
    },
    setCreatingData: (state, { payload }) => {
      state.isDatacreating = payload.data_id;
    },
    setSWSTatus: (state, { payload }) => {
      state.serviceWorker.status = payload.status;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(createPayment.type, (state, { payload }) => {
        state.isDatacreating = payload.payment_id;
      })
      .addCase(createNewLoan.type, (state, { payload }) => {
        state.isDatacreating = payload.money_id;
      });
  },
});

export const selectTheme = (state) => state.ui.palette;
export const selectDisplay = (state) => state.ui.DisplayNav;
export const selectCreateStatus = (state) => state.ui.isDatacreating;

export const { resetUi, setDisplayNav, setSWSTatus, setCreatingData } = uiSlice.actions;
export default uiSlice.reducer;
