// import { useContext, useEffect, useState } from "react";
// import { Context } from "@shared/context/DataContext";
// import { getLoanSelector } from "@shared/context/Selectors";
import { useHistory, useParams } from "react-router";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import Grow from "@material-ui/core/Grow";
import DisplayLoanStatus from "@shared/components/DisplayLoanStatus";
import DebtorNameHeader from "@shared/components/DebtorNameHeader";
import RenderLoanDetail from "@shared/components/RenderLoanDetail";
import RenderLoanDetailButtons from "@shared/components/RenderLoanDetailButtons";
import DisplayLastPaymentAlert from "@shared/components/DisplayLastPaymentAlert";
import NoLoanFound from "@shared/components/NoLoanFound";
// import LoanDetailDebug from "@shared/components/LoanDetailDebug";
import LoanDetailMenu from "@shared/components/LoanDetailMenu";
// import Loading from "@shared/components/Loading";
import { useSelector } from "react-redux";
import { selectLoanById } from "../store/slices/loans";
// import { selectCommon } from "../store/slices/common";
// import { useHistory, useParams } from "react-router-dom";

const LoanDetail = () => {
  const history = useHistory();
  const { id: money_id } = useParams();
  // const dispatch = useDispatch();
  const currentLoan = useSelector((state) => selectLoanById(state, money_id));
  // const common = useSelector(selectCommon);

  if (!currentLoan) return <NoLoanFound />;

  return (
    <div className="mb-5">
      <DebtorNameHeader debtor={currentLoan}>
        <LoanDetailMenu loan={currentLoan} />
      </DebtorNameHeader>

      {/* {import.meta.env.DEV && <LoanDetailDebug loan={currentLoan} />} */}

      {currentLoan.is_active === "0" && (
        <Box component="div" p={1}>
          <Grow direction="up" in>
            <Alert variant="outlined" severity="error">
              <Typography variant="body1" component="p" className="font-weight-bold">
                Este prestamo fue cancelado!
              </Typography>
            </Alert>
          </Grow>
        </Box>
      )}

      {currentLoan.paidFromRenewal === true && (
        <Box component="div">
          <div className="alert alert-primary">
            <Typography variant="subtitle2" component="p">
              Este prestamo fue saldado con un renovo!
            </Typography>
          </div>
        </Box>
      )}

      <DisplayLastPaymentAlert loan={currentLoan} />

      <Box component="div" p={1}>
        <DisplayLoanStatus loan={currentLoan} />
      </Box>

      <Box component="div" p={1}>
        <RenderLoanDetail loan={currentLoan} />
        <RenderLoanDetailButtons loan={currentLoan} history={history} />
      </Box>
    </div>
  );
};

export default LoanDetail;
