import Typography from "@nodes/@material-ui/core/Typography";
import Chip from "@nodes/@material-ui/core/Chip";
import { printAmount } from "../functions";
// import Calculator from "./Calculator";

const RenderPaymentInfo = ({ loan }) => {
  return (
    <div>
      {/* <Grid container>
        <Grid item xs={10}> */}
      <Typography variant="h6" component="h4" gutterBottom>
        Aplicar Pago!
        <b className="p-2">
          <Chip label={`P${loan.money_id}`} />
        </b>
      </Typography>
      {/* </Grid>
        <Grid item xs={2}>
          <Calculator />
        </Grid>
      </Grid> */}

      <ul className="list-group mb-3">
        <li className="list-group-item">
          <Typography variant="body1" component="p">
            Cuotas Paga: {loan.completed + " / " + loan.npayments}
          </Typography>
        </li>

        <li className="list-group-item">
          <Typography variant="body1" component="p">
            Balance: {printAmount(loan.balance)}
          </Typography>
        </li>

        <li className="list-group-item">
          <Typography variant="body1" component="p">
            Monto: {printAmount(loan.wPayment)}
          </Typography>
        </li>

        <li className="list-group-item list-group-item-dark font-weight-bold">
          <Typography variant="body1" component="p">
            X Cobrar: {loan.xCobrarAmountTotal > 0 ? printAmount(loan.xCobrarAmountTotal) : "$0"}
          </Typography>
        </li>

        {/* {loan.mora > 0 && (
          <li className="list-group-item list-group-item-danger ">
          <Typography variant="body1" component="p">
          Mora Pendiente: {printAmount(loan.mora)}
          </Typography>
          </li>
        )} */}

        {loan.pending < 1 && loan.balance > 0 && (
          <li style={{ background: "#bbe1f7" }} className="list-group-item font-weight-bold">
            <Typography variant="body1" component="p">
              En adelanto de la semana pasada!
            </Typography>
          </li>
        )}
      </ul>
    </div>
  );
};

export default RenderPaymentInfo;
