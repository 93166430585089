import { useState } from "react";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import Badge from "@material-ui/core/Badge";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
// import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import { makeStyles } from "@material-ui/core/styles";
// import { FaHandHoldingUsd } from "react-icons/fa";
import { GiTakeMyMoney, GiPayMoney } from "react-icons/gi";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import { printAbsAmount, printAmount } from "@shared/functions";
import Loading from "@shared/components/Loading";
import { useSelector } from "react-redux";
import { selectCommon } from "../store/slices/common";
import { selectTotalCollected } from "../store/slices/payments";
import { selectTotalNewLoans } from "../store/slices/newLoans";
import { selectTotalGastos } from "../store/slices/gastos";
import { useHistory } from "react-router-dom";
import { useGetPermissionsQuery } from "../store/slices/api";
import { pouches_db } from "../config";
import useData from "../Hooks/useData";

const useStyles = makeStyles({
  stickToBottom: {
    backgroundColor: "#e4e4e4",
    width: "100%",
    position: "fixed",
    bottom: 0,
  },
});

const BottomNavigationPanel = () => {
  const history = useHistory();
  const totalGastos = useSelector(selectTotalGastos);
  const totalLoan = useSelector(selectTotalNewLoans);
  const totalCollected = useSelector(selectTotalCollected);
  const common = useSelector(selectCommon);

  const { data: gastos2 } = useData(pouches_db.GASTOS);

  const classes = useStyles();
  const [value, setValue] = useState("");

  const { data: permissions } = useGetPermissionsQuery({ creditor_id: common.creditor_id, user_id: common.user_id });

  if (!gastos2) return <Loading />;

  if (!permissions) return <Loading />;

  const totalGastos2 = gastos2.reduce((n, { amount }) => n + amount, 0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const cashMoney =
    totalLoan.adelanto +
    common.bank_amount +
    totalLoan.actanotarial +
    totalCollected.collectedCash +
    totalCollected.mora -
    totalGastos -
    totalGastos2 -
    totalLoan.entregas;

  let entregasLabel = "Entregas";
  let collectedLabel = "Cobrado";
  let efectivoLabel = "Efectivo";

  if (permissions.perm_efectivo) {
    entregasLabel = `Entregas ${printAmount(totalLoan.entregas)}`;
    collectedLabel = `Cobrado ${printAmount(totalCollected.collectedCash + totalLoan.adelanto)}`;
    efectivoLabel = `Efectivo ${printAbsAmount(cashMoney)}`;
  }

  return (
    <BottomNavigation showLabels value={value} onChange={handleChange} className={classes.stickToBottom}>
      <BottomNavigationAction
        onClick={() => history.push("/entregas")}
        label={entregasLabel}
        value="entregas"
        icon={
          <Badge overlap="rectangular" badgeContent={totalLoan.count || 0} color="error">
            <GiPayMoney size="1.6em" />,
          </Badge>
        }
      />

      <BottomNavigationAction
        onClick={() => history.push("/collected")}
        label={collectedLabel}
        value="cobrado"
        icon={
          <Badge overlap="rectangular" badgeContent={totalCollected.count || 0} color="primary">
            <GiTakeMyMoney size="1.8em" />
          </Badge>
        }
      />

      <BottomNavigationAction
        className={cashMoney < 0 ? "text-danger" : ""}
        onClick={() => history.push("/cuadre")}
        label={efectivoLabel}
        value="efectivo"
        icon={<MonetizationOnIcon />}
      />
    </BottomNavigation>
  );
};

export default BottomNavigationPanel;
