//This component should be used when data content should be refreshed and no internet is available.

import Typography from "@nodes/@material-ui/core/Typography";
import Alert from "@nodes/@material-ui/lab/Alert";
import { MdSignalWifiConnectedNoInternet0 } from "@nodes/react-icons/md";

const NoInternetContent = () => {
  return (
    <div className="container mt-5 mb-5">
      <div className="mt-5 mb-5 text-center">
        <MdSignalWifiConnectedNoInternet0 size="13em" />
      </div>

      <Alert variant="outlined" severity="info">
        <Typography variant="h6" component="p" gutterBottom className="font-weight-bold">
          No Conexion de Internet!
        </Typography>
        <Typography variant="body1" component="p" paragraph>
          WPrestamos requiere internet para mostrar el contenido de esta pagina.
        </Typography>

        <Typography variant="body1" component="p" paragraph>
          Conectese al Internet, Para refrescar el contenido de esta pagina.
        </Typography>
      </Alert>
    </div>
  );
};

export default NoInternetContent;
