import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Form, RenderInput } from "@shared/components/Form";
import { number, object } from "yup";
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import { useSelector } from "react-redux";
import { selectQueues } from "../store/slices/syncronization";
import { verifyAccessCode, uploadDebuggingData } from "@shared/api";

const UploadSyncState = () => {
  const alert = useAlert();
  const history = useHistory();

  const queues = useSelector(selectQueues);

  const initialValues = {
    accessCode: "",
    owner_id: 1001,
    type: "pendingSync",
  };

  const validation = object({
    accessCode: number().required("Codigo es requerida!"),
  });

  const button = { label: "Verificar Codigo", color: "primary", variant: "contained" };

  const submithandler = async (data) => {
    try {
      await verifyAccessCode(data);
      await uploadDebuggingData(queues);

      history.replace("/syncronization");
      alert.success("Datos Subido!");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="container mt-5">
      <Typography variant="h6" paragraph>
        Subir sincronizaciones pendiente!
      </Typography>

      <Typography variant="body1" paragraph>
        Para ejercer la siguiente accion necesita un codigo de autorizacion
      </Typography>

      <Form initialValues={initialValues} validation={validation} submithandler={submithandler} button={button}>
        <Box paddingBottom={3}>
          {RenderInput(
            "accessCode",
            "Codigo de Aceso",
            "number",
            false,
            "outlined",
            "Ingrese el codigo de autorizacion"
          )}
        </Box>
      </Form>

      <Typography className="mt-2" variant="body2" paragraph>
        <b>Cada codigo solo puede ser usado una vez!</b>
      </Typography>
    </div>
  );
};

export default UploadSyncState;
