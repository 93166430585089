import { useState, useEffect, useCallback, useRef } from "react";
import LoanCard from "@shared/components/LoanCard";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import SearchBox from "@shared/components/common/SearchBox";
import { RemoveAccents, indentifySearchType } from "@shared/functions";
import Grid from "@material-ui/core/Grid";
import useDebounce from "@shared/hooks/useDebounce";
import Loading from "@shared/components/Loading";
import { useSelector } from "react-redux";
import { selectLoans } from "../store/slices/loans";

const Loans = () => {
  const loans = useSelector(selectLoans);
  const [searchText, setSearchText] = useState("");
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [filteredLoans, setFilteredLoans] = useState([]);

  const loader = useRef(null);
  const loansXPage = 10;

  const handleObserver = useCallback((entries) => {
    const target = entries[0];
    if (target.isIntersecting) {
      setPage((prev) => prev + 1);
    }
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver);
    if (loader.current) observer.observe(loader.current);
  }, [handleObserver]);

  useDebounce(
    () => {
      if (searchText.length < 3) {
        setFilteredLoans(loans.slice(0, page * loansXPage));
        setLoading(false);
      } else {
        setFilteredLoans(
          loans
            .filter(
              (item) =>
                RemoveAccents(item.loanSearch).toLowerCase().indexOf(indentifySearchType(searchText.toLowerCase())) > -1
            )
            .slice(0, loansXPage + 10)
        );
      }
    },
    [loans, searchText, page],
    searchText.length < 3 ? 0 : 800
  );

  const handleSearch = (query) => {
    setSearchText(query);
  };

  return (
    <div className="mb-3">
      <div style={{ minHeight: "110%" }}>
        {loans.length === 0 ? (
          <div className="container mt-5 mb-5">
            <Typography variant="h5" componet="h3" gutterBottom>
              No ay Prestamos registrado!
            </Typography>

            <Typography variant="body1" componet="p" paragraph>
              En esta pagina puede ver todo los prestamos.
            </Typography>
            <Typography variant="body1" componet="p" paragraph>
              Por el momento, no tiene ningun Prestamo!
            </Typography>
          </div>
        ) : (
          <div className="mt-3 mb-3">
            <Box component="div" p={1}>
              <Typography variant="h5" componet="h3">
                Lista de Prestamos ({loans.length})
              </Typography>

              <SearchBox onChange={handleSearch} placeHolder="Escriba para buscar Prestamo..." value={searchText} />
            </Box>

            {loading ? (
              <Loading />
            ) : (
              <Grid container>
                {filteredLoans.map((loan) => (
                  <Grid key={loan.money_id} item xs={12}>
                    <LoanCard loan={loan} />
                  </Grid>
                ))}
              </Grid>
            )}
          </div>
        )}
      </div>

      <Box ref={loader} component="div" p={2}>
        {!loading && searchText.length < 3 && loans?.length > page * loansXPage && (
          <Typography variant="h5" paragraph>
            Por favor espere...
          </Typography>
        )}
      </Box>
    </div>
  );
};
export default Loans;
