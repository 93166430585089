import { Link } from "@nodes/react-router-dom";
import Card from "@nodes/@material-ui/core/Card";
import CardContent from "@nodes/@material-ui/core/CardContent";
import { makeStyles } from "@nodes/@material-ui/core";
import Grid from "@nodes/@material-ui/core/Grid";
import Divider from "@nodes/@material-ui/core/Divider";
import Box from "@nodes/@material-ui/core/Box";
import Chip from "@nodes/@material-ui/core/Chip";
import Typography from "@nodes/@material-ui/core/Typography";
import NumberFormat from "@nodes/react-number-format";

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 10,
  },
}));

const RenderUserProfile = ({ user = {}, history }) => {
  const classes = useStyles();

  return (
    <div>
      <Box paddingBottom={1}>
        <Card raised>
          <Grid container>
            <Grid item xs={9}>
              <Typography className="m-3" variant="h6" component="h4">
                <b className="pr-1">
                  <Chip label={`W${user.user_id}`} />
                </b>
                Informacion
              </Typography>
            </Grid>
            <Grid item xs={3} align="right">
              <h6 className="m-3">
                <Link to={`/user/personal/${user.user_id}`}>Editar</Link>
              </h6>
            </Grid>
          </Grid>

          <Divider />
          <CardContent className={classes.root}>
            <Typography variant="body1" component="p">
              {user.fname} {user.lname}
            </Typography>

            {user.phone && (
              <Typography variant="body1" component="p">
                <NumberFormat value={user.phone} displayType={"text"} format="(###) ###-####" />
                {user.verified === "0" && (
                  <span className="text-danger pl-1" onClick={() => history.push("user/phone/verify")}>
                    Sin verificar
                  </span>
                )}
              </Typography>
            )}
            {user.cedula && (
              <Typography variant="body1" component="p">
                <NumberFormat value={user.cedula} displayType={"text"} format="###-#######-#" />
              </Typography>
            )}
          </CardContent>
        </Card>
      </Box>

      <Box paddingBottom={1}>
        <Card raised>
          <Grid container>
            <Grid item xs={9}>
              <Typography className="m-3" variant="h6" component="h4">
                Usuario
              </Typography>
            </Grid>
            <Grid item xs={3} align="right">
              <h6 className="m-3">
                <Link to={`/user/username/${user.user_id}`}>Editar</Link>
              </h6>
            </Grid>
          </Grid>
          <Divider />
          <CardContent className={classes.root}>
            <Typography variant="body1" component="p">
              Usuario: {user.username}
            </Typography>
          </CardContent>
        </Card>
      </Box>

      <Box paddingBottom={1}>
        <Card raised>
          <Grid container>
            <Grid item xs={9}>
              <Typography className="m-3" variant="h6" component="h4">
                Contraseña
              </Typography>
            </Grid>
            <Grid item xs={3} align="right">
              <h6 className="m-3">
                <Link to={`/user/password/${user.user_id}`}>Editar</Link>
              </h6>
            </Grid>
          </Grid>
          <Divider />
          <CardContent className={classes.root}>
            <Typography variant="body1" component="p">
              Contraseña: *********
            </Typography>
          </CardContent>
        </Card>
      </Box>

      <Box paddingBottom={1}>
        <Card raised>
          <Grid container>
            <Grid item xs={9}>
              <Typography className="m-3" variant="h6" component="h4">
                Preguntas de Seguridad
              </Typography>
            </Grid>
            <Grid item xs={3} align="right">
              <h6 className="m-3">
                <s>Editar</s>
                {/* <Link to="/security/questions">
                </Link> */}
              </h6>
            </Grid>
          </Grid>
          <Divider />
          <CardContent className={classes.root}>
            <Typography variant="body1" component="p">
              Preguntas de seguridad para recuperar su contraseña si alguna vez la olvida.
            </Typography>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

export default RenderUserProfile;
