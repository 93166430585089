import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Paper from "@material-ui/core/Paper";
import ListItemText from "@material-ui/core/ListItemText";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// import DragHandleIcon from "@material-ui/icons/DragHandle";
// import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import TouchAppIcon from "@material-ui/icons/TouchApp";
import Loading from "@shared/components/Loading";
import { useSelector, useDispatch } from "react-redux";
import { selectDebtorsReorder, setDebtorsReordered } from "../store/slices/loans";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const DebtorsOrganize = () => {
  const debtorsReorded = useSelector(selectDebtorsReorder);
  const dispatch = useDispatch();

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const debtors = reorder(debtorsReorded, result.source.index, result.destination.index);
    dispatch(setDebtorsReordered({ data: debtors }));
  };

  if (!debtorsReorded) return <Loading />;

  return (
    <div className="container mt-3 mb-5">
      <Typography variant="h5" component="h3" gutterBottom>
        Ordenar Clientes
      </Typography>
      <Typography variant="body1" component="p" paragraph>
        En esta pagina puede organizar los clientes en el mismo orden en cual usted le cobra.
      </Typography>

      <Paper variant="outlined" style={{ backgroundColor: "#faf8f8" }}>
        <DragDropContext onDragEnd={onDragEnd}>
          <List>
            <Droppable droppableId="droppable-1">
              {(provided, _) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {debtorsReorded.map((debtor, i) => (
                    <Draggable key={debtor.dUniqueId} draggableId={"draggable-" + debtor.dUniqueId} index={i}>
                      {(provided, snapshot) => (
                        <ListItem
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          style={{
                            ...provided.draggableProps.style,
                            boxShadow: snapshot.isDragging ? "0 0 .5rem #e60d0f" : "none",
                          }}>
                          <ListItemIcon {...provided.dragHandleProps}>
                            <TouchAppIcon />
                          </ListItemIcon>
                          <ListItemText primary={debtor.name} />
                        </ListItem>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </List>
        </DragDropContext>
      </Paper>
    </div>
  );
};

export default DebtorsOrganize;
