export const getObjectByIndex = (loans, index) => {
  return loans[index];
};

export const getNewLoansByDebtor = (loans, debtor_id) => {
  return loans.filter((x) => !x.deleted && x.debtor_id === debtor_id);
};

export const getLoansCobro = (loans, maxWeeks) => {
  return loans.filter((loan) => loan.current_week <= maxWeeks);
};

export const getGastosSelector = (gastos) => {
  const gastosLength = gastos.length;
  let obj = { amount: 0, count: 0, list: [] };

  for (let i = 0; i < gastosLength; i++) {
    obj.amount += gastos[i].amount * 1;
    obj.count++;
    obj.list.push(gastos[i]);
  }

  obj.list.reverse();
  return obj;
};

export const getImagesByDebtor = (images, debtor_id) => {
  return images.filter((image) => image.debtor_id === debtor_id);
};

export const getNewLoansSelector = (loans, reverseOrder = true) => {
  const loansLength = loans.length;
  let obj = { amount: 0, count: 0, list: [] };

  for (let i = 0; i < loansLength; i++) {
    obj.amount += loans[i].amount * 1;
    obj.count++;
    obj.list.push(loans[i]);
  }

  if (reverseOrder === true) {
    obj.list.reverse();
  }

  return obj;
};

export const getCollectedMora = (loans) => {
  const loansLength = loans.length;
  let obj = { amount: 0, list: [] };

  for (let i = 0; i < loansLength; i++) {
    const { name, paymentMora, amount, debtor_id, money_id } = loans[i];

    if (paymentMora > 0) {
      obj.amount += paymentMora;
      obj.list.push({ money_id, debtor_id, name, amount, paymentMora });
    }
  }
  return obj;
};

export const getXcobrarLoans = (loans) => {
  const loansLength = loans.length;
  let obj = { count: 0, amount: 0, xCobrarTotal: 0, xCobrarCount: 0, list: [], failedAmount: 0, failedList: [] };

  for (let i = 0; i < loansLength; i++) {
    if (loans[i].toggleHide === true && loans[i].is_active === "1") {
      //Add this condition recently  && loans[i].pending > 0 becuase progress was already started because of adelantos loans
      if (loans[i].xCobrar === true && loans[i].pending > 0) {
        obj.xCobrarCount++;
        obj.xCobrarTotal += loans[i].xCobrarAmount;
        if (loans[i].paymentAmount >= loans[i].xCobrarAmount) {
          obj.amount += loans[i].xCobrarAmount;
          obj.count++;
        } else {
          if (loans[i].paymentAmount > 0) {
            obj.count++;
          }

          obj.amount += loans[i].paymentAmount;
          const failedRemainingAmount = loans[i].xCobrarAmount - loans[i].paymentAmount;
          obj.failedAmount += failedRemainingAmount;
          obj.failedList.push({ name: loans[i].name, amount: failedRemainingAmount, money_id: loans[i].money_id });
        }
      }

      obj.list.push(loans[i]);
    }
  }
  return obj;
};

export const filterVencidosLoans = (loans, hideVencidos) => {
  if (!hideVencidos) return loans;

  const loansLength = loans.length;
  let newLoans = [];

  for (let i = 0; i < loansLength; i++) {
    if (loans[i].statusText !== "Vencido") {
      newLoans.push(loans[i]);
    }
  }
  return newLoans;
};

export const getLoanSignature = (signatures, money_id) => {
  const result = signatures.filter((signature) => signature.money_id === money_id);
  return result[0];
};

export const getModifiedDebtors = (debtors) => {
  return debtors.filter((debtor) => debtor.modified_debtor === true);
};

export const getNewLoan = (loans, money_id) => {
  const result = loans.filter((loan) => loan.money_id === money_id);
  return result[0];
};

export const getLoanSelector = (loans, money_id) => {
  const result = loans.filter((loan) => loan.money_id === money_id);
  return result[0];
};

export const getPaymentSelector = (collected, payment_id) => {
  const result = collected.filter((payment) => payment.payment_id === payment_id);
  return result[0];
};

export const getDebtorSelector = (debtors, debtor_id) => {
  const result = debtors.filter((debtor) => debtor.debtor_id === debtor_id);
  return result[0];
};

export const getEmployeeSelector = (employees, user_id) => {
  const result = employees.filter((employee) => employee.user_id === user_id);
  return result[0];
};

export const getCollectedLoans = (loans) => {
  const loansLength = loans.length;
  let obj = { amount: 0, count: 0, list: [] };

  for (let i = 0; i < loansLength; i++) {
    if (loans[i].paymentAmount > 0 || loans[i].paymentMora > 0) {
      obj.amount += loans[i].paymentAmount;
      obj.count++;
      obj.list.push(loans[i]);
    }
  }

  return obj;
};

export const getCollectedPayments = (loans) => {
  const loansLength = loans.length;
  let obj = { amount: 0, count: 0, list: [] };

  for (let i = 0; i < loansLength; i++) {
    if (loans[i].paymentAmountDisplay > 0 || loans[i].paymentMoraDisplay > 0) {
      obj.amount += loans[i].paymentAmountDisplay;
      obj.count++;
      obj.list.push(loans[i]);
    }
  }

  return obj;
};

export const getDebtorLoansBalanceSelector = (loans, debtor_id, balance3 = 0, routeConfig = {}) => {
  const loansLength = loans.length;
  let obj = { totalBalance: 0, payoffBalance: 0, loansBalance: [] };

  for (let i = 0; i < loansLength; i++) {
    const { debtor_id: currentID, balance } = loans[i];

    if (currentID === debtor_id && balance > balance3) {
      obj.totalBalance += balance;
      if (routeConfig.autoSelectRenewal === true) {
        obj.payoffBalance += balance;
        obj.loansBalance.push({ isChecked: true, balance, ...loans[i] });
      } else {
        obj.loansBalance.push({ isChecked: false, balance, ...loans[i] });
      }
    }
  }
  return obj;
};
