import { useParams } from "react-router";
import Typography from "@material-ui/core/Typography";
// import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { printAmount, getUniqueID } from "@shared/functions";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import SubmitButton from "@shared/components/SubmitButton";
import Loading from "@shared/components/Loading";
import CancelIcon from "@material-ui/icons/Cancel";
import { selectCommon } from "../store/slices/common";
import { deletePayment, selectPaymentById } from "../store/slices/payments";
// import { undoPayment } from "../store/slices/loans";
import { useDispatch, useSelector } from "react-redux";
import { createQueue } from "../store/slices/syncronization";
// import { updatePayoff } from "../store/slices/newLoans";
import { useHistory } from "react-router-dom";
import { useGetPermissionsQuery } from "../store/slices/api";
import { useAlert } from "react-alert";
import RenderBackButton from "@shared/components/RenderBackButton";
import usePouch from "../Hooks/usePouch";
import usePouchQueues from "../Hooks/usePouchQueues";
import { pouches_db } from "../config";

const DeletePayment = () => {
  const sync_db = usePouchQueues(pouches_db.SYNC_QUEUES);
  const payments_db = usePouch(pouches_db.PAYMENTS);
  const alert = useAlert();
  const history = useHistory();
  const { id: payment_id } = useParams();

  const dispatch = useDispatch();
  const payment = useSelector((state) => selectPaymentById(state, payment_id));
  const common = useSelector(selectCommon);

  const { data: permissions } = useGetPermissionsQuery({ creditor_id: common.creditor_id, user_id: common.user_id });

  const handleDeletePayment = async () => {
    const applyLoan = {
      money_id: payment.money_id,
      paymentAmount: payment.paymentAmount,
      paymentMora: payment.paymentMora,
    };

    const postData = {
      payment_id: payment.payment_id,
      money_id: payment.money_id,
      debtor_id: payment.debtor_id,
      pUniqueId: payment.pUniqueId,
      user_id: common.user_id,
    };

    const syncProps = {
      data_id: payment.payment_id,
      syncronization_id: `R${common.creditor_id}_${common.collect_date}_${getUniqueID()}`,
      queueTime: Date.now(),
      endPoint: "/bgsync/cobro/payment/delete",
      syncPriority: 5,
      syncType: "deletePayment",
      syncAmount: payment.paymentAmount,
      syncTitle: "Pago Borrado",
      syncName: `Cliente > ${payment.name}`,
      requiredData: true,
    };

    if (permissions.perm_payment === true) {
      // if (payment.payoffLoanId) {
      //   dispatch(updatePayoff(payment));
      // }

      // await payments_db.deleteDoc(payment.payment_id);
      dispatch(deletePayment(payment));
      // dispatch(undoPayment(applyLoan));

      dispatch(createQueue({ postData, syncProps }));
      // await sync_db.putSertQueue({ postData, syncProps });

      history.replace("/collected");
      alert.error("Pago Borrado!");
    } else {
      localStorage.setItem(
        "awaitingAction",
        JSON.stringify({ postData, payment, syncProps, applyLoan, type: "deletePayment" })
      );
      history.replace("/access/codes");
    }
  };

  if (!permissions || !payment) return <Loading />;

  return (
    <div className="container mt-5">
      <Card>
        <CardContent>
          {!permissions.perm_payment && (
            <Box className="text-danger">
              <Divider className="mt-2" />
              <Typography variant="h6" component="h4" gutterBottom>
                No autorizado
              </Typography>
              <Typography variant="body1" component="p" paragraph>
                Usted no esta autorizado para borrar pagos en la app de cobro!
              </Typography>

              <Typography variant="body1" component="p" paragraph>
                Al continuar va requerir de una codigo de aceso!
              </Typography>
              <Divider className="mt-2" />
            </Box>
          )}

          <Typography variant="h5" component="h3" className="text-danger">
            Confirmaci&oacute;n
          </Typography>

          <Typography variant="h6" component="h4" gutterBottom>
            Decea borrar este pago?
          </Typography>
          <Typography variant="body1" component="p" paragraph>
            Pago de {printAmount(payment.paymentAmount)} para {payment.name}
          </Typography>

          <Box paddingTop={2}>
            {/* <Button
              fullWidth
              variant="contained"
              color="secondary"
              onClick={() =>
                handleDeletePayment({
                  payment_id: payment.payment_id,
                  money_id: payment.money_id,
                  debtor_id: payment.debtor_id,
                  user_id: payment.user_id,
                  paidFromRenewal: payment.paidFromRenewal,
                  payoffLoanId: payment.payoffLoanId,
                  paymentAmount: payment.paymentAmount,
                })
              }>
              Borrar Pago
            </Button> */}

            <SubmitButton
              autoFetch={false}
              color="secondary"
              text="Eliminar Pago!"
              startIcon={<CancelIcon />}
              callBack={handleDeletePayment}
            />
            <div className="mt-4">
              <RenderBackButton />
            </div>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

export default DeletePayment;
