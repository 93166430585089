import NewLoansMenu from "../components/NewLoansMenu";
import Card from "@nodes/@material-ui/core/Card";
import Divider from "@nodes/@material-ui/core/Divider";
import CardHeader from "@nodes/@material-ui/core/CardHeader";
// import CardContent from "@nodes/@material-ui/core/CardContent";
import PersonIcon from "@nodes/@material-ui/icons/Person";
import Typography from "@nodes/@material-ui/core/Typography";
import { makeStyles } from "@nodes/@material-ui/core";
import NumberFormat from "@nodes/react-number-format";
// import { MdOutlineCloudDone, MdOutlineCloudOff } from "@nodes/react-icons/md";
// import { mainApp } from "@/config";

const useStyles = makeStyles(() => ({
  CardContent: {
    paddingTop: 0,
  },
}));

// const RenderSyncIcon = ({ loan }) => {
//   if (mainApp === 1) return null;
//   return loan.synced === true ? (
//     <MdOutlineCloudDone size="0.8em" className="text-success" />
//   ) : (
//     <MdOutlineCloudOff size="0.8em" className="text-danger" />
//   );
// };

const NewLoansCard = ({ loan, route, user, userConfig }) => {
  const classes = useStyles();
  return (
    <Card variant="outlined">
      <CardHeader
        action={<NewLoansMenu route={route} user={user} loan={loan} userConfig={userConfig} />}
        title={
          <Typography variant="h6" component="h4">
            {/* <RenderSyncIcon loan={loan} /> */}
            <PersonIcon size="small" /> {loan.name}
          </Typography>
        }
        subheader={
          <>
            {loan.payoffBalance > 0 && (
              <Typography variant="body2" component="p" className="text-info font-weight-bold">
                Balance anterior saldado:{" "}
                {<NumberFormat value={loan.payoffBalance} displayType={"text"} thousandSeparator={true} prefix={"$"} />}
                !
              </Typography>
            )}
          </>
        }
      />
      {/* <CardContent className={classes.CardContent}>
        <Typography variant="body1" component="p">
          Monto Prestado:{" "}
          {<NumberFormat value={loan.amount} displayType={"text"} thousandSeparator={true} prefix={"$"} />}
        </Typography>
        <Typography variant="body1" component="p">
          Cuotas: {<NumberFormat value={loan.wPayment} displayType={"text"} thousandSeparator={true} prefix={"$"} />} X{" "}
          {loan.npayments}
        </Typography>
        <Typography variant="body1" component="p">
          Acta Notarial:{" "}
          {<NumberFormat value={loan.actanotarial} displayType={"text"} thousandSeparator={true} prefix={"$"} />}
        </Typography>
        {+loan.discount > 0 && (
          <Typography variant="body1" component="p" className="text-danger">
            Descuento Adicional:{" "}
            {<NumberFormat value={loan.discount} displayType={"text"} thousandSeparator={true} prefix={"$"} />}
          </Typography>
        )}
        {+loan.adelanto > 0 && (
          <Typography variant="body1" component="p" className="text-success">
            Pago Adelanto:{" "}
            {<NumberFormat value={loan.adelanto} displayType={"text"} thousandSeparator={true} prefix={"$"} />}
          </Typography>
        )}
        <Typography variant="subtitle1" component="p" className="font-weight-bold">
          Cantidad Entregada:{" "}
          {
            <NumberFormat
              value={loan.amount - loan.actanotarial - loan.adelanto - loan.discount - loan.payoffBalance}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"$"}
            />
          }
        </Typography>
      </CardContent> */}
      <Divider />
    </Card>
  );
};

export default NewLoansCard;
