
import Box from "@nodes/@material-ui/core/Box";
import Typography from "@nodes/@material-ui/core/Typography";
import Divider from "@nodes/@material-ui/core/Typography";
import DebtorLoanCard from "./DebtorLoanCard";

const RenderDebtorLoans = ({ title = "Lista de Prestamos", loans = [] }) => {
  if (loans.length === 0) return null;

  return (
    <Box>
      <Divider />

      <Typography variant="h6" component="h4">
        <Box p={1}>{title}</Box>
      </Typography>

      {loans.map((loan) => (
        <Box key={loan.money_id} paddingBottom={1}>
          <DebtorLoanCard loan={loan} />
        </Box>
      ))}
    </Box>
  );
};

export default RenderDebtorLoans;
