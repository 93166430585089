import { useRef, useState, useCallback, useEffect } from "@nodes/react";
import ReactCrop from "@nodes/react-image-crop";
import "@nodes/react-image-crop/dist/ReactCrop.css";
import CropDialog from "./CropDialog";

const PIXEL_RATIO = 4;
export default function CropImage(props) {
  const [crop, setCrop] = useState({
    unit: "%",
    width: 80,
    aspect: 32 / 21,
  });

  const [src, setSrc] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [open, setModalOpen] = useState(false);
  const [preview, setPreview] = useState();
  const [base64, setBase64] = useState();
  const [croppedFile, setCroppedFile] = useState();

  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (props.selectedFile) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setSrc(reader.result));
      reader.readAsDataURL(props.selectedFile);
      setModalOpen(true);
    }
  }, [props.selectedFile]);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    const ctx = canvas.getContext("2d");

    canvas.width = crop.width * PIXEL_RATIO;
    canvas.height = crop.height * PIXEL_RATIO;

    ctx.setTransform(PIXEL_RATIO, 0, 0, PIXEL_RATIO, 0, 0);
    ctx.imageSmoothingEnabled = false;

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    const base64Image = canvas.toDataURL("image/jpeg");
    setBase64(base64Image);

    canvas.toBlob((blob) => {
      if (!blob) return;
      const previewUrl = URL.createObjectURL(blob);
      const newFile = new File([blob], props.selectedFile.name);

      setPreview(previewUrl);
      setCroppedFile(newFile);
    }, "image/jpg");
  }, [completedCrop, props.selectedFile]);

  return (
    <>
      <CropDialog
        open={open}
        onHide={() => setModalOpen(false)}
        onSave={() => {
          props.onSave({ file: croppedFile, preview, base64 });
          setModalOpen(false);
        }}>
        <ReactCrop
          src={src}
          style={{ maxHeight: "550px" }}
          crop={crop}
          onChange={(crop, percentCrop) => setCrop(percentCrop)}
          onComplete={(c) => setCompletedCrop(c)}
          onImageLoaded={onLoad}
        />
      </CropDialog>

      <canvas ref={previewCanvasRef} style={{ width: 0, height: 0 }} />
    </>
  );
}
