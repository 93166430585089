import { useState } from "react";
import Menu from "@nodes/@material-ui/core/Menu";
import MenuItem from "@nodes/@material-ui/core/MenuItem";
import MoreVertIcon from "@nodes/@material-ui/icons/MoreVert";
import Divider from "@nodes/@material-ui/core/Divider";
import IconButton from "@nodes/@material-ui/core/IconButton";
import PersonIcon from "@nodes/@material-ui/icons/Person";
import { FaRegMoneyBillAlt, FaFileContract, FaHandHoldingUsd } from "@nodes/react-icons/fa";
import { AiOutlineOrderedList, AiOutlineFileSync } from "@nodes/react-icons/ai";
import VisibilityIcon from "@nodes/@material-ui/icons/Visibility";
import CancelIcon from "@nodes/@material-ui/icons/Cancel";
import EditIcon from "@nodes/@material-ui/icons/Edit";
import { mainApp } from "@/config";
import { useHistory } from "@nodes/react-router-dom";

export default function LoanDetailMenu({ loan }) {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const moneyIdCondition = loan?.money_id?.length > 1;
  const { pathname } = history.location;

  return (
    <div>
      <IconButton aria-label="settings" onClick={handleClick}>
        <PersonIcon />
        <MoreVertIcon />
      </IconButton>

      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {pathname !== `/payment/create/${loan.money_id}/${loan.debtor_id}` && moneyIdCondition && (
          <span>
            <MenuItem
              className="text-success"
              onClick={() => history.push(`/payment/create/${loan.money_id}/${loan.debtor_id}`)}>
              <FaRegMoneyBillAlt size="1.5em" className="pr-1" />
              Aplicar Pago
            </MenuItem>
            <Divider />
          </span>
        )}

        {pathname !== `/loan/renewal/${loan.dUniqueId}/${loan.money_id}` && moneyIdCondition && +loan.balance > 0 && (
          <span>
            <MenuItem
              className="text-warning"
              onClick={() => history.push(`/redirect/page/${loan.dUniqueId}/${loan.money_id}/${loan.cedula}`)}>
              <AiOutlineFileSync size="1.5em" className="pr-1" />
              Renovar Prestamo
            </MenuItem>
            <Divider />
          </span>
        )}

        {pathname !== `/loan/create/${loan.dUniqueId}` && (
          <span>
            <MenuItem onClick={() => history.push(`/redirect/page/${loan.dUniqueId}/0/${loan.cedula}`)}>
              <FaHandHoldingUsd size="1.5em" className="pr-1" />
              Nuevo Prestamo
            </MenuItem>
            <Divider />
          </span>
        )}

        {pathname !== `/loan/detail/${loan.money_id}/${loan.debtor_id}` && moneyIdCondition && (
          <span>
            <MenuItem
              className="text-primary"
              onClick={() => history.push(`/loan/detail/${loan.money_id}/${loan.debtor_id}`)}>
              <VisibilityIcon className="pr-1" />
              Prestamo Detalle
            </MenuItem>
            <Divider />
          </span>
        )}

        {pathname === `/loan/detail/${loan.money_id}/${loan.debtor_id}` && moneyIdCondition && (
          <span>
            <MenuItem
              onClick={() => history.push(`/view/loan/signature/${loan.money_id}/${loan.debtor_id}/${loan.mUniqueId}`)}>
              <FaFileContract className="pr-1" />
              Ver Documentos
            </MenuItem>
            <Divider />
          </span>
        )}

        {pathname === `/loan/detail/${loan.money_id}/${loan.debtor_id}` && moneyIdCondition && (
          <span>
            <MenuItem onClick={() => history.push(`/loan/payments/${loan.money_id}/${loan.debtor_id}`)}>
              <AiOutlineOrderedList size="1.4em" className="pr-1" />
              Historial de pagos
            </MenuItem>
            <Divider />
          </span>
        )}

        {pathname === `/loan/detail/${loan.money_id}/${loan.debtor_id}` && mainApp === 1 && moneyIdCondition && (
          <span>
            <MenuItem
              className="text-info"
              onClick={() => history.push(`/loan/modify/${loan.money_id}/${loan.debtor_id}`)}>
              <EditIcon className="pr-1" />
              Modificar Prestamo
            </MenuItem>
            <Divider />
            <MenuItem
              className="text-danger"
              onClick={() => history.push(`/loan/delete/${loan.money_id}/${loan.debtor_id}`)}>
              <CancelIcon className="pr-1" />
              Cancelar Prestamo
            </MenuItem>
            <Divider />
          </span>
        )}

        <MenuItem onClick={() => history.push(`/debtor/profile/${loan.dUniqueId}`)}>
          <PersonIcon className="pr-1" />
          Perfil del Cliente
        </MenuItem>
      </Menu>
    </div>
  );
}
