import CreateSpense from "../components/CreateSpense";
// import UpdateBankAmount from "@shared/components/UpdateBankAmountCobro";
import Typography from "@material-ui/core/Typography";
import NumberFormat from "react-number-format";
import DeleteGasto from "@shared/components/DeleteGasto";
import Loading from "@shared/components/Loading";
import { printAmount, getUniqueID } from "@shared/functions";
import { getTimestamp } from "@shared/functions";
import { useAlert } from "react-alert";
import usePouch from "../Hooks/usePouch";
import useData from "../Hooks/useData";
import usePouchQueues from "../Hooks/usePouchQueues";
import { pouches_db } from "../config";
import { syncTypes } from "../components/sync/getDataType";

const Spenses = () => {
  const gastos_db = usePouch(pouches_db.GASTOS);
  const sync_db = usePouchQueues(pouches_db.SYNC_QUEUES);
  const history_db = usePouchQueues(pouches_db.SYNC_HISTORY);
  const alert = useAlert();

  const { data: common, isLoading: c_loading } = useData(pouches_db.OFFLINE_DATA, {
    defaultValue: null,
    _id: "common",
  });

  const { data: gastos, refresh, isLoading, error } = useData(pouches_db.GASTOS);

  if (c_loading) return <Loading />;

  const handleCreateGasto = async (data) => {
    const timestamp = getTimestamp();
    const gUniqueId = timestamp + common.creditor_id;

    const syncronization_id = `R${common.creditor_id}_${common.collect_date}_${getUniqueID()}`;

    const newData = { ...data, gUniqueId, timestamp, syncronization_id };

    let operation1 = false;
    let operation2 = false;

    try {
      await gastos_db.putDoc({
        _id: gUniqueId,
        ...data,
        syncronization_id,
        gUniqueId,
        timestamp,
        creditor_id: common.creditor_id,
        collect_date: common.collect_date,
      });
      operation1 = true;

      const syncQueueData = {
        _id: syncronization_id,
        syncPriority: 5,
        dependsOn: null,
        syncType: syncTypes.CREATE_GASTO,
        postData: newData,
      };
      await sync_db.putSertQueue(syncQueueData);
      operation2 = true;

      const syncHistoryData = {
        _id: syncronization_id,
        data_id: gUniqueId,
        syncType: syncTypes.CREATE_GASTO,
        syncAmount: data.amount,
        syncTitle: "Gasto Registrado",
        syncName: `Descripcion > ${data.description}`,
      };

      await history_db.putSertHistory(syncHistoryData);

      refresh();
      alert.success("Gasto Registrado!", { position: "bottom center" });
    } catch (err) {
      console.error(err);

      // Rollback if any fails
      if (operation1) {
        await gastos_db.deleteDoc(data.gUniqueId);
        console.log("Rolled back the record 1.");
      }

      if (operation2) {
        await sync_db.deleteDoc(syncronization_id);
        console.log("Rolled back the record 2.");
      }

      alert.error("Error Registrando Gasto!");
    }
  };

  const handleDeleteGasto = async (data) => {
    const syncronization_id = `R${common.creditor_id}_${common.collect_date}_${getUniqueID()}`;

    const newData = { ...data, syncronization_id };

    let operation1 = false;
    let operation2 = false;

    try {
      await gastos_db.deleteDoc(data.gUniqueId);
      operation1 = true;

      const syncQueueData = {
        _id: syncronization_id,
        syncPriority: 6,
        dependsOn: data.syncronization_id,
        syncType: syncTypes.DELETE_GASTO,
        postData: newData,
      };
      await sync_db.putSertQueue(syncQueueData);
      operation2 = true;

      const syncHistoryData = {
        _id: syncronization_id,
        data_id: data.gUniqueId,
        syncType: syncTypes.DELETE_GASTO,
        syncAmount: data.amount,
        syncTitle: "Gasto Borrado",
        syncName: `Descripcion > ${data.description}`,
      };

      await history_db.putSertHistory(syncHistoryData);

      alert.error("Gasto Borrado!");
      refresh();
    } catch (err) {
      console.error(err);

      // Rollback if any fails
      if (operation1) {
        await gastos_db.deleteDoc(data.gUniqueId);
        console.log("Rolled back the record.");
      }

      if (operation2) {
        await sync_db.deleteDoc(syncronization_id);
        console.log("Rolled back the record.");
      }

      alert.error("Error borrando gasto!");
    }
  };

  if (error)
    return (
      <div className="alert alert-danger">
        <Typography variant="subtitle2" component="p">
          Hubo un problema cargando los datos.
        </Typography>
      </div>
    );

  if (isLoading) return <Loading />;

  const totalGastos2 = gastos.reduce((n, { amount }) => n + amount, 0);

  return (
    <div className="container mt-3 mb-5">
      <CreateSpense handleCreateGasto={handleCreateGasto} />

      <Typography variant="h6" component="h4" gutterBottom>
        Gastos Registrado {printAmount(totalGastos2)}
      </Typography>

      {/* <Typography variant="h6" component="h4" gutterBottom>
        Caja / Banco:
        <NumberFormat value={common.bank_amount} displayType={"text"} thousandSeparator={true} prefix={" $"} />
        <span className="p-1 text-primary">
          <UpdateBankAmount updateBankAmount={updateBankAmount} common={common} />
        </span>
      </Typography> */}

      {gastos.length === 0 ? (
        <div className="mt-3">
          <Typography variant="subtitle1" component="p" paragraph>
            En esta pagina puede ver todo los gastos aplicado.{" "}
          </Typography>

          <Typography variant="body1" component="p" gutterBottom>
            Por el momento, no se han agregado ningun gasto!
          </Typography>
        </div>
      ) : (
        <table className="table table-striped">
          <thead>
            <tr>
              {/* <th scope="col"></th> */}
              <th scope="col">#</th>
              <th scope="col">Nota</th>
              <th scope="col">Cantidad</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {gastos.map((gasto, index) => (
              <tr key={index}>
                {/* <td>
                  {gasto.synced === true ? (
                    <MdOutlineCloudDone className="text-success" />
                  ) : (
                    <MdOutlineCloudOff className="text-danger" />
                  )}
                </td> */}
                <td className="font-italic">{index + 1}</td>
                <td className="font-italic">{gasto.description}</td>
                <td className="text-danger">
                  {<NumberFormat value={gasto.amount} displayType={"text"} thousandSeparator={true} prefix={" $"} />}
                </td>
                <td>
                  <DeleteGasto deleteGasto={handleDeleteGasto} gasto={gasto} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default Spenses;
