import sendToApi from "./sendToApi";

const syncWithRetry = async (doc, configs, retries = 1) => {
  const { baseDelay, apiRetries } = configs;

  try {
    const success = await sendToApi(doc);
    if (success !== true) throw new Error("API request failed");
    return true; // Sync succeeded
  } catch (error) {
    if (retries < apiRetries) {
      const delay = baseDelay * Math.pow(2, retries);
      const jitter = Math.floor(Math.random() * 1000);
      const delayWithJitter = delay + jitter;

      console.log(`Retrying ${doc._id} in ${delayWithJitter / 1000}s... (Attempt ${retries + 1}/${4})`);

      await new Promise((resolve) => setTimeout(resolve, delayWithJitter));
      return syncWithRetry(doc, configs, retries + 1);
    } else {
      console.error(`Failed to sync ${doc._id} after multiple retries`);
      return error;
    }
  }
};

export default syncWithRetry;
