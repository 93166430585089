import { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
// import ListSubheader from "@material-ui/core/ListSubheader";
import CircularProgress from "@nodes/@material-ui/core/CircularProgress";
import CheckIcon from "@nodes/@material-ui/icons/Check";
import Typography from "@nodes/@material-ui/core/Typography";
import { useDispatch } from "react-redux";
import { setDisplayNav } from "../store/slices/ui";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 600,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function RenderLoadingStates({ data }) {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    const interval = setTimeout(() => {
      const displayLoading = data.find((x) => !x.status);
      if (!displayLoading) {
        dispatch(setDisplayNav({ DisplayNav: true }));
        return history.replace("/dashboard");
      }
    }, 2000);

    return () => clearTimeout(interval);
  }, [data]);

  return (
    <div className="container mt-5 mb-5">
      <Typography variant="h5" paragraph align="center">
        Descargando Datos!
      </Typography>

      <List
        subheader={
          <Typography variant="body1" paragraph align="center">
            <b>Por favor espere!</b>
          </Typography>
        }
        className={classes.root}>
        {data.map((item) => (
          <ListItem key={item.label}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText id="switch-list-label-wifi" primary={item.label} />
            {item.status ? <CheckIcon className="text-success" /> : <CircularProgress size="1.5em" />}
          </ListItem>
        ))}
      </List>
    </div>
  );
}
