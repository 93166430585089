import { AiFillPhone } from "@nodes/react-icons/ai";
import { BsFillCircleFill } from "@nodes/react-icons/bs";
import { ImUser } from "@nodes/react-icons/im";
import { printAmount, formatPhoneNumber, printDate } from "../functions";
import { parseISO } from "@nodes/date-fns";
import "./styles/invoice.css";

const RenderCustomerBalance = ({ data }) => {
  if (!data) return null;

  // const payAmount = +data.balance - +data.paidCredit - +data.paidReferral;

  return (
    <div className="container px-0">
      <div className="row mt-4">
        <div className="col-12 col-lg-12">
          <div className="row">
            <div className="col-sm-6">
              <div>
                <span className="text-600 text-110 text-blue align-middle">{data.name}</span>
              </div>
              <div className="text-grey-m2">
                <div className="my-1">
                  <ImUser /> <b className="text-600">Cliente W{data.customer_id}</b>
                </div>
              </div>
              <div className="text-grey-m2">
                <div className="my-1">
                  <AiFillPhone /> <b className="text-600">{formatPhoneNumber(data.phone)}</b>
                </div>
              </div>
              <div className="text-grey-m2">
                <div className="my-1">
                  - <b className="text-600">Pago Mensual: {printAmount(+data.monthly)}</b>
                </div>
              </div>
              <div className="text-grey-m2">
                <div className="my-1">
                  -{" "}
                  <b className="text-600">
                    &Uacute;ltimo Pago:{" "}
                    {data.lastPaidDate ? printDate(parseISO(data.lastPaidDate), "dd-MMM-yyyy") : "Ninguno Ahun"}
                  </b>
                </div>
              </div>
            </div>

            <div className="text-95 col-sm-6 align-self-start d-sm-flex justify-content-end">
              <hr className="d-sm-none" />
              <div className="text-grey-m2">
                {data.prevBal > 0 && (
                  <div className="my-2">
                    <BsFillCircleFill size="0.9em" style={{ color: "#84B0CA" }} />{" "}
                    <span className="text-600 text-90">Balance Atrasado: </span> {printAmount(+data.prevBal)}
                  </div>
                )}

                <div className="my-2">
                  <BsFillCircleFill size="0.9em" style={{ color: "#84B0CA" }} />{" "}
                  <span className="text-600 text-90">Balance Actual: </span> {printAmount(+data.balance)}
                </div>

                {data.paidCredit > 0 && (
                  <div className="my-2">
                    <BsFillCircleFill size="0.9em" style={{ color: "#84B0CA" }} />{" "}
                    <span className="text-600 text-90">Credito / Promo: </span> {printAmount(+data.paidCredit)}
                  </div>
                )}

                {data.paidReferral > 0 && (
                  <div className="my-2">
                    <BsFillCircleFill size="0.9em" style={{ color: "#84B0CA" }} />{" "}
                    <span className="text-600 text-90">Pago de Referidos: </span> {printAmount(+data.paidReferral)}
                  </div>
                )}

                <div className="my-2">
                  <BsFillCircleFill size="0.9em" style={{ color: "#84B0CA" }} />{" "}
                  <span className="text-600 text-90">Cantidad a pagar: </span> {printAmount(Math.max(+data.balance, 0))}
                </div>

                <div className="my-2">
                  <BsFillCircleFill size="0.9em" style={{ color: "#84B0CA" }} />{" "}
                  <span className="text-600 text-90">Pago Recibido: </span> {printAmount(+data.paidAmount)}
                </div>
              </div>
            </div>
          </div>

          <div className="mt-4"></div>
        </div>
      </div>
    </div>
  );
};

export default RenderCustomerBalance;
