import { useState, useEffect } from "react";
import { RenderInput } from "./Form";
import Grid from "@nodes/@material-ui/core/Grid";
import Typography from "@nodes/@material-ui/core/Typography";
import Box from "@nodes/@material-ui/core/Box";
import FormControl from "@nodes/@material-ui/core/FormControl";
import Button from "@nodes/@material-ui/core/Button";
import Alert from "@nodes/@material-ui/lab/Alert";
import IconButton from "@nodes/@material-ui/core/IconButton";
import OutlinedInput from "@nodes/@material-ui/core/OutlinedInput";
import InputLabel from "@nodes/@material-ui/core/InputLabel";
import InputAdornment from "@nodes/@material-ui/core/InputAdornment";
import BackspaceIcon from "@nodes/@material-ui/icons/Backspace";
import { toast } from "@nodes/react-toastify";
import Grow from "@nodes/@material-ui/core/Grow";
import { printAmount, printDate, newDate, printAbsAmount } from "../functions";
import { FaRegMoneyBillAlt } from "@nodes/react-icons/fa";
import BasicDatePicker from "./BasicDatePicker";
import { AiFillBank } from "@nodes/react-icons/ai";
import TextField from "@nodes/@material-ui/core/TextField";
import RenderSwitchButton from "./RenderSwitchButton";
import { makeStyles } from "@nodes/@material-ui/core/styles";
import { mainApp } from "@/config";

const useStyles = makeStyles({
  root: {
    color: "#ffc107",
  },
});

function customRoundAmount(amount, number = 5) {
  return Math.round(amount / number) * number;
}

const RenderPaymentDepositForm = ({ loan, config, createPayment, showPaymentDate }) => {
  const classes = useStyles();
  const [payment_date, setPaymentDate] = useState(null);
  const [paymentAmount, setPaymentAmount] = useState("");
  const [paymentMora, setPaymentMora] = useState("");
  const [isSubmitting, setSubmitting] = useState(null);
  const [duplexPayment, setDuplexPayment] = useState(false);
  const [fullBalanceWarning, setFullBalanceWarning] = useState(false);
  const [autoPrintReceipt, setAutoPrintReceipt] = useState(config.autoPrintPayment);

  const atrasosAdelantos = (loan.pending - 1) * loan.wPayment - loan.paymentAmount - loan.incomplete;
  useEffect(() => {
    if (
      !loan ||
      loan.paymentMora > 0 ||
      loan.completed >= loan.current_week - +config.moraWeeks ||
      +config.moraPercent < 1
    )
      return;
    const mca =
      loan.npayments < loan.current_week ? loan.balance * (loan.current_week - loan.npayments) : atrasosAdelantos;

    setPaymentMora(customRoundAmount(mca * (+config.moraPercent / 100)));
    //eslint-disable-next-line
  }, [loan, config]);

  const handleChangeMora = (event) => {
    if (!event.target.value) {
      setPaymentMora("");
      return;
    }

    const value = event.target.value * 1;
    setPaymentMora(value);
  };

  const handlePaymentAmount = (amount) => {
    setPaymentAmount(amount);
  };

  // const handleClickMontoMora = (amount, mora) => {
  //   setPaymentAmount(+amount + +mora);
  //   setPaymentMora(+mora);
  // };

  const handleTextboxChange = (event) => {
    if (!event.target.value) {
      setPaymentAmount("");
      return;
    }

    setPaymentAmount(event.target.value * 1);
  };

  const handleClearInput = () => {
    setPaymentAmount("");
  };

  const initialValues = {
    sleepDelay: +loan.paymentAmount !== 0 ? 5 : undefined,
    money_id: loan.money_id,
    mUniqueId: loan.mUniqueId,
    debtor_id: loan.debtor_id,
    user_id: loan.user_id,
    creditor_id: loan.creditor_id,
    current_collect_date: loan.collect_date,
    payment_date: payment_date ?? loan.payment_date,
    type: loan.type,
    modified_time: loan.modified_time,
    paymentAmount,
    paymentMora,
  };

  const submithandler = async (paymentType) => {
    const paymentAmount2 = initialValues.paymentAmount * 1;
    const paymentMora2 = initialValues.paymentMora * 1;

    if (paymentAmount2 < 1) {
      return;
    }

    if (paymentAmount2 < paymentMora2) {
      toast.error(`Cantidad Invalida, revise la cantidad de Mora!`);
      return;
    }

    if (paymentAmount2 - paymentMora2 > loan.balance) {
      toast.error(`Balance del prestamo es ${printAmount(loan.balance)}!`);
      return;
    }

    if (loan.paymentAmount && loan.paymentAmount !== 0 && !duplexPayment) {
      setDuplexPayment(true);
      return;
    }

    if (
      paymentAmount2 - paymentMora2 === loan.balance &&
      loan.npayments - loan.completed !== 1 &&
      !fullBalanceWarning
    ) {
      setFullBalanceWarning(true);
      return;
    }

    setSubmitting(paymentType);
    await createPayment({ ...initialValues, paymentType, name: loan.name, autoPrintReceipt });
    setSubmitting(null);
  };

  if (+loan.is_active === 0) {
    return (
      <Box component="div" p={1}>
        <Typography variant="h6" component="h4" gutterBottom>
          Prestamo Cancelado!
        </Typography>

        <Typography variant="body1" component="p">
          Este prestamo ha sido cancelado desde la app Central.
        </Typography>
      </Box>
    );
  }

  if (+loan.balance === 0) {
    return (
      <Box component="div" p={1}>
        <Typography variant="h6" component="h4" gutterBottom>
          Prestamo Saldado!
        </Typography>

        <Typography variant="body1" component="p">
          El balance de este prestamo es $0.
        </Typography>

        <Typography variant="body1" component="p">
          Prestamo saldo en {printDate(newDate(loan.last_date), "dd-MMM-y")}.
        </Typography>
      </Box>
    );
  }

  return (
    <div>
      {showPaymentDate && (
        <Box paddingLeft={1} paddingBottom={3}>
          <BasicDatePicker
            date={payment_date ?? loan.payment_date}
            setDate={setPaymentDate}
            label={"Fecha de Pago"}
            openTo="date"
          />
        </Box>

        // <Box paddingBottom={3}>{RenderInput("payment_date", "Fecha de Pago  ", "date", false, "outlined")}</Box>
      )}

      <Box paddingBottom={2}>
        <FormControl fullWidth variant="outlined">
          <InputLabel htmlFor="outlined-adornment-amount">Cobrar Cantidad</InputLabel>
          <OutlinedInput
            autoComplete="off"
            margin="dense"
            placeholder="Escriba la cantidad de Pago!"
            type="number"
            name="paymentAmount"
            id="outlined-adornment-amount"
            value={paymentAmount}
            onChange={handleTextboxChange}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            labelWidth={120}
            endAdornment={
              <InputAdornment position="end">
                <IconButton aria-label="toggle password visibility" onClick={handleClearInput}>
                  <BackspaceIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </Box>

      {/* <Grid container spacing={1}>
          <Grid item xs={5}> */}
      <Box paddingBottom={2}>
        <Button
          fullWidth
          size="small"
          variant="outlined"
          color="primary"
          onClick={() => handlePaymentAmount(Math.min(loan.wPayment, loan.balance))}>
          <small>Pagar Cuota {printAbsAmount(Math.min(loan.wPayment, loan.balance))}</small>
        </Button>
      </Box>
      {/* </Grid> */}

      {/* {loan.completed < loan.current_week && (
              <Grid item xs={7}>
              <Button
              size="small"
              variant="outlined"
              color="secondary"
              onClick={() => handleClickMontoMora(Math.min(loan.wPayment, loan.balance), loan.mora)}>
              <small>Pagar Monto + Mora</small>
              </Button>
              </Grid>
              )} */}

      {/* {loan.completed < loan.current_week && (
              <Grid item xs={7}>
              <Button
              size="small"
              variant="outlined"
              color="secondary"
              onClick={() => handleClickMontoMora(Math.min(loan.balance), loan.mora)}>
              <small>Pagar Balance + Mora</small>
              </Button>
              </Grid>
              )} */}

      {atrasosAdelantos > 0 && atrasosAdelantos + loan.wPayment < loan.balance && (
        // <Grid item xs={7}>
        <Box paddingBottom={2}>
          <Button
            fullWidth
            size="small"
            variant="outlined"
            color="primary"
            onClick={() => handlePaymentAmount(Math.min(loan.wPayment, loan.balance) + atrasosAdelantos)}>
            <small>
              Pagar Monto + Atrasos {printAbsAmount(Math.min(loan.wPayment, loan.balance) + atrasosAdelantos)}
            </small>
          </Button>
        </Box>
        // </Grid>
      )}

      {/* <Grid item xs={5}> */}
      <Box paddingBottom={2}>
        <Button
          fullWidth
          size="small"
          variant="outlined"
          color="primary"
          onClick={() => handlePaymentAmount(loan.balance)}>
          <small>Saldar Balance {printAbsAmount(loan.balance)}</small>
        </Button>
      </Box>
      {/* </Grid> */}

      {/* {atrasosAdelantos < 0 && loan.pending > 0 && (
        // <Grid item xs={7}>
        <Box paddingBottom={1}>
          <Button
            fullWidth
            size="small"
            variant="outlined"
            color="default"
            onClick={() => handlePaymentAmount(Math.min(loan.wPayment, loan.balance) + atrasosAdelantos)}>
            <small>Pagar Monto - Adelanto</small>
          </Button>
        </Box>
        // </Grid>
      )} */}
      {/* </Grid> */}

      {fullBalanceWarning && (
        <Box paddingBottom={1}>
          <Grow direction="up" in>
            <Alert variant="outlined" severity="info">
              <Typography variant="body1" component="p">
                Desea saldar este prestamo?, si quiere saldar el prestamo trate nuevamente!
              </Typography>
            </Alert>
          </Grow>
        </Box>
      )}

      {duplexPayment && (
        <Box paddingBottom={1}>
          <Grow direction="up" in>
            <Alert variant="outlined" severity="warning">
              <Typography variant="body1" component="p">
                Este prestamo, <b>ya tiene un pago de {printAmount(loan.paymentAmount)}</b> aplicado,
                <b> si quiere aplicar le otro pago de {printAmount(paymentAmount)}</b> trate nuevamente!
              </Typography>
            </Alert>
          </Grow>
        </Box>
      )}

      <div className="alert alert-secondary font-weight-bold">
        <strong>Cobrar Cantidad: {paymentAmount ? printAmount(paymentAmount) : "$0"}</strong>
        {loan.paymentMora === 0 &&
          loan.completed < loan.current_week - +config.moraWeeks &&
          +config.moraPercent > 0 && (
            <Box p={1}>
              <TextField
                onChange={handleChangeMora}
                color="secondary"
                placeholder="Mora Cantidad!"
                label=""
                type="number"
                variant="outlined"
                value={paymentMora}
                id="standard-start-adornment"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <span className="text-danger">Descontar Mora $</span>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          )}
      </div>

      <Box paddingBottom={3}>
        <Button
          fullWidth
          disabled={isSubmitting === "cash" ? true : false}
          startIcon={<FaRegMoneyBillAlt size="1.2em" />}
          variant="contained"
          color="primary"
          onClick={() => submithandler("cash")}>
          Aplicar Pago Efectivo
        </Button>
      </Box>

      {config.showPaymentTransfer === "1" && (
        <Box paddingBottom={3}>
          <Button
            fullWidth
            disabled={isSubmitting === "deposit" ? true : false}
            startIcon={<AiFillBank size="1.2em" />}
            variant="contained"
            color="primary"
            classes={{ root: classes.root }}
            onClick={() => submithandler("deposit")}>
            Aplicar Transferencia
          </Button>
        </Box>
      )}

      {mainApp === 0 && (
        <RenderSwitchButton
          isDisabled={!loan.permissions?.perm_route_info}
          name="autoPrintReceipt"
          label="Imprimir recibo para este Pago!"
          value={autoPrintReceipt}
          setHandler={setAutoPrintReceipt}
        />
      )}
    </div>
  );
};

export default RenderPaymentDepositForm;
