import { createSlice } from "@reduxjs/toolkit";
import { fetchCommonThunk } from "./common";
import { deletePayment } from "./payments";

const initialState = {
  cuadre_id: null,
  newLoans: [],
  total: { entregas: 0, actanotarial: 0, adelanto: 0, count: 0 },
};

export const newLoansSlice = createSlice({
  name: "newLoans",
  initialState,
  reducers: {
    // resetNewLoans: () => initialState,
    createNewLoan: (state, action) => {
      state.newLoans.unshift({
        ...action.payload,
        loanSearch: action.payload.name,
        postData: undefined,
        syncProps: undefined,
      });
      state.total.entregas += +action.payload.amount;
      state.total.actanotarial += +action.payload.actanotarial;
      state.total.adelanto += +action.payload.adelanto;
      state.total.count += 1;
    },
    modifyNewLoan: (state, { payload }) => {
      state.newLoans.map((x) => {
        if (x.mUniqueId === payload.mUniqueId) {
          state.total.entregas += +payload.amount - x.amount;
          state.total.adelanto += +payload.adelanto - x.adelanto;
          state.total.actanotarial += +payload.actanotarial - x.actanotarial;

          x.percentage = +payload.percentage;
          x.npayments = +payload.npayments;
          x.amount = +payload.amount;
          x.wPayment = +payload.wPayment;
          x.actanotarial = +payload.actanotarial;
          x.adelanto = +payload.adelanto;
          x.discount = +payload.discount;
        }
      });

      // const index = state.newLoans.findIndex((x) => x.money_id === payload.money_id);
      // const newLoan = state.newLoans[index];
      // state.total.entregas += payload.amount - newLoan.amount;
      // state.total.adelanto += payload.adelanto - newLoan.adelanto;
      // state.total.actanotarial += payload.actanotarial - newLoan.actanotarial;
    },
    updateDebtorNewLoan: (state, { payload }) => {
      state.newLoans.map((x) => {
        if (x.dUniqueId === payload.dUniqueId) {
          x.name = payload.name;
          x.nickname = payload.nickname;
        }
      });
    },
    updatePayoff: (state, { payload }) => {
      state.newLoans.map((z) => {
        if (z.money_id === payload.payoffLoanId) {
          z.payoffBalance = 0;
        }
      });
    },
    deleteNewLoan: (state, { payload }) => {
      state.newLoans.map((y) => {
        if (y.mUniqueId === payload.mUniqueId) {
          y.deleted = true;

          state.total.count -= 1;
          state.total.entregas -= +payload.amount;
          state.total.adelanto -= +payload.adelanto;
          state.total.actanotarial -= +payload.actanotarial;
        }
      });
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCommonThunk.fulfilled, (state, { payload }) => {
        if (state.cuadre_id === payload.cuadre_id) return state;
        return { ...initialState, cuadre_id: payload.cuadre_id };
      })
      .addCase(deletePayment.type, (state, { payload }) => {
        state.newLoans.map((z) => {
          if (z.money_id === payload.payoffLoanId) {
            z.payoffBalance = 0;
          }
        });
      });
  },
});

export const selectTotalNewLoans = (state) => state.newLoans.total;
export const selectNewLoans = (state) => state.newLoans.newLoans.filter((x) => !x.deleted);
export const selectNewLoansAll = (state) => state.newLoans.newLoans;
export const selectNewLoanById = (state, money_id) => state.newLoans.newLoans.find((x) => x.money_id === money_id);
export const selectNewLoanByDebtorId = (state, dUniqueId) =>
  state.newLoans.newLoans.find((x) => !x.deleted && x.dUniqueId === dUniqueId);

export const { createNewLoan, modifyNewLoan, updateDebtorNewLoan, deleteNewLoan } = newLoansSlice.actions;
export default newLoansSlice.reducer;
