import { useState, useEffect } from "react";
import { replaceNonDigits } from "@shared/functions";
import { useHistory } from "react-router-dom";
import { useFormikContext } from "@nodes/formik";
import { Form, RenderInput } from "@shared/components/Form";
import { object, addMethod, mixed } from "@nodes/yup";
import Box from "@nodes/@material-ui/core/Box";
import { MdOutlinePersonSearch } from "react-icons/md";
import { validateCedula } from "@shared/functions";
import { Typography } from "@material-ui/core";
import { UseCedulasNames } from "@shared/server/fetcher";

const GetFormikValues = ({ setValues }) => {
  const { values } = useFormikContext();
  useEffect(() => {
    setValues(values);
  }, [values, setValues]);
  return null;
};

const CedulaSearch = () => {
  addMethod(mixed, "validateCedula", validateCedula);

  const history = useHistory();

  const initialValues = { cedula: "" };

  const [formValues, setFormValues] = useState(initialValues);

  UseCedulasNames(formValues.cedula ? formValues.cedula.substring(0, 1) : null);

  const validation = object({
    cedula: mixed().validateCedula(false),
  });

  const button2 = { icon: <MdOutlinePersonSearch />, label: "Continuar", variant: "contained", color: "primary" };

  const submithandler2 = async (data) => {
    const cleanCedula = replaceNonDigits(data.cedula);
    history.push(`/redirect/page/newdebtor/0/${cleanCedula}`);
  };

  return (
    <div className="container mt-5 mb-5">
      <br />
      <Form initialValues={initialValues} validation={validation} submithandler={submithandler2} button={button2}>
        {/* <Box paddingBottom={2}>{RenderInput("cedula", "Numero de Cedula", "tel", false, "standard", "wwww")}</Box> */}
        <Box paddingBottom={2}>
          <Typography variant="h6" gutterBottom className="text-center">
            Conectese al internet!
          </Typography>

          <Typography variant="body1" paragraph>
            Si hay internet el sistema podria automaticamente llenar las casillas con datos relacionado a la cedula!
          </Typography>
        </Box>
        <Box paddingBottom={3}>
          {RenderInput(
            "cedula",
            "Numero de Cedula",
            "tel",
            false,
            "outlined",
            "Conectese al Internet para una mejor experiencia!"
          )}
        </Box>
        {/* <Box paddingBottom={2}>{RenderInput("cedula", "Numero de Cedula", "tel", false, "filled", "wwww")}</Box> */}
        <GetFormikValues setValues={setFormValues} />
      </Form>
    </div>
  );
};

export default CedulaSearch;
