import { useState } from "@nodes/react";
import Button from "@nodes/@material-ui/core/Button";
import Typography from "@nodes/@material-ui/core/Typography";
import Alert from "@nodes/@material-ui/lab/Alert";
import Divider from "@nodes/@material-ui/core/Divider";
import { UseBroadcastMessage } from "@shared/server/fetcher";

const BroadcastMessage = () => {
  const [show, setShow] = useState(true);
  const { data } = UseBroadcastMessage(import.meta.env.VITE_ENV);

  const localName = "broadcastHiddenid";
  const broadcastHiddenid = localStorage.getItem(localName);

  if (!show || data?.id === broadcastHiddenid) return null;

  const handleClick = () => {
    setShow(false);
    localStorage.setItem(localName, data.id);
  };

  const RenderBroadcastMessage = ({ data = {} }) => {
    return (
      <Alert variant="standard" severity="info">
        <Typography variant="h5" component="p" gutterBottom>
          <b>{data.title}</b>
        </Typography>

        {data.body1 && (
          <Typography variant="body1" component="p" paragraph>
            <b>{data.body1}</b>
          </Typography>
        )}

        {data.body2 && (
          <Typography variant="body2" component="p" paragraph>
            <b>{data.body2}</b>
          </Typography>
        )}

        <Divider className="mb-3" />

        <div className="text-right">
          <Button variant="contained" color="secondary" size="small" onClick={handleClick}>
            Ok, Cerrar!
          </Button>
        </div>
      </Alert>
    );
  };

  return data ? <RenderBroadcastMessage data={data} /> : null;
};

export default BroadcastMessage;
