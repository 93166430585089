import { useState, useEffect, useContext, createContext } from "react";
import api from "@shared/server/api";
import { localStorageAuthv2, localStorageCommon } from "../config";

const authContext = createContext();
// Provider component that wraps your app and makes auth object
// ... available to any child component that calls useAuth()
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object
// .. and re-render when it changes
export const useAuth = () => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const initialValue = { user: {}, jwt: null };
  const [data, setData] = useState(initialValue);
  const [errors, setErrors] = useState(null);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    if (data.jwt === null) {
      const localUser = localStorage.getItem(localStorageAuthv2);
      // const localCommon = localStorage.getItem(localStorageCommon);

      if (localUser) {
        const user = JSON.parse(localUser);
        // const common = JSON.parse(localCommon);

        setData(user);
      } else {
        setData(initialValue);
      }
    }

    setLoading(false);
  }, []);

  async function login(postData) {
    setErrors(null);
    setLoading(true);

    try {
      const ipAddress = "1.0.0.27";
      const { data: user } = await api.post("/cobro/login/v2", {
        data: { ...postData, ipAddress },
        foceAppUpdateNumber: import.meta.env.VITE_foceAppUpdateNumber,
      });

      localStorage.setItem(localStorageAuthv2, JSON.stringify(user));

      if (user.action !== "routeDisabled") {
        setData(user);
      }

      setLoading(false);

      return user;
    } catch (error) {
      setLoading(false);
      if (error.response) {
        setErrors(error.response.data.message);
      } else {
        setErrors(error.message);
      }
    }
  }

  function logout() {
    localStorage.removeItem(localStorageAuthv2);
    setData(initialValue);
  }

  function setAuthData(data) {
    setData(data);
  }

  // Return the user object and auth methods
  return { data, errors, isLoading, login, logout, setAuthData };
}
