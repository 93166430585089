import { useState } from "react";
import { object } from "yup";
import { Form } from "@shared/components/Form";
import { useParams } from "react-router";
import SignatureDialog from "@shared/components/SignatureDialog";
import Box from "@material-ui/core/Box";
import RenderLoanContract from "@shared/components/RenderLoanContract";
import FloatingButton from "@shared/components/FloatingButton2";
import ClearIcon from "@material-ui/icons/Clear";
import CheckIcon from "@material-ui/icons/Check";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import { makeStyles, Typography } from "@material-ui/core";
import Loading from "@shared/components/Loading";
import { useSelector } from "react-redux";
// import { getUniqueID } from "@shared/functions";
// import { printEntregaHandler, printLoanContractHandler } from "@shared/functions/print";
import { selectNewLoanById } from "../store/slices/newLoans";
// import { createQueue } from "../store/slices/syncronization";
import { selectCommon } from "../store/slices/common";
import { useHistory } from "react-router-dom";
import { useGetOwnerConfigQuery, useGetRouteQuery, useGetUserConfigQuery, useGetUserQuery } from "../store/slices/api";
import { useAlert } from "react-alert";
import usePouchQueues from "../Hooks/usePouchQueues";
import { syncTypes } from "../components/sync/getDataType";
import { pouches_db } from "../config";

const useStyles = makeStyles(() => ({
  label: {
    fontSize: "20px",
  },
  root: {
    paddingLeft: 1,
    paddingRight: 1,
  },
}));

const LoanSignature = () => {
  const sync_db = usePouchQueues(pouches_db.SYNC_QUEUES);
  const history_db = usePouchQueues(pouches_db.SYNC_HISTORY);
  const alert = useAlert();
  const history = useHistory();
  const { id: money_id } = useParams();
  // const dispatch = useDispatch();
  const common = useSelector(selectCommon);
  const classes = useStyles();
  const [acceptCondition, setAcceptCondition] = useState(false);
  const [allowSecondSig, setAllowSecondSig] = useState(false);
  const [conditionAccepted, setConditionAccepted] = useState(false);
  const [signature, setSignature] = useState(null);
  const [signature2, setSignature2] = useState(null);

  const { data: route } = useGetRouteQuery({
    creditor_id: common.creditor_id,
    user_id: common.user_id,
    collect_date: common.collect_date,
  });
  const { data: ownerConfig } = useGetOwnerConfigQuery(common.owner_id);
  const { data: user } = useGetUserQuery(common.user_id);
  const { data: userConfig } = useGetUserConfigQuery(common.user_id);

  const currentLoan = useSelector((state) => selectNewLoanById(state, money_id));

  if (!route || !currentLoan || !user || !userConfig || !ownerConfig) return <Loading />;

  const initialValues = {
    ...currentLoan,
    signature,
    signature2,
  };

  const validation = object({});
  const button = { label: "Guardar Firma Digital", variant: "contained", color: "primary" };
  const submithandler = async (data) => {
    const redirect = "/entregas";

    // const syncProps = {
    //   syncronization_id: `R${common.creditor_id}_${common.collect_date}_${getUniqueID()}`,
    //   queueTime: Date.now(),
    //   syncPriority: 4,
    //   uniqueType: `sig${currentLoan.mUniqueId}`,
    //   endPoint: "/bgsync/cobro/signature/upload",
    //   syncType: "uploadSignature",
    //   syncAmount: data.amount,
    //   syncTitle: "Firma Digital Registrada",
    //   syncName: `Cliente > ${data.name}`,
    // };

    // if (ownerConfig.autoPrintRenewal === "1") {
    //   printEntregaHandler(data, { ...route, ...common }, { ...user, ...userConfig });
    // }

    // if (ownerConfig.autoPrintLoanContract === "1") {
    //   printLoanContractHandler(data, { ...route, ...common }, { ...user, ...userConfig });
    // }

    //dispatch(removeNonUniqueType(syncProps));
    // dispatch(createQueue({ postData: data, syncProps }));

    const syncronization_id = `R${common.creditor_id}_${common.collect_date}_${money_id}`;
    let operation1 = false;

    try {
      const syncQueueData = {
        _id: syncronization_id,
        syncPriority: 4,
        dependsOn: null,
        syncType: syncTypes.UPLOAD_SIGNATURE,
        postData: { ...data, syncronization_id },
      };

      await sync_db.putSertQueue(syncQueueData);
      operation1 = true;

      const syncHistoryData = {
        _id: syncronization_id,
        data_id: money_id,
        syncType: syncTypes.UPLOAD_SIGNATURE,
        syncAmount: data.amount,
        syncTitle: "Firma Digital Registrada",
        syncName: `Cliente > ${data.name}`,
      };
      await history_db.putSertHistory(syncHistoryData);

      alert.success("Firma Guardada!");
      history.replace(redirect);
    } catch (err) {
      console.error(err);

      // Rollback if any fails
      if (operation1) {
        await sync_db.deleteDoc(syncronization_id);
        console.log("Rolled back the record.");
      }

      alert.error("Error Guardando Firma!");
    }
  };

  const handleAcceptCondition = () => {
    if (!acceptCondition) return;
    setConditionAccepted(true);
  };

  const replacements = {
    name: currentLoan.name,
    amount: currentLoan.amount,
    cedula: currentLoan.cedula,
    route_name: route.route_name,
    given_date: currentLoan.given_date,
  };

  return (
    <div className="mt-4 mb-5">
      <Box p={1} component="div">
        <RenderLoanContract title="Contrato de Prestamo!" data={replacements} />
      </Box>

      {!conditionAccepted && (
        <div className="mt-5">
          <FormGroup style={{ position: "fixed", bottom: "15%", left: "10%" }}>
            <FormControlLabel
              classes={{ label: classes.label }}
              control={
                <Checkbox
                  checked={acceptCondition}
                  onChange={() => setAcceptCondition(!acceptCondition)}
                  name="acceptCondition"
                  color="primary"
                />
              }
              label="Acepto las condiciones!"
            />
          </FormGroup>

          <FloatingButton icon={<ClearIcon />} color="secondary" />

          <FloatingButton icon={<CheckIcon />} callBack={handleAcceptCondition} color="primary" />
        </div>
      )}

      {conditionAccepted && (
        <>
          <div className="container">
            <Typography variant="h6" className="mt-3">
              Firma Digital!
            </Typography>
            <Typography variant="body2">Presione en el espacio en blanco para firmar!</Typography>
          </div>

          <Box p={1} component="div">
            <SignatureDialog signature={signature} setSignature={setSignature} name={currentLoan.name} />
          </Box>

          {allowSecondSig && (
            <Box p={1} component="div">
              <SignatureDialog
                signature={signature2}
                setSignature={setSignature2}
                name={`Garante de ${currentLoan.name}`}
              />
            </Box>
          )}
        </>
      )}

      {signature && (
        <>
          {!allowSecondSig && (
            <Box p={1} component="div">
              <Button fullWidth variant="outlined" onClick={() => setAllowSecondSig(true)} color="primary">
                Agregar Firma de Garante
              </Button>
            </Box>
          )}

          <Box p={1} component="div">
            <Form
              initialValues={initialValues}
              validation={validation}
              submithandler={submithandler}
              button={button}></Form>
          </Box>
        </>
      )}
    </div>
  );
};

export default LoanSignature;
