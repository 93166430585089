import { useState } from "react";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import CloseCuadre from "./CloseCuadre";
import CentralCuadre from "./CentralCuadre";

const Cuadre = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newTab) => {
    setSelectedTab(newTab);
  };

  return (
    <>
      <Tabs
        textColor="primary"
        // variant="scrollable"
        variant="fullWidth"
        scrollButtons="auto"
        value={selectedTab}
        onChange={handleTabChange}>
        <Tab label="Cuadre de Cobro" />
        <Tab label="Cuadre Central" />
      </Tabs>

      {selectedTab === 0 && <CloseCuadre />}
      {selectedTab === 1 && <CentralCuadre />}
    </>
  );
};

export default Cuadre;
