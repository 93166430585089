import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Form, RenderInput } from "@shared/components/Form";
import { number, object } from "yup";
import { useAlert } from "react-alert";
import { verifyAccessCode, uploadDebuggingData } from "@shared/api";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
// import {
//   useGetDebtorsQuery,
//   useGetOwnerConfigQuery,
//   useGetPermissionsQuery,
//   useGetRouteQuery,
//   useGetUserConfigQuery,
//   useGetUserQuery,
// } from "../store/slices/api";

const UploadSyncState = () => {
  const alert = useAlert();
  const history = useHistory();

  const initialValues = {
    accessCode: "",
    owner_id: 1001,
    type: "appState",
  };

  const state = useSelector((state) => state);

  // const { data: debtors } = useGetDebtorsQuery({
  //   creditor_id: common.creditor_id,
  //   user_id: common.user_id,
  //   collect_date: common.collect_date,
  // });

  // const { data: route } = useGetRouteQuery({
  //   creditor_id: common.creditor_id,
  //   user_id: common.user_id,
  //   collect_date: common.collect_date,
  // });
  // const { data: user } = useGetUserQuery(common.user_id);
  // const { data: userConfig } = useGetUserConfigQuery(common.user_id);
  // const { data: ownerConfig } = useGetOwnerConfigQuery(common.owner_id);
  // const { data: permissions } = useGetPermissionsQuery({ creditor_id: common.creditor_id, user_id: common.user_id });

  const reduceState = () => {
    const { api, payments, loans, newLoans, newDebtors, gastos, common, syncronization, auth } = state;
    // let newLoans = [];
    let reducedState = {
      api,
      auth,
      payments,
      gastos,
      loans: loans.loans,
      newLoans,
      common,
      newDebtors,
      syncronization,
    };

    // const loansLength = reducedState.newLoans.length;
    // for (let i = 0; i < loansLength; i++) {
    //   newLoans.push({ ...reducedState.newLoans[i], signature: "Cleared" });
    // }

    return { ...reducedState };
  };

  const validation = object({
    accessCode: number().required("Codigo es requerida!"),
  });

  const button = { label: "Verificar Codigo", color: "primary", variant: "contained" };

  const submithandler = async (data) => {
    try {
      await verifyAccessCode(data);
      await uploadDebuggingData(reduceState());

      history.replace("/");
      alert.success("Datos Subido!");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="container mt-5">
      <Typography variant="h6" paragraph>
        Subir Estado de App!
      </Typography>

      <Typography variant="body1" paragraph>
        Para ejercer la siguiente accion necesita un codigo de autorizacion
      </Typography>

      <Form initialValues={initialValues} validation={validation} submithandler={submithandler} button={button}>
        <Box paddingBottom={3}>
          {RenderInput(
            "accessCode",
            "Codigo de Aceso",
            "number",
            false,
            "outlined",
            "Ingrese el codigo de autorizacion"
          )}
        </Box>
      </Form>

      <Typography className="mt-2" variant="body2" paragraph>
        <b>Cada codigo solo puede ser usado una vez!</b>
      </Typography>
    </div>
  );
};

export default UploadSyncState;
