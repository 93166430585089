export const syncTypes = {
  TESTING: "testing",
  DELETE_GASTO: "deleteGasto",
  CREATE_GASTO: "createGasto",
  UPDATE_BANK: "updateBank",
  CREATE_DEBTOR: "createDebtor",
  CREATE_LOAN: "createLoan",
  CREATE_DEBTOR_LOAN: "createDebtorLoan",
  DEBTORS_REORDER: "loansReorder",
  UPLOAD_IMAGE: "uploadImage",
  UPLOAD_SIGNATURE: "uploadSignature",
};

const getEndPointsType = (syncType) => {
  const types = {
    testing: "/syncronizer-test",
    deleteGasto: "/bgsync/cobro/gasto/delete",
    createGasto: "/bgsync/cobro/gasto/create",
    updateBank: "/bgsync/cobro/bank/update",
    createDebtor: "/bgsync/cobro/debtor/create",
    createLoan: "/bgsync/cobro/loan/create",
    createDebtorLoan: "/bgsync/cobro/debtor/loan/create",
    loansReorder: "/bgsync/cobro/debtors/organize",
    uploadImage: "/bgsync/cobro/image/upload",
    uploadSignature: "/bgsync/cobro/signature/upload",
  };

  return types[syncType] ?? null;
};

export default getEndPointsType;
