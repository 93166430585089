
import Typography from "@nodes/@material-ui/core/Typography";
// import Alert from "@nodes/@material-ui/lab/Alert";
import { printAmount, printDate, newDate } from "../functions";

const DisplayLastPaymentAlert = ({ loan }) => {
  if (!loan.paymentAmount && !loan.paymentMora) return null;

  return (
    <>
      <div style={{ background: "#7ddd81" }} className="alert">
        {/* <Typography variant="body1" component="p">
          Pago Aplicado {printAmount(loan.paymentAmount)} + Mora {printAmount(loan.paymentMora)}!
        </Typography> */}

        <Typography variant="body2" component="p">
          Pago(s) aplicado {printAmount(loan.paymentAmount)} + Mora {printAmount(loan.paymentMora)} |{" "}
          {printDate(newDate(loan.last_date))}!
        </Typography>
      </div>

      {/* <Alert variant="outlined" severity="info">
        <Typography variant="body1" component="p">
          Último pago {printAmount(loan.paymentAmount)} + Mora {printAmount(loan.paymentMora)} |{" "}
          {printDate(newDate(loan.last_date))}!
        </Typography>
      </Alert> */}
    </>
  );
};

export default DisplayLastPaymentAlert;
