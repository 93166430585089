
import { TextField } from "@nodes/formik-material-ui";
import { Field } from "@nodes/formik";

const TextInput = ({ name, label, type, autoFocus, variant, helperText }) => {
  return (
    <Field
      autoFocus={autoFocus}
      fullWidth
      variant={variant}
      autoComplete={name}
      component={TextField}
      key={name}
      type={type}
      helperText={helperText}
      name={name}
      label={label}
    />
  );
};

export default TextInput;
