import Zoom from "@nodes/@material-ui/core/Zoom";
import Fab from "@nodes/@material-ui/core/Fab";
import { makeStyles } from "@nodes/@material-ui/core";
import { useHistory } from "@nodes/react-router-dom";

//This component is used for Loan Signature.

const useStyles = makeStyles(() => ({
  fab: {
    position: "fixed",
    bottom: "3%",
    right: "5%",
  },
  fab2: {
    position: "fixed",
    bottom: "3%",
    left: "5%",
  },
}));

const handleClick = (callBack) => {
  if (callBack) {
    callBack();
  }
};

const FloatingButton = ({ icon, callBack, color }) => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <Zoom in>
      <Fab
        onClick={() => handleClick(callBack)}
        color={color}
        className={color === "primary" ? classes.fab : classes.fab2}>
        {icon}
      </Fab>
    </Zoom>
  );
};

export default FloatingButton;
