import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { selectPaymentById } from "../store/slices/payments";
import Typography from "@nodes/@material-ui/core/Typography";
import Alert from "@nodes/@material-ui/lab/Alert";
import RenderBackButton from "@shared/components/RenderBackButton";
import { GoStop } from "@nodes/react-icons/go";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";

const VerifySavedData = () => {
  const history = useHistory();
  const { id: payment_id } = useParams();
  const payment = useSelector((state) => selectPaymentById(state, payment_id));

  useEffect(() => {
    if (payment) {
      localStorage.removeItem("lastPaymentId");
      history.replace("/dashboard");
    }
  }, []);

  return (
    <div className="container mt-5 mb-5">
      <div className="mt-5 mb-5 text-center">
        <GoStop size="12em" className="text-danger" />
      </div>

      <Alert variant="outlined" severity="error">
        <Typography variant="h6" component="p" gutterBottom className="font-weight-bold">
          Pago no fue guardado!
        </Typography>

        <Typography variant="body1" component="p" paragraph>
          Su ultimo pago realizado no fue guardado correctamente.
        </Typography>

        <Typography variant="body1" component="p" paragraph>
          Por favor, presione el boton de Volver atras y realize el pago nuevamente.
        </Typography>
      </Alert>

      <div className="mt-4">
        <RenderBackButton />
      </div>
    </div>
  );
};

export default VerifySavedData;
