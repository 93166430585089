
import Card from "@nodes/@material-ui/core/Card";
import CardActions from "@nodes/@material-ui/core/CardActions";
import Divider from "@nodes/@material-ui/core/Divider";
import Grid from "@nodes/@material-ui/core/Grid";
import CardHeader from "@nodes/@material-ui/core/CardHeader";
import IconButton from "@nodes/@material-ui/core/IconButton";
import { FaRegMoneyBillAlt } from "@nodes/react-icons/fa";
import CardContent from "@nodes/@material-ui/core/CardContent";
import Typography from "@nodes/@material-ui/core/Typography";
import { makeStyles } from "@nodes/@material-ui/core";
import NumberFormat from "@nodes/react-number-format";
import Chip from "@nodes/@material-ui/core/Chip";
import { printDate, newDate, printAbsAmount, getBadgeColor } from "../functions";
import VisibilityIcon from "@nodes/@material-ui/icons/Visibility";
import { useHistory } from "@nodes/react-router-dom";

const useStyles = makeStyles(() => ({
  CardContent: {
    paddingTop: 0,
  },
  h6: {
    fontSize: 22,
  },
}));

const DebtorLoanCard = ({ loan }) => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <Card raised>
      <CardHeader
        title={
          <Typography className={classes.h6} variant="h6" component="h4">
            <b className="p-1">
              <Chip label={`P${loan.money_id}`} />
            </b>
            | Prestamo {printAbsAmount(loan.amount)}
          </Typography>
        }
        subheader={
          <>
            <Typography variant="body2" component="p">
              <NumberFormat value={loan.balance} displayType={"text"} thousandSeparator={true} prefix={"Balance $"} />
            </Typography>
            <Typography variant="body2" component="p">
              Ultimo Pago: {printDate(newDate(loan.last_date), "dd-MMM-y")}
            </Typography>
          </>
        }
      />
      <CardContent className={classes.CardContent}>
        <Typography variant="body1" component="p">
          Entregado en: {printDate(newDate(loan.given_date), "dd-MMM-y")}
        </Typography>
        <Typography variant="body1" component="p"></Typography>
        <Typography variant="body1" component="p">
          Cuotas Pagas:{" "}
          {<NumberFormat value={loan.wPayment} displayType={"text"} thousandSeparator={true} prefix={"$"} />} |{" "}
          {loan.completed} / {loan.npayments}
        </Typography>
      </CardContent>
      <Divider />
      <CardActions disableSpacing>
        <Grid container>
          <Grid item xs={6}>
            {loan.balance > 0 ? (
              <IconButton
                aria-label="add sector"
                onClick={() => history.push(`/payment/create/${loan.money_id}/${loan.debtor_id}`)}>
                <FaRegMoneyBillAlt className="text-success" />
              </IconButton>
            ) : (
              <IconButton>
                <FaRegMoneyBillAlt />
              </IconButton>
            )}

            <IconButton
              aria-label="share"
              onClick={() => history.push(`/loan/detail/${loan.money_id}/${loan.debtor_id}`)}>
              <VisibilityIcon className="text-primary" />
            </IconButton>
          </Grid>
          <Grid item xs={6} align="right">
            {loan.is_active === "0" ? (
              <Typography variant="subtitle2" component="p" className="badge badge-pill badge-danger">
                <b>Cencelado</b>
              </Typography>
            ) : loan.statusText === "Al Dia" ? (
              <Typography variant="subtitle2" component="p" className="badge badge-pill badge-success">
                <i>{loan.statusText}</i>
              </Typography>
            ) : (
              <Typography variant="subtitle2" component="p" className={getBadgeColor(loan.statusText)}>
                <i>
                  {loan.statusText}: {printAbsAmount(loan.statusAmount)}
                </i>
              </Typography>
            )}
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
};

export default DebtorLoanCard;
