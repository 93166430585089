
import Typography from "@nodes/@material-ui/core/Typography";
import { printDate, newDate, printAbsAmount } from "../functions";
import HistoryPaymentMenu from "./HistoryPaymentMenu";

export default function RenderLoanPayment({ data, history, payments = [] }) {
  //       data={{ route, configs: { ...routeConfig, ...userConfig } }}
  if (payments.length === 0)
    return (
      <div className="container mt-4 mb-4">
        <Typography variant="h6">No pagos aplicado!!</Typography>
      </div>
    );

  const paymentsTotal = payments.reduce((acc, obj) => {
    return obj.is_active === "1" ? acc + +obj.paymentAmount : acc;
  }, 0);

  const morasTotal = payments.reduce((acc, obj) => {
    return obj.is_active === "1" ? acc + +obj.paymentMora : acc;
  }, 0);

  return (
    <>
      <Typography variant="h5" gutterBottom>
        Historial de Pagos!
      </Typography>
      <div style={{ maxHeight: 600, width: "100%", overflow: "scroll" }}>
        <table style={{ minWidth: 500 }} className="table table-striped">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Fecha</th>
              <th scope="col">Cantidad</th>
              <th scope="col">Mora</th>
              <th scope="col">Atrasos</th>
              <th scope="col"></th>
            </tr>
          </thead>
          {data.route.ISO === "US" ? (
            <tbody>
              {payments.map((payment, index) => (
                <tr key={payment.payment_id}>
                  <th scope="row">{index + 1}</th>
                  <td className={payment.is_active !== "1" ? "text-danger" : ""}>
                    {printDate(newDate(payment.payment_date))}
                  </td>
                  <td>{printAbsAmount(payment.paymentAmount)}</td>
                  <td>{printAbsAmount(payment.paymentMora)}</td>
                  <td>{printAbsAmount(payment.paymentMora)}</td>
                  <td align="right">
                    <HistoryPaymentMenu history={history} route={data.route} payment={payment} configs={data.configs} />
                  </td>
                </tr>
              ))}
            </tbody>
          ) : (
            <tbody>
              {payments.map((payment, index) => (
                <tr
                  key={payment.payment_id}
                  style={{
                    textDecoration: payment.is_active !== "1" ? "line-through" : "none",
                  }}>
                  {/* var icon = (area == 1) ? icon1 : (area == 2) ? icon2 : icon0; */}
                  <th scope="row">{index + 1}</th>
                  <td>{printDate(newDate(payment.payment_date))}</td>
                  <td>{printAbsAmount(payment.paymentAmount)}</td>
                  <td>{printAbsAmount(payment.paymentMora)}</td>
                  <td className={payment.atrasos > 0 ? "text-danger" : payment.atrasos < 0 ? "text-success" : ""}>
                    {printAbsAmount(payment.atrasos)}
                  </td>
                  <td align="right">
                    <HistoryPaymentMenu history={history} route={data.route} payment={payment} configs={data.configs} />
                  </td>
                </tr>
              ))}
            </tbody>
          )}
          <thead>
            <tr className="table-secondary">
              <th scope="col">+</th>
              <th scope="col">Total</th>
              <th scope="col">{printAbsAmount(paymentsTotal)}</th>
              <th scope="col">{printAbsAmount(morasTotal)}</th>
              <th scope="col">
                <span
                  className={
                    data.loan.statusAmount > 0 ? "text-danger" : data.loan.statusAmount < 0 ? "text-success" : ""
                  }>
                  {printAbsAmount(data.loan.statusAmount)}
                </span>
              </th>
              <th scope="col"></th>
            </tr>
          </thead>
        </table>
      </div>
    </>
  );
}
