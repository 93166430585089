import Card from "@nodes/@material-ui/core/Card";
import Box from "@nodes/@material-ui/core/Box";
import CardContent from "@nodes/@material-ui/core/CardContent";
import Typography from "@nodes/@material-ui/core/Typography";
import FormGroup from "@nodes/@material-ui/core/FormGroup";
import FormControlLabel from "@nodes/@material-ui/core/FormControlLabel";
import Checkbox from "@nodes/@material-ui/core/Checkbox";
import { mainApp } from "@/config";

const RenderOwnerConfig = ({ route, ownerConfig, handleOwnerConfigChange, permissions = {} }) => {
  return (
    <>
      <Card>
        <Typography className="pl-2 text-warning" variant="body1" component="p">
          Estas configuraciones aplican a todas sus ruta(s).
        </Typography>

        <CardContent>
          <FormGroup row>
            <Box paddingBottom={0}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!permissions.perm_route_info && mainApp === 0}
                    checked={+ownerConfig.autoPrintPayment === 1 ? true : false}
                    onChange={() => handleOwnerConfigChange("autoPrintPayment", ownerConfig.autoPrintPayment)}
                    name={"autoPrintPayment"}
                    value={ownerConfig.autoPrintPayment}
                  />
                }
                label="Auto imprimir recibo de pago!"
              />
            </Box>
          </FormGroup>

          <FormGroup row>
            <Box paddingBottom={0}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!permissions.perm_route_info && mainApp === 0}
                    checked={+ownerConfig.autoPrintRenewal === 1 ? true : false}
                    onChange={() => handleOwnerConfigChange("autoPrintRenewal", ownerConfig.autoPrintRenewal)}
                    name={"autoPrintRenewal"}
                    value={ownerConfig.autoPrintRenewal}
                  />
                }
                label="Auto imprimir detalles de nuevo Prestamos!"
              />
            </Box>
          </FormGroup>

          <FormGroup row>
            <Box paddingBottom={0}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!permissions.perm_route_info && mainApp === 0}
                    checked={+ownerConfig.autoPrintLoanContract === 1 ? true : false}
                    onChange={() => handleOwnerConfigChange("autoPrintLoanContract", ownerConfig.autoPrintLoanContract)}
                    name={"autoPrintLoanContract"}
                    value={ownerConfig.autoPrintLoanContract}
                  />
                }
                label="Auto imprimir contrato de nuevo Prestamos!"
              />
            </Box>
          </FormGroup>

          <FormGroup row>
            <Box paddingBottom={0}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!permissions.perm_route_info && mainApp === 0}
                    checked={+ownerConfig.showAbonoPayments === 1 ? true : false}
                    onChange={() => handleOwnerConfigChange("showAbonoPayments", ownerConfig.showAbonoPayments)}
                    name={"showAbonoPayments"}
                    value={ownerConfig.showAbonoPayments}
                  />
                }
                label="Mostrar pagos de Abono en Recibo de Pago!"
              />
            </Box>
          </FormGroup>

          <FormGroup row>
            <Box paddingBottom={0}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!permissions.perm_route_info && mainApp === 0}
                    checked={+ownerConfig.showLoanBalance === 1 ? true : false}
                    onChange={() => handleOwnerConfigChange("showLoanBalance", ownerConfig.showLoanBalance)}
                    name={"showLoanBalance"}
                    value={ownerConfig.showLoanBalance}
                  />
                }
                label="Mostrar balance de prestamo en recibo de Pago!"
              />
            </Box>
          </FormGroup>

          <FormGroup row>
            <Box paddingBottom={0}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={true}
                    checked={+ownerConfig.showMoraBalance === 1 ? true : false}
                    onChange={() => handleOwnerConfigChange("showMoraBalance", ownerConfig.showMoraBalance)}
                    name={"showMoraBalance"}
                    value={ownerConfig.showMoraBalance}
                  />
                }
                label="Mostrar balance de Mora en recibo de Pago!"
              />
            </Box>
          </FormGroup>

          <FormGroup row>
            <Box paddingBottom={0}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!permissions.perm_route_info && mainApp === 0}
                    checked={+ownerConfig.showPaymentTransfer === 1 ? true : false}
                    onChange={() => handleOwnerConfigChange("showPaymentTransfer", ownerConfig.showPaymentTransfer)}
                    name={"showPaymentTransfer"}
                    value={ownerConfig.showPaymentTransfer}
                  />
                }
                label="Mostrar boton para aplicar pago de transferencia!"
              />
            </Box>
          </FormGroup>

          <FormGroup row>
            <Box paddingBottom={0}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!permissions.perm_route_info && mainApp === 0}
                    checked={+ownerConfig.digitalContract === 1 ? true : false}
                    onChange={() => handleOwnerConfigChange("digitalContract", ownerConfig.digitalContract)}
                    name={"digitalContract"}
                    value={ownerConfig.digitalContract}
                  />
                }
                label="Habilitar contrato de prestamo Digital!"
              />
            </Box>
          </FormGroup>

          <FormGroup row>
            <Box paddingBottom={0}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!permissions.perm_route_info && mainApp === 0}
                    checked={+ownerConfig.autoDepurar === 1 ? true : false}
                    onChange={() => handleOwnerConfigChange("autoDepurar", ownerConfig.autoDepurar)}
                    name={"autoDepurar"}
                    value={ownerConfig.autoDepurar}
                  />
                }
                label="Auto depurar cliente para nueva entregas!"
              />
            </Box>
          </FormGroup>

          <FormGroup row>
            <Box paddingBottom={0}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={["local", "testing"].includes(import.meta.env.VITE_ENV) ? false : true}
                    checked={+ownerConfig.allowDuplicateDebtor === 1 ? true : false}
                    onChange={() => handleOwnerConfigChange("allowDuplicateDebtor", ownerConfig.allowDuplicateDebtor)}
                    name={"allowDuplicateDebtor"}
                    value={ownerConfig.allowDuplicateDebtor}
                  />
                }
                label="Permitir la creacion del mismo cliente en multiple rutas!"
              />
            </Box>
          </FormGroup>

          <FormGroup row>
            <Box paddingBottom={0}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={["local", "testing"].includes(import.meta.env.VITE_ENV) ? false : true}
                    // disabled={!permissions.perm_route_info && mainApp === 0}
                    checked={+ownerConfig.loanIdentification === 1 ? true : false}
                    onChange={() => handleOwnerConfigChange("loanIdentification", ownerConfig.loanIdentification)}
                    name={"loanIdentification"}
                    value={ownerConfig.loanIdentification}
                  />
                }
                label="Requerir foto de identificacion para nueva entregas!"
              />
            </Box>
          </FormGroup>
        </CardContent>
      </Card>
    </>
  );
};

export default RenderOwnerConfig;
