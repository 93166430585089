// import api from "@shared/server/api";
import getEndPointsType from "./getDataType";

const sendToApi = async (doc) => {
  try {
    const endPoint = getEndPointsType(doc.syncType);
    const response = await fetch(import.meta.env.VITE_API_URL + endPoint, {
      mode: "cors",
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ ...doc.postData, triesCount: doc.triesCount }),
    });

    if (response.ok) {
      return true;
    } else {
      const errorText = await response.text();
      throw new Error(`Sync failed: ${errorText}`);
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};

export default sendToApi;
