import Typography from "@nodes/@material-ui/core/Typography";
import { mainApp } from "@/config";

const style = {
  backgroundColor: mainApp === 1 ? "#860c15" : "#2d3c91",
  color: "#fff",
  paddingTop: 3,
  paddingBottom: 3,
};

const RenderTestUserWarning = ({ route }) => {
  if (import.meta.env.DEV || +route?.user_id !== 2115) return null;

  return (
    <div className="text-center" style={style}>
      <Typography variant="h6" component="p">
        <b>Esta usando usuario de prueba!</b>
      </Typography>
    </div>
  );
};

export default RenderTestUserWarning;
