
import TextField from "@nodes/@material-ui/core/TextField";
// import InputAdornment from "@nodes/@material-ui/core/InputAdornment";
// import BackspaceIcon from "@nodes/@material-ui/icons/Backspace";
// import IconButton from "@nodes/@material-ui/core/IconButton";

const SearchBox = ({ onChange, value, placeHolder }) => {
  // const handleClearInput = () => {
  //   onChange("");
  // };

  return (
    <TextField
      autoComplete="off"
      className="form-control mb-2 mt-3"
      type="text"
      color="secondary"
      placeholder={placeHolder}
      name="search"
      onChange={(e) => {
        onChange(e.currentTarget.value);
      }}
      // endAdornment={
      //   <InputAdornment position="end">
      //     <IconButton aria-label="toggle password visibility" onClick={handleClearInput}>
      //       <BackspaceIcon />
      //     </IconButton>
      //   </InputAdornment>
      // }
      value={value}
    />
  );
};

export default SearchBox;
