import Box from "@nodes/@material-ui/core/Box";
import Typography from "@nodes/@material-ui/core/Typography";
import Grid from "@nodes/@material-ui/core/Grid";
import { printAmount, printDate, newDate } from "../functions";
import { addWeeks } from "@nodes/date-fns";
import Chip from "@nodes/@material-ui/core/Chip";

const RenderLoanDetail = ({ loan = {} }) => {
  const endDate = addWeeks(newDate(loan.given_date), loan.npayments);

  return (
    <div>
      <Typography variant="h6" component="h4">
        <img
          alt=""
          src={loan.isMain === "0" ? "/../../cobro-icon-57x57.png" : "/../../main-icon-57x57.png"}
          style={{ width: 22, height: 22, marginRight: 3 }}
        />
        Detalle del Prestamo
        <b className="p-2">
          <Chip label={`P${loan.money_id}`} />
        </b>
      </Typography>

      <Grid container>
        <Grid item xs={6}>
          <Box component="div" p={1}>
            <Typography variant="body2" component="p">
              Entregado
            </Typography>

            <Typography variant="h6" component="h4">
              <span className="badge badge-dark p-2">{printDate(newDate(loan.given_date))}</span>
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box component="div" p={1}>
            <Typography variant="body2" component="p">
              Proximo Pago
            </Typography>
            <Typography variant="h6" component="h4">
              <span className="badge badge-dark p-2">{printDate(newDate(loan.next_date))}</span>
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box component="div" p={1}>
            <Typography variant="body2" component="p">
              Vence en
            </Typography>

            <Typography variant="h6" component="h4">
              <span className="badge badge-dark p-2">{printDate(endDate)}</span>
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box component="div" p={1}>
            <Typography variant="body2" component="p">
              &Uacute;ltimo Pago
            </Typography>

            <Typography variant="h6" component="h4">
              <span className="badge badge-dark p-2">{printDate(newDate(loan.last_date))}</span>
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box component="div" p={1}>
            <Typography variant="body2" component="p">
              Cantidad
            </Typography>

            <Typography variant="h6" component="h4">
              <span className="badge badge-dark p-2">{printAmount(loan.amount)}</span>
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box component="div" p={1}>
            <Typography variant="body2" component="p">
              Monto
            </Typography>

            <Typography variant="h6" component="h4">
              <span className="badge badge-dark p-2">{printAmount(loan.wPayment)}</span>
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box component="div" p={1}>
            <Typography variant="body2" component="p">
              Balance
            </Typography>

            <Typography variant="h6" component="h4">
              <span className="badge badge-dark p-2">{printAmount(loan.balance)}</span>
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box component="div" p={1}>
            <Typography variant="body2" component="p">
              Cuotas Pagas
            </Typography>

            <Typography variant="h6" component="h4">
              <span className="badge badge-dark p-2">
                {loan.completed} / {loan.npayments}
              </span>
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box component="div" p={1}>
            <Typography variant="body2" component="p">
              X Cobrar
            </Typography>

            <Typography variant="h6" component="h4">
              <span className="badge badge-dark p-2">
                {loan.xCobrarAmountTotal > 0 ? printAmount(loan.xCobrarAmountTotal) : "$0"}
              </span>
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box component="div" p={1}>
            <Typography variant="body2" component="p">
              X Cobrar Mora
            </Typography>

            <Typography variant="h6" component="h4">
              {/* <span className="badge badge-dark p-2">{printAmount(loan.mora)}</span> */}
              <span className="badge badge-dark p-2">N/A</span>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default RenderLoanDetail;
