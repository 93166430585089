import React, { useEffect } from "react";
import Card from "@nodes/@material-ui/core/Card";
import CardContent from "@nodes/@material-ui/core/CardContent";
import Button from "@nodes/@material-ui/core/Button";
import Container from "@nodes/@material-ui/core/Container";
import Typography from "@nodes/@material-ui/core/Typography";

const NotFound = ({ history }) => {
  useEffect(() => {
    console.log("Page not found.", history.location.state.from);
  }, [history.location.state.from]);

  return (
    <Card className="mt-5 mb-5" raised>
      <CardContent>
        <Typography className="mt-3" component="p" variant="h5">
          Esta pagina no se encuentra!
        </Typography>
        <Typography className="mt-4" component="p" variant="body1">
          <i>Recuerde notificar al Administrador de esta aplicacion para que arregle este fallo</i>.
        </Typography>

        <Typography className="mt-3 mb-5" component="p" variant="body1">
          Haga clic en el siguiente boton para ir a la pagina de Incio!
        </Typography>

        <Container maxWidth="xs">
          <Button fullWidth variant="contained" color="primary" onClick={() => history.replace("/")}>
            Ir a Inicio
          </Button>
        </Container>
      </CardContent>
    </Card>
  );
};

export default NotFound;
