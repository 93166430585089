
import { CheckboxWithLabel } from "@nodes/formik-material-ui";
import { Field } from "@nodes/formik";

const checkboxLabel = ({ name, label, color }) => {
  return (
    <Field
      component={CheckboxWithLabel}
      Label={{ label: label }}
      key={name}
      color={color}
      type="checkbox"
      name={name}
      label={label}
    />
  );
};

export default checkboxLabel;
