import { Route, Switch, Redirect, useHistory } from "@nodes/react-router-dom";
import ProtectedRoute from "@/components/ProtectedRoute";
import NotFound from "./pages/NotFound";
// import PasswordPhoneVerify from "./pages/PasswordPhoneVerify";
// import UserPhoneVerify from "./pages/UserPhoneVerify";
// import UserLookup from "./pages/UserLookup";
// import PasswordNew from "./pages/PasswordNew";
// import PrintPaymentReceipt from "./pages/PrintPaymentReceipt";
// import PasswordResetMethod from "./pages/PasswordResetMethod";
// import Referrals from "./pages/Referrals";
import CustomerBalance from "./pages/CustomerBalance";
// import DebtorLoansCheck from "./pages/DebtorLoansCheck";
// import PaymentDetails from "./pages/PaymentDetails";
import QuickPrinter from "./pages/QuickPrinter";
// import UserPersonal from "./pages/UserPersonal";
// import UserUsername from "./pages/UserUsername";
// import UserPassword from "./pages/UserPassword";
// import LoanPayments from "./pages/LoanPayments";
// import LoanContract from "./pages/LoanContract";
// import RouteDisabled from "./pages/RouteDisabled";
// import RouteWarning from "./pages/RouteWarning";
// import SecurityVerify from "./pages/SecurityVerify";
// import SecurityQuestions from "./pages/SecurityQuestions";

const SharedRoutes = () => {
  const history = useHistory();
  return (
    <Switch>
      {/* <ProtectedRoute path="/referrals" render={(props) => <Referrals {...props} />} /> */}
      {/* <ProtectedRoute path="/user/personal/:id/:t?" render={(props) => <UserPersonal {...props} />} />
      <ProtectedRoute path="/user/username/:id/:t?" render={(props) => <UserUsername {...props} />} />
      <ProtectedRoute path="/user/password/:id/:t?" render={(props) => <UserPassword {...props} />} /> */}
      {/* <ProtectedRoute path="/quickprinter" render={(props) => <QuickPrinter {...props} />} /> */}
      {/* <ProtectedRoute path="/loan/payments/:id/:id2" render={(props) => <LoanPayments {...props} />} /> */}
      {/* <ProtectedRoute path="/security/questions" render={(props) => <SecurityQuestions {...props} />} /> */}
      {/* <ProtectedRoute path="/print/receipt/:type/:mId/:mId2?" render={(props) => <PrintPaymentReceipt {...props} />} /> */}
      {/* <ProtectedRoute path="/view/loan/signature/:id/:id2/:id3" render={(props) => <LoanContract {...props} />} /> */}

      {/* <ProtectedRoute path="/user/phone/verify" render={(props) => <UserPhoneVerify {...props} />} /> */}
      {/* <ProtectedRoute path="/payment/details/:id/:id2/:id3" render={(props) => <PaymentDetails {...props} />} /> */}

      {/* <ProtectedRoute path="/customer/balance/:id/:id2?" render={(props) => <CustomerBalance {...props} />} /> */}

      {/* <ProtectedRoute path="/debtor/loans/check/:dUn/:mId?" render={(props) => <DebtorLoansCheck {...props} />} /> */}
      {/* 
      <Route path="/user/lookup" render={(props) => <UserLookup {...props} />} /> */}
      {/* <Route path="/password/resetmethod" render={(props) => <PasswordResetMethod {...props} />} /> */}
      {/* <Route path="/password/phone/verify" render={(props) => <PasswordPhoneVerify {...props} />} /> */}
      {/* <Route path="/security/verify" render={(props) => <SecurityVerify {...props} />} /> */}
      {/* <Route path="/password/new/:id/:salt/:time" render={(props) => <PasswordNew {...props} />} /> */}
      {/* <Route path="/route/pending/disabled/:i/:i2" render={(props) => <RouteDisabled {...props} />} />
      <Route path="/route/pending/warning/:route" render={(props) => <RouteWarning {...props} />} /> */}
      <Route path="/quickprinter" render={(props) => <QuickPrinter {...props} />} />
      <Route path="/customer/balance/:id/:id2?" render={(props) => <CustomerBalance {...props} />} />
      <Route path="/notfound" render={(props) => <NotFound {...props} />} />
      <Redirect
        to={{
          pathname: "/notfound",
          state: { from: history.location.pathname },
        }}
      />
    </Switch>
  );
};

export default SharedRoutes;
