import { useState } from "react";
import Typography from "@nodes/@material-ui/core/Typography";
import { makeStyles } from "@nodes/@material-ui/core/styles";
import Accordion from "@nodes/@material-ui/core/Accordion";
import AccordionDetails from "@nodes/@material-ui/core/AccordionDetails";
import AccordionSummary from "@nodes/@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@nodes/@material-ui/icons/ExpandMore";
import CircularProgress from "@nodes/@material-ui/core/CircularProgress";
import { printAbsAmount, printAmount } from "../functions";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(14),
    flexShrink: 0,
    flexBasis: "60%",
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const RenderCuadreData = ({ data, collected, gastos, entregas }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  if (!data?.cuadre_id) return null;

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      <Accordion expanded={expanded === "panel0"} onChange={handleChange("panel0")}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel0bh-content" id="panel0bh-header">
          <Typography className={classes.heading}>Prestamos X Cobrar</Typography>

          <Typography className={classes.secondaryHeading}>(-) {printAbsAmount(data.totalCuentaXcobrarSum)}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ maxHeight: 200, overflow: "auto", width: "100%", paddingLeft: 12 }}>
            <ol style={{ paddingLeft: 12 }}>
              {data.cuentaXcobrar.map((loan) => {
                if (loan.atrasos > 0)
                  return (
                    <li key={loan.money_id}>
                      <Typography variant="body2" component="p">
                        {printAbsAmount(loan.amount)} (<span className="text-danger">{printAmount(loan.atrasos)}</span>){" "}
                        {loan.name}
                      </Typography>
                    </li>
                  );

                return (
                  <li key={loan.money_id}>
                    <Typography variant="body2" component="p">
                      {printAbsAmount(loan.amount)} {loan.name}
                    </Typography>
                  </li>
                );
              })}
            </ol>
            {data.cuentaXcobrar.length === 0 && (
              <Typography variant="body2" component="p">
                No prestamos sin Cobrar!
              </Typography>
            )}
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
          <Typography className={classes.heading}>Entrega de Prestamos</Typography>

          {entregas.loading ? (
            <CircularProgress size="1.3em" />
          ) : (
            <Typography className={classes.secondaryHeading}>(-) {printAbsAmount(entregas.totalNewLoans)}</Typography>
          )}
        </AccordionSummary>
        <AccordionDetails>
          <ol style={{ paddingLeft: 12 }}>
            {!entregas.loading &&
              entregas.data.map((loan) => (
                <li key={loan.money_id}>
                  <Typography variant="body2" component="p">
                    {printAbsAmount(loan.amount)} {loan.name}
                  </Typography>
                </li>
              ))}
          </ol>
          {entregas.totalNewLoans === 0 && (
            <Typography variant="body2" component="p">
              No prestamos registrado!
            </Typography>
          )}
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === "panel2"} onChange={handleChange("panel2")}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2bh-content" id="panel2bh-header">
          <Typography className={classes.heading}>Acta Notarial</Typography>

          {entregas.loading ? (
            <CircularProgress size="1.3em" />
          ) : (
            <Typography className={classes.secondaryHeading}>(+) {printAbsAmount(entregas.totalActa)}</Typography>
          )}
        </AccordionSummary>
        <AccordionDetails>
          <ol style={{ paddingLeft: 12 }}>
            {!entregas.loading &&
              entregas.data.map((loan) => (
                <li key={loan.money_id}>
                  <Typography variant="body2" component="p">
                    {printAbsAmount(loan.actanotarial)} {loan.name}
                  </Typography>
                </li>
              ))}
          </ol>

          {entregas.totalActa === 0 && (
            <Typography variant="body2" component="p">
              No acta Notarial registrada!
            </Typography>
          )}
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === "panel2v"} onChange={handleChange("panel2v")}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2vbh-content" id="panel2vbh-header">
          <Typography className={classes.heading}>Pagos de Mora</Typography>

          {collected.loading ? (
            <CircularProgress size="1.3em" />
          ) : (
            <Typography className={classes.secondaryHeading}>(+) {printAbsAmount(collected.totalMora)}</Typography>
          )}
        </AccordionSummary>
        <AccordionDetails>
          <ol style={{ paddingLeft: 12 }}>
            {!collected.loading &&
              collected.data
                .filter((x) => x.paymentMora > 0)
                .map((mora) => (
                  <li key={mora.payment_id}>
                    <Typography variant="body2" component="p">
                      {printAbsAmount(mora.paymentMora)} {mora.name}
                    </Typography>
                  </li>
                ))}
          </ol>

          {collected.totalMora === 0 && (
            <Typography variant="body2" component="p">
              No pagos de Mora aplicado!
            </Typography>
          )}
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === "panel3"} onChange={handleChange("panel3")}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3bh-content" id="panel3-1bh-header">
          <Typography className={classes.heading}>Pagos Aplicado</Typography>

          {collected.loading ? (
            <CircularProgress size="1.3em" />
          ) : (
            <Typography className={classes.secondaryHeading}>(+) {printAbsAmount(collected.totalCash)}</Typography>
          )}
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ maxHeight: 200, overflow: "auto", width: "100%", paddingLeft: 12 }}>
            <ol style={{ paddingLeft: 12 }}>
              {!collected.loading &&
                collected.data
                  .filter((x) => x.paymentType === "cash")
                  .map((loan) => (
                    <li key={loan.payment_id}>
                      <Typography variant="body2" component="p">
                        {printAbsAmount(loan.paymentAmount)} {loan.name}
                      </Typography>
                    </li>
                  ))}
            </ol>
            {collected.totalCash === 0 && (
              <Typography variant="body2" component="p">
                No pagos Aplicado!
              </Typography>
            )}
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === "panel3v2"} onChange={handleChange("panel3v2")}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3v2bh-content" id="panel3v2-1bh-header">
          <Typography className={classes.heading}>Pagos Transferencia</Typography>

          {collected.loading ? (
            <CircularProgress size="1.3em" />
          ) : (
            <Typography className={classes.secondaryHeading}>(+) {printAbsAmount(collected.totalDeposit)}</Typography>
          )}
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ maxHeight: 200, overflow: "auto", width: "100%", paddingLeft: 12 }}>
            <ol style={{ paddingLeft: 12 }}>
              {!collected.loading &&
                collected.data
                  .filter((x) => x.paymentType !== "cash")
                  .map((loan) => (
                    <li key={loan.payment_id}>
                      <Typography variant="body2" component="p">
                        {printAbsAmount(loan.paymentAmount)} {loan.name}
                      </Typography>
                    </li>
                  ))}
            </ol>
            {collected.totalDeposit === 0 && (
              <Typography variant="body2" component="p">
                No pagos Aplicado!
              </Typography>
            )}
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === "panel3-1"} onChange={handleChange("panel3-1")}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3-1bh-content" id="panel3-1bh-header">
          <Typography className={classes.heading}>Pagos de Atrasos</Typography>

          <Typography className={classes.secondaryHeading}>(+) {printAbsAmount(data.totalAtrasoSum)}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ maxHeight: 200, overflow: "auto", width: "100%", paddingLeft: 12 }}>
            <ol style={{ paddingLeft: 12 }}>
              {data.atrasos.map((loan) => (
                <li key={loan.money_id}>
                  <Typography variant="body2" component="p">
                    {printAbsAmount(loan.amount)} {loan.name}
                  </Typography>
                </li>
              ))}
            </ol>
            {data.atrasos.length === 0 && (
              <Typography variant="body2" component="p">
                No pagos de atrasos!
              </Typography>
            )}
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === "panel3-123"} onChange={handleChange("panel3-123")}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3-123bh-content" id="panel3-1bh-header">
          <Typography className={classes.heading}>Pagos Vencidos</Typography>

          <Typography className={classes.secondaryHeading}>(+) {printAbsAmount(data.totalVencidoSum)}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ maxHeight: 200, overflow: "auto", width: "100%", paddingLeft: 12 }}>
            <ol style={{ paddingLeft: 12 }}>
              {data.vencidos.map((loan) => (
                <li key={loan.money_id}>
                  <Typography variant="body2" component="p">
                    {printAbsAmount(loan.amount)} {loan.name}
                  </Typography>
                </li>
              ))}
            </ol>
            {data.vencidos.length === 0 && (
              <Typography variant="body2" component="p">
                No pagos de Vencidos!
              </Typography>
            )}
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === "panel3-2"} onChange={handleChange("panel3-2")}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3-2bh-content" id="panel3-2bh-header">
          <Typography className={classes.heading}>Pagos de Adelantos</Typography>

          <Typography className={classes.secondaryHeading}>(+) {printAbsAmount(data.totalAdelantoSum)}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ maxHeight: 200, overflow: "auto", width: "100%", paddingLeft: 12 }}>
            <ol style={{ paddingLeft: 12 }}>
              {data.adelantos.map((loan) => (
                <li key={loan.money_id}>
                  <Typography variant="body2" component="p">
                    {printAbsAmount(loan.amount)} {loan.name}
                  </Typography>
                </li>
              ))}
            </ol>
            {data.adelantos.length === 0 && (
              <Typography variant="body2" component="p">
                No pagos de Adelanto!
              </Typography>
            )}
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === "panel4"} onChange={handleChange("panel4")}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3bh-content" id="panel3bh-header">
          <Typography className={classes.heading}>
            <span className="text-danger">Gastos Aplicado</span>
          </Typography>

          {gastos.loading ? (
            <CircularProgress size="1.3em" />
          ) : (
            <Typography className={classes.secondaryHeading}>
              <span className="text-danger">(-) {printAbsAmount(gastos.total)}</span>
            </Typography>
          )}
        </AccordionSummary>
        <AccordionDetails>
          <ol style={{ paddingLeft: 12 }}>
            {!gastos.loading &&
              gastos.data.map((gasto) => (
                <li key={gasto.id}>
                  <Typography variant="body2" component="p">
                    {printAbsAmount(gasto.amount)} {gasto.description}
                  </Typography>
                </li>
              ))}
          </ol>
          {gastos.total === 0 && (
            <Typography variant="body2" component="p">
              No gastos registrado!
            </Typography>
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default RenderCuadreData;
