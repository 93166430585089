import { useEffect } from "react";
import useNetwork from "@shared/hooks/useNetwork";
import useInterval from "@shared/hooks/useInterval";
import { useSelector, useDispatch } from "react-redux";
import { selectQueues, postQueue, postMultiQueue, removeSyncronization } from "../store/slices/syncronization";

const checkOnlineStatus = async () => {
  try {
    const online = await fetch(import.meta.env.VITE_API_URL + "/cobro/sync/ping", {
      cache: "no-store",
    });

    return online.status >= 200 && online.status < 300; // either true or false }
  } catch (err) {
    return false; // definitely offline
  }
};

const ReduxQueueSync = () => {
  const dispatch = useDispatch();
  const pendingQueues = useSelector(selectQueues);
  const isOnline = useNetwork();

  const excludedTypes = [];

  const startSync = async () => {
    const isConnection = await checkOnlineStatus();

    const queues = pendingQueues.filter((x) => x.queueTime < Date.now());

    if (isConnection !== true) return;
    if (queues.length === 0) return;

    queues.forEach((entry) => {
      if (!excludedTypes.includes(entry.syncType)) {
        try {
          if (entry.triesCount > 10) {
            dispatch(removeSyncronization({ syncronization_id: entry.syncronization_id }));
          } else {
            dispatch(postQueue(entry));
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        // dispatch(postMultiQueue(entry));
      }
    });
  };

  const autoSyncInterval = 30 * 1000; //seconds
  useInterval(async () => {
    startSync();
  }, autoSyncInterval);

  //Retries every everytime isOnline changes
  useEffect(() => {
    startSync();
    //eslint-disable-next-line
  }, [isOnline]);

  return null;
};

export default ReduxQueueSync;
