import Typography from "@nodes/@material-ui/core/Typography";
import { getFormattedLoanContract } from "@shared/functions";
import parse from "@nodes/html-react-parser";

const RenderLoanContract = ({ title, data }) => {
  const result = getFormattedLoanContract(data, true);

  return (
    <div>
      <Typography variant="h6" component="h4" gutterBottom>
        {title}
      </Typography>
      <div
        style={{
          borderBottom: "#969696 solid 1px",
          overflowY: "scroll",
          maxHeight: "50vh",
        }}>
        <Typography variant="body1" component="p" paragraph>
          {parse(result)}
        </Typography>
      </div>
    </div>
  );
};

export default RenderLoanContract;
