import { number, date, mixed, ref, bool, object } from "@nodes/yup";

const percentage0 = number()
  .required("Porciento es requerido!")
  .min(0, "Porciento es muy bajito!")
  .max(60, "Porciento es muy alto!");

const percentage = number()
  .required("Porciento es requerido!")
  .min(10, "Porciento es muy bajito!")
  .max(60, "Porciento es muy alto!");

const npayments = number()
  .required("Cuotas es requerida!")
  .min(5, "Cuotas es muy corta!")
  .max(50, "Cuotas es muy alta!");

const npayments0 = number()
  .required("Cuotas es requerida!")
  .min(1, "Cuotas es muy corta!")
  .max(50, "Cuotas es muy alta!");

const amount = number().required("Cantidad es requerida!").min(500, "Cantidad es muy bajita!");
const actanotarial = number().required("Acta Notarial es requerida!").min(0, "Acta Notarial es muy corta!");

const given_date = date().required("Fecha de entrega es requerida!");

const adelanto = mixed().notOneOf([null, "", 1, 2, 3, 4, 5], "Cantidad de Adelanto es Invalida!");

const discount = number()
  .integer("Descuento Adicional Invalida!")
  .min(0, "Cantidad de Adelanto Invalida!")
  .max(ref("amount"), "Cantidad de Adelanto muy alta!");

export const loanModify = object({
  percentage,
  npayments,
  amount,
  adelanto,
  discount,
  actanotarial,
  given_date,
});

export const loanModifyCentral = object({
  percentage: percentage0,
  npayments: npayments0,
  amount,
  adelanto,
  discount,
  actanotarial,
  given_date,
});

export const loanCreate = object({
  percentage,
  npayments,
  amount,
  adelanto,
  discount,
  actanotarial,
  confirmNewLoan: bool().oneOf([true], "Confirme nueva entrega!"),
});
