import { useState, useMemo, useEffect } from "react";
import { useParams } from "react-router";
import Box from "@material-ui/core/Box";
import RenderLoanModifyForm from "@shared/components/RenderLoanModifyForm";
import { FaRegMoneyBillAlt } from "react-icons/fa";
import Loading from "@shared/components/Loading";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import { getUniqueID } from "@shared/functions";
import DebtorNameHeader from "@shared/components/DebtorNameHeader";
import RenderNewLoanCalculation from "@shared/components/RenderNewLoanCalculation";
import LoanDetailMenu from "@shared/components/LoanDetailMenu";
import { useSelector, useDispatch } from "react-redux";
import { modifyNewLoan, selectNewLoanById } from "../store/slices/newLoans";
import { selectCommon } from "../store/slices/common";
// import { alertInfo } from "../store/slices/snackbar";
// import Alert from "@material-ui/lab/Alert";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";
// import Button from "@material-ui/core/Button";
// import { RiArrowGoBackLine } from "react-icons/ri";
import RenderBackButton from "@shared/components/RenderBackButton";
import { createQueue } from "../store/slices/syncronization";
import { useGetPermissionsQuery } from "../store/slices/api";
import { useAlert } from "react-alert";
import usePouch from "../Hooks/usePouch";
import { pouches_db } from "../config";

const calcMonto = (percentage = 0, amount = 0, npayments = 0) => {
  const amount2 = amount * 1;
  const npayments2 = npayments * 1;
  const percentage2 = percentage * 1;
  if (npayments2 === 0) return 0;

  return Math.ceil(((percentage2 / 100) * amount2 + amount2) / npayments2);
};

const calcEntrega = (amount = 0, actanotarial = 0, adelanto = 0, discount = 0, balance = 0) => {
  const amount2 = amount * 1;
  const balance2 = balance * 1;
  const adelanto2 = adelanto * 1;
  const discount2 = discount * 1;
  const actanotarial2 = actanotarial * 1;
  return amount2 - actanotarial2 - balance2 - adelanto2 - discount2;
};
const calcProfits = (npayments = 0, monto = 0, amount = 0) => {
  const npayments2 = npayments * 1;
  const monto2 = monto * 1;
  const amount2 = amount * 1;
  return npayments2 * monto2 - amount2;
};

const initialFormValues = { amount: "", percentage: "", npayments: "", actanotarial: "", adelanto: "" };

const ModifyEntrega = () => {
  const loans_db = usePouch(pouches_db.LOANS);
  const alert = useAlert();
  const history = useHistory();
  const { id: money_id } = useParams();
  const [currentLoanData, setCurrentLoanData] = useState({});
  const [formValues, setFormValues] = useState(initialFormValues);

  const dispatch = useDispatch();
  const currentLoan = useSelector((state) => selectNewLoanById(state, money_id));
  const common = useSelector(selectCommon);

  const { data: permissions } = useGetPermissionsQuery({ creditor_id: common.creditor_id, user_id: common.user_id });

  const monto = useMemo(() => {
    return calcMonto(formValues.percentage, formValues.amount, formValues.npayments);
  }, [formValues.percentage, formValues.amount, formValues.npayments]);

  const entrega = useMemo(() => {
    return calcEntrega(formValues.amount, formValues.actanotarial, formValues.adelanto, formValues.discount);
  }, [formValues.amount, formValues.actanotarial, formValues.adelanto, formValues.discount]);

  const profit = useMemo(() => {
    return calcProfits(formValues.npayments, monto, formValues.amount);
  }, [formValues.npayments, monto, formValues.amount]);

  if (!currentLoan || !permissions) return <Loading />;

  const initialValues = {
    ...currentLoan,
    actanotarial: currentLoan.actanotarial,
    npayments: currentLoan.npayments,
    percentage: currentLoan.percentage,
    amount: currentLoan.amount,
    adelanto: currentLoan.adelanto,
  };

  useEffect(() => {
    setCurrentLoanData(initialValues);
  }, []);

  const button = {
    icon: <FaRegMoneyBillAlt size="1.2em" />,
    label: "Modificar Entrega",
    variant: "contained",
    color: "primary",
  };

  const submithandler = async (data) => {
    if (JSON.stringify(data) === JSON.stringify(currentLoanData)) {
      history.replace("/entregas");
      return;
    }

    const wPayment = calcMonto(data.percentage, data.amount, data.npayments);
    const oldAdelanto = currentLoan.adelanto;

    const postData = {
      ...data,
      currentLoan: undefined,
      wPayment,
      oldAdelanto,
      user_id: common.user_id,
    };

    const syncProps = {
      data_id: currentLoan.money_id,
      syncronization_id: `R${common.creditor_id}_${common.collect_date}_${getUniqueID()}`,
      queueTime: Date.now(),
      uniqueType: `loan${currentLoan.mUniqueId}`,
      endPoint: "/bgsync/cobro/loan/modify",
      syncPriority: 3,
      syncType: "modifyLoan",
      syncAmount: currentLoan.amount,
      syncTitle: "Entregada Modificada",
      syncName: `Cliente > ${currentLoan.name}`,
      requiredData: true,
    };

    if (permissions.perm_loan === true) {
      //dispatch(removeNonUniqueType(syncProps));

      dispatch(modifyNewLoan(postData));
      dispatch(createQueue({ postData, syncProps }));

      history.replace("/entregas");
      alert.info("Entrega Modificada!");
    } else {
      localStorage.setItem("awaitingAction", JSON.stringify({ postData, syncProps, type: "modifyNewLoan" }));
      history.replace("/access/codes");
    }
  };

  return (
    <div className="mt-3 mb-5">
      <Container maxWidth="md" disableGutters>
        <DebtorNameHeader debtor={currentLoan}>
          <LoanDetailMenu loan={currentLoan} />
        </DebtorNameHeader>

        {/* <Alert variant="outlined" severity="error">
          <Typography variant="h5" component="p" gutterBottom>
            Aviso
          </Typography>

          <Typography variant="body1" component="p" paragraph>
            Por el momento los adelantos no se guardan.
          </Typography>

          <Typography variant="body1" component="p" paragraph>
            Los adelanto actualizado en esta pagina no se guardan de manera correcta, por favor no actualizar{" "}
            <b>Pago de Adelanto</b>
          </Typography>
        </Alert> */}

        <Box component="div" p={1}>
          <Box paddingBottom={1}>
            <Divider />
          </Box>

          <Box div="div" p={1} paddingBottom={2}>
            <RenderNewLoanCalculation data={{ monto, entrega, profit }} />
          </Box>

          {!permissions.perm_loan && (
            <Box className="text-danger" paddingBottom={2}>
              <Divider className="mt-2" />
              <Typography variant="h6" component="h4" gutterBottom>
                No autorizado
              </Typography>
              <Typography variant="body1" component="p" paragraph>
                Usted no esta autorizado para modificar entrega en la app de cobro!
              </Typography>

              <Typography variant="body1" component="p" paragraph>
                Al continuar va requerir de una codigo de aceso!
              </Typography>
              <Divider className="mt-2" />
            </Box>
          )}

          <RenderLoanModifyForm
            setFormValues={setFormValues}
            initialValues={initialValues}
            submithandler={submithandler}
            // duplexNewLoan={duplexNewLoan}
            button={button}
          />

          <div className="mt-3">
            <RenderBackButton />
          </div>
        </Box>
      </Container>
    </div>
  );
};

export default ModifyEntrega;
