import { useEffect } from "react";
import useNetwork from "@shared/hooks/useNetwork";
import usePouchQueues from "../../Hooks/usePouchQueues";
import syncWithRetry from "./syncWithRetry";
import usePouch from "../../Hooks/usePouch";
import { pouches_db, syncStatus } from "../../config";
import sortData from "./sortData";

const checkOnlineStatus = async () => {
  try {
    const online = await fetch(import.meta.env.VITE_API_URL + "/cobro/sync/ping", {
      cache: "no-store",
    });

    return online.status >= 200 && online.status < 300; // either true or false }
  } catch (err) {
    return false; // definitely offline
  }
};

const delayPromise = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const SyncManager = () => {
  const conflicts_db = usePouch(pouches_db.SYNC_CONFLICTS);
  const queue_db = usePouchQueues(pouches_db.SYNC_QUEUES);
  const history_db = usePouchQueues(pouches_db.SYNC_HISTORY);
  const isOnline = useNetwork();

  const configs = {
    maxRetries: 6,
    apiRetries: 4,
    baseDelay: 1000,
    periodicInterval: 3 * 60 * 1000,
  };

  const syncDocument = async (doc, hisDoc) => {
    if (!navigator.onLine) {
      console.log("Offline,navigator.online, skipping syncDocument.");
      return;
    }

    // Attempt to sync the document
    const requestResult = await syncWithRetry(doc, configs);

    if (requestResult === true) {
      if (hisDoc) {
        hisDoc.syncStatus = syncStatus.COMPLETED;
        await history_db.putDoc(hisDoc);
      }

      await queue_db.deleteDoc(doc._id);
    } else {
      let errorData = {};
      if (requestResult.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log("Error Response Status,Data: ", requestResult.response.status, requestResult.response.data);
        console.log("Error Response Headers: ", requestResult.response.headers);

        errorData.errorType = "FAILED_NETWORK_REQUEST_RESPONSE_EXIST";
        errorData.errorResponseStatus = requestResult.response.status;
        errorData.errorResponseHeader = requestResult.response.headers;
        errorData.errorResponseData = requestResult.response.data;
      } else if (requestResult.request) {
        // The request was made but no response was received
        // `requestResult.request` is an instance of XMLHttpRequest in the browser
        // and an instance of http.ClientRequest in node.js
        console.log("Failed request: ", requestResult.request);

        errorData.errorType = "FAILED_NETWORK_REQUEST_EXIST";
        errorData.errorRequest = requestResult.request;
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error Message: ", requestResult.message);

        errorData.errorType = "FAILED_NETWORK_MESSAGE";
        errorData.error = requestResult.message;
      }

      await addConflictError({ ...doc, syncError: { ...errorData } });

      doc.replayAfter = Date.now() + Math.pow(2, doc.triesCount + 1) * 60 * 1000;
      doc.triesCount += 1;
      await queue_db.putDoc(doc);

      if (doc.triesCount >= configs.maxRetries) {
        // Too much retries, storing docs in conflic pouch.
        if (hisDoc) {
          hisDoc.syncStatus = syncStatus.FAILED;
          await history_db.putDoc(hisDoc);
        }

        await queue_db.deleteDoc(doc._id);

        await addConflictError({
          ...doc,
          syncError: {
            errorType: "MAX_LOOP_RETRIES_REACHED",
            errorMaxRetries: "Unsynced because max retries reached.",
          },
        });

        console.error("too much retries, storing this doc as conflict queue.");
      }

      // console.error(`Max retries reached for document ${doc._id}.`);
    }
  };

  const syncAllDocuments = async () => {
    const isConnection = await checkOnlineStatus();

    if (!navigator.onLine) {
      console.log("Offline, navigator.online, skipped syncAllDocuments.");
      return;
    }

    if (!isOnline) {
      console.log("Offline, isOnline, skipped syncAllDocuments.");
      return;
    }

    if (!isConnection) {
      console.log("Offline, isConnection, skipped syncAllDocuments.");
      return;
    }

    // console.log("Sync process started....");

    try {
      // const result = await queue_db.findDocs({
      //   selector: { synced: null },
      // });
      const result = await queue_db.getAllDocs();

      let docs = sortData(result);

      const now = Date.now();
      for (const [index, doc] of docs.entries()) {
        if (doc?.replayAfter > now) continue;

        const hisDoc = await history_db.getDocById(doc._id);

        if (!hisDoc) {
          await queue_db.deleteDoc(doc._id);

          await addConflictError({
            ...doc,
            syncError: {
              errorType: "SYNCRONIZATION_HISTORY_DOC_MISSSING",
              errorDocMissing: `Skipped sync for ${doc._id} because sync_id is not present.`,
            },
          });

          console.log("Syncronization History doc ID doesnt exist.", doc._id);
          continue;
        }

        if (doc.dependsOn) {
          const parentDoc = await history_db.getDocById(doc.dependsOn);

          if (!parentDoc || parentDoc?.syncStatus === syncStatus.FAILED) {
            await queue_db.deleteDoc(doc._id);

            if (hisDoc) {
              hisDoc.syncStatus = syncStatus.FAILED;
              await history_db.putDoc(hisDoc);
            }

            await addConflictError({
              ...doc,
              syncError: {
                errorType: "PARENT_SYNCRONIZATION_DOC_MISSSING",
                errorDocMissing: `skipped because dependency ${doc.dependsOn} is missing or failed to sync.`,
              },
            });

            console.log(`skipped sync for ${doc._id} because dependency ${doc.dependsOn} is missing.`);
            continue;
          }

          if (parentDoc.syncStatus === syncStatus.PENDING) {
            await delayPromise(500);
            console.log(`skipping sync for ${doc._id} because dependency ${doc.dependsOn} is not synced yet.`);
            continue;
          }
        }

        const dynamicDelay = 400 * Math.min(Math.pow(2, doc.triesCount), 10000) + index * 40;
        // console.log(`Index:${index} Delaying for ${dynamicDelay}ms before calling sync...`);
        await delayPromise(dynamicDelay);

        await syncDocument(doc, hisDoc);
      }

      // After every sync period.
      await queue_db.compact();
      await history_db.compact();
      await conflicts_db.compact();

      // const checkForMoreUnsynched = await queue_db.findDocs({
      //   selector: { synced: null },
      //   fields: ["_id"],
      //   limit: 1,
      // });

      // const checkForMoreUnsynched = await queue_db.getAllDocs({ limit: 1 });
      // if (checkForMoreUnsynched.length > 0) {
      //   syncAllDocuments();
      //   return;
      // }
    } catch (error) {
      console.error("Error during sync", error);
    }

    // console.log("All documents are synched.");
  };

  const addConflictError = async (doc) => {
    const { _id, syncType, postData: oldPost, syncError, dependsOn } = doc;
    const currentDoc = await conflicts_db.getDocById(_id);

    if (currentDoc) {
      currentDoc.syncErrorList.push(syncError);

      await conflicts_db.putDoc(currentDoc);
      return;
    }

    const postData = { ...oldPost };

    if (syncType === "uploadImage") delete postData.data;

    if (syncType === "uploadSignature") {
      delete postData.signature;
      delete postData.signature2;
    }

    await conflicts_db.putDoc({ _id, syncType, postData, dependsOn, syncErrorList: [syncError] });
  };

  let syncTimeout;
  let isSyncing = false; // Track if a sync is in progress
  const throttleSync = async (caller = "startOnlineSync", delay = 3000) => {
    if (!navigator.onLine) {
      console.log("Offline,navigator.online, throttled sync skipped.");
      return;
    }

    // console.log({ syncTimeout, isSyncing });

    if (syncTimeout || isSyncing) return; // Prevent overlapping syncs

    console.log("Throttle sync started called from: ", caller);

    syncTimeout = setTimeout(async () => {
      isSyncing = true; // Mark sync as in progress
      await syncAllDocuments(); // Perform the sync
      isSyncing = false; // Mark sync as complete
      syncTimeout = null; // Reset the throttle timer
    }, delay);
  };

  const startOnlineSync = () => {
    window.addEventListener("online", throttleSync);
  };

  let periodicSyncInterval; // To track the interval ID
  const startPeriodicSync = (interval) => {
    if (periodicSyncInterval) {
      console.log("Periodic sync already running.");
      return; // Prevent multiple intervals
    }

    periodicSyncInterval = setInterval(() => {
      throttleSync("startPeriodicSyncc");
    }, interval);
  };

  const stopPeriodicSync = () => {
    // if (periodicSyncInterval) {
    clearInterval(periodicSyncInterval);
    periodicSyncInterval = null;
    console.log("Periodic sync stopped.");
    // }
  };

  useEffect(() => {
    startOnlineSync();
    startPeriodicSync(configs.periodicInterval);
    throttleSync("Initial Sync useEffect"); // Initial sync

    () => {
      stopPeriodicSync();
    };
  }, []);

  return null; // This component does not render anything
};

export default SyncManager;
