import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { logout } from "./auth";
import api from "@shared/server/api";

export const postQueue = createAsyncThunk("syncronization/postQueue", async (queue) => {
  //Try to syncronize pending offline queues from state.

  const { data } = await api.post(queue.endPoint, { ...queue.postData, triesCount: queue.triesCount });
  return data;
});

export const postMultiQueue = createAsyncThunk("syncronization/postMultiQueue", async (queue) => {
  //Try to syncronize pending offline queues from state.

  const { data } = await api.post(queue.endPoint, { ...queue.postData });
  return data;
});

export const createQueue = createAsyncThunk("syncronization/tryOrPostQueue", async (queue, thunkAPI) => {
  const { creditor_id, collect_date, user_id } = thunkAPI.getState().common.common;
  const { postData: currentData, syncProps } = queue;
  const { syncronization_id, endPoint } = syncProps;

  //Attach creditor_id, collect_date, user_id, syncronization_id so when users logout with pending data, common wont be available to sync.
  const postData = { ...currentData, creditor_id, collect_date, user_id, syncronization_id };

  try {
    await api.post(endPoint, postData);
    thunkAPI.dispatch(recordSyncronization({ ...syncProps, network: "online" }));
  } catch (err) {
    console.log("Posting to server failed, Adding to offline Queue ", err);

    thunkAPI.dispatch(addOfflineQueue({ postData, syncProps }));
    thunkAPI.dispatch(recordSyncronization({ ...syncProps, network: "offline" }));
  }
});

const initialState = {
  syncronization: [],
  queues: [],
  totalQueues: 0,
};

export const syncronizationSlice = createSlice({
  name: "syncronization",
  initialState,
  reducers: {
    addOfflineQueue: (state, { payload }) => {
      const { postData, syncProps: sync } = payload;
      const { syncronization_id } = payload.syncProps;

      //Before adding to sync check if any duplicate exist, and remove it before adding new one.
      if (sync.uniqueType) {
        const newSyncs = state.syncronization.filter((s) => s.uniqueType !== sync.uniqueType);

        if (state.syncronization.length !== newSyncs.length) {
          state.queues = state.queues.filter((q) => q.uniqueType !== sync.uniqueType);
          state.syncronization = newSyncs;
          state.syncronization.push({
            syncronization_id,
            type: sync.syncType,
            data_id: sync.data_id ?? null,
            network: "offline",
            uniqueType: sync.uniqueType ?? null,
          });
        }
      }

      state.queues.unshift({
        postData: { ...postData, syncronization_id },
        triesCount: 0,
        error: null,

        syncronization_id,
        queueTime: sync.queueTime,
        endPoint: sync.endPoint,
        syncType: sync.syncType,
        syncAmount: sync.syncAmount,
        syncTitle: sync.syncTitle,
        syncName: sync.syncName,
        uniqueType: sync.uniqueType ?? null,
        requiredData: sync.requiredData,
      });

      // state.syncronization.push({
      //   syncronization_id,
      //   type: sync.syncType,
      //   data_id: sync.data_id ?? null,
      //   uniqueType: sync.uniqueType ?? null,
      // });

      state.totalQueues = state.queues.length;
    },
    recordSyncronization: (state, { payload }) => {
      state.syncronization.push({
        syncronization_id: payload.syncronization_id,
        type: payload.syncType,
        data_id: payload.data_id ?? null,
        network: payload.network ?? null,
        uniqueType: payload.uniqueType ?? null,
      });
    },
    removeNonUniqueType: (state, { payload: y }) => {
      const uniqueType = state.queues.find((x) => x.uniqueType === y.uniqueType);
      if (!uniqueType) return state;

      state.totalQueues = state.queues.length;
      state.queues = state.queues.filter((x) => x.uniqueType !== y.uniqueType);
      state.syncronization = state.syncronization.filter((y) => y.uniqueType !== y.uniqueType);
    },
    removeSyncronization: (state, { payload }) => {
      const newSyncronization = state.queues.filter((q) => q.syncronization_id !== payload.syncronization_id);
      state.queues = newSyncronization;
      state.totalQueues = state.queues.length;
    },
  },
  extraReducers(builder) {
    builder
      // .addCase(addOfflineQueue.type, (state, { payload: y }) => {
      //   console.log("Im being ran");
      //   const uniqueType = state.queues.find((x) => x.uniqueType === y.uniqueType);
      //   if (!uniqueType) return state;

      //   state.totalQueues -= 1;
      //   state.queues = state.queues.filter((x) => x.uniqueType !== y.uniqueType);
      //   state.syncronization = state.syncronization.filter((y) => y.uniqueType !== y.uniqueType);
      // })
      .addCase(logout.type, (state) => {
        state.syncronization = [];
      })
      .addCase(postQueue.fulfilled, (state, { meta }) => {
        state.queues = state.queues.filter((x) => x.syncronization_id !== meta.arg.syncronization_id);
        state.totalQueues = state.queues.length;
      })
      .addCase(postQueue.rejected, (state, action) => {
        const { meta, error } = action;
        state.queues.map((x) => {
          if (x.syncronization_id === meta.arg.syncronization_id) {
            x.triesCount += 1;
            x.queueTime += Math.pow(2, x.triesCount) * 60000;
            x.error = error.message;
          }
        });
      });
  },
});

export const selectTotalQueues = (state) => state.syncronization.totalQueues;
export const selectQueues = (state) => state.syncronization.queues;
export const selectSyncronizations = (state) => state.syncronization.syncronization;
export const selectSyncronizationById = (state, syncronization_id) =>
  state.syncronization.syncronization.find((x) => x.syncronization_id === syncronization_id);

export const { removeSyncronization, addOfflineQueue, recordSyncronization } = syncronizationSlice.actions;
export default syncronizationSlice.reducer;
