import Typography from "@nodes/@material-ui/core/Typography";
import Grid from "@nodes/@material-ui/core/Grid";
import NumberFormat from "@nodes/react-number-format";

const RenderNewLoanCalculation = ({ data }) => {
  return (
    <Grid container>
      <Grid item xs={8} className={data.entrega < 0 ? "text-danger" : ""}>
        <Typography variant="subtitle1" component="p">
          Entregar:
        </Typography>
        <Typography variant="h4" component="h3" className="font-weight-bold">
          <NumberFormat value={data.entrega} displayType={"text"} thousandSeparator={true} prefix={"$"} />
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="subtitle1" component="p">
          Cuota:
        </Typography>
        <Typography variant="h4" component="h3" className="font-weight-bold">
          <NumberFormat value={data.monto} displayType={"text"} thousandSeparator={true} prefix={"$"} />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6" component="h4">
          Ganancia Potencial{" "}
          <NumberFormat value={data.profit} displayType={"text"} thousandSeparator={true} prefix={"$"} />!
        </Typography>
      </Grid>
    </Grid>
  );
};

export default RenderNewLoanCalculation;
