import { init as initialize, setUser, Replay } from "@sentry/browser";
// import LogRocket from "logrocket";
// import setupLogRocketReact from "logrocket-react";
import { BrowserTracing } from "@sentry/tracing";
import { toast } from "react-toastify";
import { localStorageKey, localStorageRememberUser } from "@/config";
import { getLocalToken } from "@shared/functions";

function init() {
  // let logRocketId = null;

  const token = getLocalToken();

  let jsonValue = localStorage.getItem(localStorageRememberUser);
  const deviceObj = jsonValue != null ? JSON.parse(jsonValue) : null;

  initialize({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    release: `cobro-${import.meta.env.VITE_VERSION}-${import.meta.env.VITE_ENV}`,
    token,
    user_id: deviceObj?.user_id || "0000",
    environment: import.meta.env.VITE_ENV,

    tracesSampleRate: 0.2,
    replaysSessionSampleRate: 0.2,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    integrations: [
      new BrowserTracing(),
      new Replay({
        maskAllText: false,
        blockAllMedia: true,
      }),
    ],
  });

  // LogRocket.init(logRocketId, {
  //   release,
  //   environment: import.meta.env.VITE_ENV,
  // });

  // setupLogRocketReact(LogRocket);
}

function identifyUser(user) {
  console.log("Itenfied user as ", user.firstname);
  // LogRocket.identify(user.user_id, {
  //   name: user.firstname + " " + user.lastname,
  // });

  setUser({
    id: user.user_id,
    username: user.username,
    name: user.firstname + " " + user.lastname,
  });
}

function initLogServices() {
  const user = getLocalData(localStorageKey)?.device;
  if (user?.logRocket === "1") {
    init();
    identifyUser(user);
  } else if (user?.logRocket !== "0") {
    init();
  }
}

function getLocalData(key) {
  try {
    const jsonValue = localStorage.getItem(key);
    const data = jsonValue != null ? JSON.parse(jsonValue) : null;

    return data;
  } catch (e) {
    return null;
  }
}

function spanishErrors(error) {
  let $arr = [];
  $arr["Network Error"] = "Error Conexion de Internet!";
  $arr["timeout of 30000ms exceeded"] = "Tiempo de espera de 30 segundos excedido!";
  $arr["timeout of 60000ms exceeded"] = "Tiempo de espera de 60 segundos excedido!";
  $arr["timeout of 90000ms exceeded"] = "Tiempo de espera de 90 segundos excedido!";

  return $arr[error] ? $arr[error] : error;
}

function log(message) {
  console.log(message);
  // sentryLog(message);
}

function error(message) {
  console.error(message);
  toast.error(spanishErrors(message), { hideProgressBar: true, autoClose: 8000 });
  // sentryLog(message);
}

// function sentryLog(message) {
//   if (isProduction) {
//     Sentry.captureException(message);
//   }
// }

const exportDefault = { log, error, initLogServices };
export default exportDefault;
