import emailjs from "@nodes/emailjs-com";

export const SERVICE_ID = "service_6ghnpjp";
export const USER_ID = "user_qJQ03UE1LK02oufDUQbF7";

//Issue-trigger-alert
export async function sendEmail(data) {
  try {
    await emailjs.send(SERVICE_ID, "template_hv45pkw", data, USER_ID);
    return true;
  } catch (err) {
    console.log(err);
  }
}

// user-contact-email
export async function userEmail(data) {
  try {
    await emailjs.send(SERVICE_ID, "template_osf0i1a", data, USER_ID);
    return true;
  } catch (err) {
    console.log(err);
  }
}

//usage
// SendEmail({
//   project_name: "WBO",
//   user_id: state.user.user_id,
//   name: state.user.firstname,
//   message: "message",
// });
