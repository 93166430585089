import { useState } from "react";
import Modal from "@nodes/react-bootstrap/Modal";
import DeleteForeverIcon from "@nodes/@material-ui/icons/DeleteForever";
import Typography from "@nodes/@material-ui/core/Typography";
// import Button from "@nodes/@material-ui/core/Button";
import { printAmount } from "../functions";
import SubmitButton from "./SubmitButton";
import CancelIcon from "@nodes/@material-ui/icons/Cancel";

const DeleteGastoModal = ({ deleteGasto, gasto }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleDeleteGasto = async () => {
    await deleteGasto(gasto);
    handleClose(false);
  };

  return (
    <div>
      <>
        <span className="text-danger" onClick={handleShow}>
          <DeleteForeverIcon />
        </span>

        <Modal show={show} onHide={handleClose} keyboard={false} centered>
          <Modal.Header closeButton>
            <Modal.Title>
              <Typography variant="h5" component="h3" className="text-danger">
                Confirmaci&oacute;n
              </Typography>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Typography variant="h6" component="h4" gutterBottom>
              Decea borrar este gasto?
            </Typography>
            <Typography variant="body2" component="p" paragraph>
              Gasto de {printAmount(gasto.amount)} {gasto.description}
            </Typography>

            {/* <Button size="small" variant="contained" color="secondary" onClick={() => handleDeleteGasto(gasto)}>
            </Button> */}

            <SubmitButton
              autoFetch={false}
              color="secondary"
              text="Borrar Gasto!"
              startIcon={<CancelIcon />}
              callBack={handleDeleteGasto}
            />
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-secondary" onClick={handleClose}>
              Cerrar
            </button>
          </Modal.Footer>
        </Modal>
      </>
    </div>
  );
};

export default DeleteGastoModal;
