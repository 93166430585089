import { createSlice } from "@reduxjs/toolkit";
import { fetchCommonThunk } from "./common";

const initialState = {
  cuadre_id: null,
  newDebtors: [],
};

export const newDebtorsSlice = createSlice({
  name: "newDebtors",
  initialState,
  reducers: {
    // resetNewDebtors: () => initialState,
    createNewDebtor: (state, action) => {
      state.newDebtors.push({
        ...action.payload,
        debtorSearch: action.payload.name,
        postData: undefined,
        syncProps: undefined,
      });
    },
  },
  updateNewDebtor: (state, { payload }) => {
    state.debtors.map((x) => {
      if (x.debtor_id === payload.debtor_id) {
        x.modified_debtor = true;
        x.name = payload.name;
        x.cedula = payload.cedula;
        x.nickname = payload.nickname;
        x.phone = payload.phone;
        x.phone2 = payload.phone2;
        x.address = payload.address;
        x.occupation = payload.occupation;
        x.work_address = payload.work_address;
        x.next_to = payload.next_to;
        x.cosigner = payload.cosigner;
        x.note = payload.note;
      }
    });
  },
  extraReducers(builder) {
    builder.addCase(fetchCommonThunk.fulfilled, (state, { payload }) => {
      if (state.cuadre_id === payload.cuadre_id) return state;
      return { ...initialState, cuadre_id: payload.cuadre_id };
    });
  },
});

export const selectNewDebtors = (state) => state.newDebtors.newDebtors;
export const selectNewDebtorById = (state, dUniqueId) =>
  state.newDebtors.newDebtors.find((x) => +x.dUniqueId === +dUniqueId);

export const { createNewDebtor, updateNewDebtor, deleteNewDebtor } = newDebtorsSlice.actions;
export default newDebtorsSlice.reducer;
