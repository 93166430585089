import React, { useEffect } from "react";
import Box from "@nodes/@material-ui/core/Box";
import Grid from "@nodes/@material-ui/core/Grid";
import { useFormikContext } from "@nodes/formik";
// import Alert from "@nodes/@material-ui/lab/Alert";
// import Grow from "@nodes/@material-ui/core/Grow";
import { Form, RenderInput, RenderCheckboxLabel } from "./Form";
import { loanCreate } from "../functions/validations";
// import { mainApp } from "@/config";

const GetFormikValues = ({ setValues }) => {
  const { values } = useFormikContext();
  useEffect(() => {
    setValues(values);
  }, [values, setValues]);
  return null;
};

const RenderNewLoanForm = ({ setFormValues, initialValues, submithandler, button, children }) => {
  return (
    <div>
      <Form initialValues={initialValues} validation={loanCreate} submithandler={submithandler} button={button}>
        {/* {mainApp === 1 && <Box paddingBottom={3}>{RenderInput("given_date", "Fecha de Entrega", "date")}</Box>}
        {mainApp === 1 && <Box paddingBottom={2}>{RenderInput("start_date", "Fecha de Inicio", "date")}</Box>} */}
        <Box paddingBottom={2}>
          <Grid container>
            <Grid item xs={6}>
              {RenderInput("percentage", "Porciento", "number", false, "standard")}
            </Grid>
            <Grid item xs={6}>
              {RenderInput("npayments", "Numero de Cuotas", "number", false, "standard")}
            </Grid>
          </Grid>
        </Box>
        <Box paddingBottom={2}>{RenderInput("amount", "Cantidad", "number")}</Box>
        <Box paddingBottom={2}>{RenderInput("actanotarial", "Acta Notarial", "number")}</Box>
        <Box paddingBottom={2}>{RenderInput("adelanto", "Pago de Adelanto", "number")}</Box>
        <Box paddingBottom={2}>{RenderInput("discount", "Descuento Adicional", "number")}</Box>

        {+initialValues.paymentMora > 0 && (
          <Box paddingBottom={2}>{RenderInput("paymentMora", "Descontar Mora", "number")}</Box>
        )}

        <Box paddingBottom={0}>{RenderCheckboxLabel("confirmNewLoan", "Confirmar entrega de Prestamo!")}</Box>

        {children}

        <GetFormikValues setValues={setFormValues} />
      </Form>
    </div>
  );
};

export default RenderNewLoanForm;
