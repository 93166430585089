import Card from "@nodes/@material-ui/core/Card";
import Typography from "@nodes/@material-ui/core/Typography";
import Box from "@nodes/@material-ui/core/Box";
import PersonIcon from "@nodes/@material-ui/icons/Person";
// import { Link } from "@nodes/react-router-dom";
import { concatPhoneLink, printAbsAmount, formatPhoneNumber, getBadgeColor } from "../functions";
import PhoneIcon from "@nodes/@material-ui/icons/Phone";
import RenderLoanRating from "./RenderLoanRating";

const XcobrarCreditCheck = ({ loan, handleLoanClick }) => {
  const handleClick = () => {
    handleLoanClick(loan);
  };

  return (
    <Box paddingBottom={1}>
      <Card raised>
        <table className="table table-sm">
          <tbody>
            <tr>
              <td colSpan="3">
                <Typography variant="subtitle2" component="p" className="font-weight-bold">
                  <a href="#!" style={{ color: "#000" }} onClick={handleClick}>
                    <PersonIcon /> {loan.name} {loan.nickname && `(${loan.nickname})`}
                  </a>
                </Typography>
              </td>
            </tr>

            <tr>
              <td colSpan="2">
                <a style={{ color: "#000" }} href={concatPhoneLink(loan.phone)}>
                  <PhoneIcon /> <span>{formatPhoneNumber(loan.phone)}</span>
                </a>
              </td>
              <td align="right">
                <RenderLoanRating rating={loan.rating} />
              </td>
            </tr>

            <tr>
              <td style={{ width: 110 }}>
                {loan.statusText === "Al Dia" ? (
                  <Typography variant="body2" component="p" className="badge badge-pill badge-success">
                    Al Dia
                  </Typography>
                ) : (
                  <Typography variant="body2" component="p" className={getBadgeColor(loan.statusText)}>
                    <small>
                      {loan.statusText} {printAbsAmount(loan.statusAmount)}
                    </small>
                  </Typography>
                )}
              </td>
              <td>
                <Typography variant="body2" component="p">
                  {printAbsAmount(loan.wPayment)}
                  {" - "}
                  {loan.completed}/{loan.npayments}
                </Typography>
              </td>
              <td>
                <Typography variant="body2" component="p">
                  Balance {printAbsAmount(loan.balance)}
                </Typography>
              </td>
            </tr>
          </tbody>
        </table>
      </Card>
    </Box>
  );
};

export default XcobrarCreditCheck;
