import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  passwordReset: {},
};

export const tempStateSlice = createSlice({
  name: "tempState",
  initialState,
  reducers: {
    pwResetLookup: (state, action) => {
      state.passwordReset = action.payload;
    },
  },
});

export const selectUserLookup = (state) => state.tempState.passwordReset;

export const { pwResetLookup } = tempStateSlice.actions;
export default tempStateSlice.reducer;
