import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useParams } from "react-router";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { useDropzone } from "react-dropzone";
import RootRef from "@material-ui/core/RootRef";
import { makeStyles } from "@material-ui/core/styles";
import CropImage from "@shared/components/CropImage";
import Loading from "@shared/components/Loading";
import { selectCommon } from "../store/slices/common";
import SubmitButton from "@shared/components/SubmitButton";
// import CancelIcon from "@material-ui/icons/Cancel";
import { MdSave } from "react-icons/md";
import DebtorNotAvailable from "../components/DebtorNotAvailable";
import { useGetDebtorsQuery } from "../store/slices/api";
import { useSelector } from "react-redux";
// import { createQueue } from "../store/slices/syncronization";
// import { alertSuccess } from "../store/slices/snackbar";
import { getUniqueID } from "@shared/functions";
import { useAlert } from "react-alert";
import { selectNewDebtorById } from "../store/slices/newDebtors";
import usePouchQueues from "../Hooks/usePouchQueues";
import { syncTypes } from "../components/sync/getDataType";
import { pouches_db } from "../config";
import useData from "../Hooks/useData";

const useStyles = makeStyles((theme) => ({
  dropzoneContainer: {
    height: 80,
    width: "100%",
    background: "#efefef",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderStyle: "dashed",
    borderColor: "#aaa",
  },
  preview: {
    width: "95%",
    // height: "70%",
    margin: "auto",
    display: "block",
    marginBottom: theme.spacing(2),
    objectFit: "cover",
  },
}));

function UploadImage() {
  const sync_db = usePouchQueues(pouches_db.SYNC_QUEUES);
  const history_db = usePouchQueues(pouches_db.SYNC_HISTORY);
  const alert = useAlert();
  // const dispatch = useDispatch();
  const history = useHistory();
  const { id: dUniqueId, page } = useParams();
  const classes = useStyles();
  // const [file, setFile] = useState();
  const [base64, setBase64] = useState();
  const [preview, setPreview] = useState();
  const [selectedImageFile, setSelectedImageFile] = useState();

  const newDebtor = useSelector((state) => selectNewDebtorById(state, dUniqueId));
  const { data: currentPouchDebtor, isLoading: d_loading } = useData(pouches_db.NEW_DEBTORS, {
    _id: dUniqueId,
    defaultValue: null,
  });

  const common = useSelector(selectCommon);

  const { data: debtors } = useGetDebtorsQuery({
    creditor_id: common.creditor_id,
    user_id: common.user_id,
    collect_date: common.collect_date,
  });

  const onDrop = (acceptedFiles) => {
    const fileDropped = acceptedFiles[0];
    if (fileDropped["type"].split("/")[0] === "image") {
      setSelectedImageFile(fileDropped);
      return;
    }

    if (!fileDropped) return;

    // setFile(fileDropped);
    const previewUrl = URL.createObjectURL(fileDropped);
    setPreview(previewUrl);
  };

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    onDrop,
  });

  if (!debtors || d_loading) return <Loading />;

  const currentDebtor2 = debtors.find((x) => x.dUniqueId === dUniqueId);

  const currentDebtor = currentDebtor2 ?? newDebtor ?? currentPouchDebtor;

  if (!currentDebtor) return <DebtorNotAvailable />;

  const { ref, ...rootProps } = getRootProps();

  const onCropSave = ({ file, preview, base64 }) => {
    setPreview(preview);
    // setFile(file);
    setBase64(base64);
  };

  const redirectTo = page ? `/debtor/profile/${dUniqueId}` : `/redirect/page/${dUniqueId}/0`;

  const handleAppendFile = async () => {
    const syncronization_id = `R${common.creditor_id}_${common.collect_date}_${getUniqueID()}`;

    const data = {
      syncronization_id,
      debtor_id: currentDebtor.debtor_id,
      cedula: currentDebtor.cedula,
      data: base64,
      timestamp: Date.now(),
      dUniqueId,
      creditor_id: common.creditor_id,
      new_debtor: currentDebtor.new_debtor,
    };

    // const syncProps = {
    //   syncronization_id,
    //   queueTime: Date.now(),
    //   endPoint: "/bgsync/cobro/image/upload",
    //   syncType: "uploadImage",
    //   syncPriority: 7,
    //   syncAmount: "",
    //   syncTitle: "Foto de perfil del cliente",
    //   syncName: `Cliente > ${currentDebtor.name}`,
    // };

    // dispatch(createQueue({ postData: data, syncProps }));

    let operation1 = false;
    try {
      const syncQueueData = {
        _id: syncronization_id,
        syncPriority: 7,
        dependsOn: null,
        syncType: syncTypes.UPLOAD_IMAGE,
        postData: data,
      };

      await sync_db.putSertQueue(syncQueueData);
      operation1 = true;

      const syncHistoryData = {
        _id: syncronization_id,
        data_id: dUniqueId,
        syncType: syncTypes.UPLOAD_IMAGE,
        syncAmount: "",
        syncTitle: "Foto de perfil del cliente",
        syncName: `Cliente > ${currentDebtor.name}`,
      };

      await history_db.putSertHistory(syncHistoryData);

      alert.info("Foto Guardada!");
      history.replace(redirectTo);
    } catch (err) {
      console.error(err);

      // Rollback if any fails
      if (operation1) {
        await sync_db.deleteDoc(syncronization_id);
        console.log("Rolled back the record.");
      }

      alert.error("Error Guardando Foto!!");
    }

    // dispatch(alertSuccess({ duration: 5000, message: "Foto del perfil del cliente guardada con Exito!" }));
  };

  return (
    <>
      <Paper elevation={4}>
        <Grid container>
          <Grid item xs={12}>
            <Typography align="center" style={{ padding: 16 }}>
              Foto de Indentificacion
            </Typography>
            <Typography variant="h6" align="center" gutterBottom>
              {currentDebtor.name}
            </Typography>
            <Divider />
          </Grid>

          <Grid item xs={12} style={{ paddingTop: 10 }}>
            <RootRef rootRef={ref}>
              <Paper {...rootProps} elevation={0} className={classes.dropzoneContainer}>
                <input {...getInputProps()} />
                <Typography variant="h6" className="m-1">
                  Presione aqui para elegir una foto.
                </Typography>
              </Paper>
            </RootRef>
          </Grid>

          <Grid item xs={12} style={{ paddingTop: 10 }}>
            <Typography align="center" variant="h6">
              Vista Previa
            </Typography>
            <img
              id="canvas"
              onLoad={() => URL.revokeObjectURL(preview)}
              className={classes.preview}
              alt="Preview"
              src={preview || "/../../images/placeholder350x150.png"}
            />
          </Grid>
        </Grid>
      </Paper>

      <CropImage onSave={onCropSave} selectedFile={selectedImageFile} />

      <Box marginBottom={3} p={2}>
        {/* <Button
          disabled={preview ? false : true}
          onClick={handleAppendFile}
          fullWidth
          variant="outlined"
          color="primary">
          Guardar Foto!
        </Button> */}

        {preview && (
          <SubmitButton
            autoFetch={false}
            color="primary"
            text="Guardar Foto!"
            startIcon={<MdSave />}
            callBack={handleAppendFile}
          />
        )}
      </Box>

      {!preview && (
        <Box p={2}>
          <Link to={redirectTo}>
            <Button fullWidth color="primary">
              Continuar sin Foto!
            </Button>
          </Link>
        </Box>
      )}
    </>
  );
}

export default UploadImage;
