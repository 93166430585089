import React, { useState } from "react";
import Button from "@nodes/@material-ui/core/Button";
import Menu from "@nodes/@material-ui/core/Menu";
import MenuItem from "@nodes/@material-ui/core/MenuItem";
import Divider from "@nodes/@material-ui/core/Divider";
import WhatsAppIcon from "@nodes/@material-ui/icons/WhatsApp";
import PrintIcon from "@nodes/@material-ui/icons/Print";
import { printReceiptHandler } from "../functions/print";
import MoreVertIcon from "@nodes/@material-ui/icons/MoreVert";
import DeleteForeverIcon from "@nodes/@material-ui/icons/DeleteForever";
import VisibilityIcon from "@nodes/@material-ui/icons/Visibility";
// import { FaReceipt } from "@nodes/react-icons/fa";
import { mainApp } from "@/config";

export default function HistoryPaymentMenu({ history, route, payment, configs }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePrintReceipt = () => {
    printReceiptHandler(payment, { ...route, today_date: payment.payment_date, ...configs });
    handleClose();
  };

  const handleWhatsapp = () => {
    window.location = `whatsapp://send?text=${printReceiptHandler(payment, {
      ...route,
      today_date: payment.payment_date,
      ...configs,
      printApp: "whatsapp",
    })}`;
    handleClose();
  };

  return (
    <div>
      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        <MoreVertIcon fontSize="small" />
      </Button>
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem
          onClick={() =>
            history.push(`/payment/details/${payment.payment_id}/${payment.money_id}/${payment.debtor_id}`)
          }>
          <VisibilityIcon className="pr-1" /> Ver Detalles
        </MenuItem>
        <Divider />

        {payment.is_active === "1" && (
          <span>
            <MenuItem onClick={handlePrintReceipt} className="text-info">
              <PrintIcon fontSize="small" /> Imprimir Recibo
            </MenuItem>
            <Divider />

            <MenuItem onClick={handleWhatsapp} className="text-success">
              <WhatsAppIcon fontSize="small" /> Enviar Whatsapp
            </MenuItem>
            <Divider />
          </span>
        )}

        {mainApp === 1 && payment.is_active === "1" && (
          <span>
            <MenuItem
              className="text-danger"
              onClick={() =>
                history.push(`/delete/payment/${payment.payment_id}/${payment.money_id}/${payment.debtor_id}`)
              }>
              <DeleteForeverIcon /> Borrar Pago
            </MenuItem>
          </span>
        )}
      </Menu>
    </div>
  );
}
