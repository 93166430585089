import { useParams } from "@nodes/react-router";
import Box from "@nodes/@material-ui/core/Box";
import Grid from "@nodes/@material-ui/core/Grid";
import Typography from "@nodes/@material-ui/core/Typography";
import Container from "@nodes/@material-ui/core/Container";
import DebtorNameHeader from "@shared/components/DebtorNameHeader";
import RenderLoanPayments from "@shared/components/RenderLoanPayments";
// import RenderLoanPaymentsDR from "@shared/components/RenderLoanPaymentsDR";
import DisplayLoanStatus from "@shared/components/DisplayLoanStatus";
import LoanDetailMenu from "@shared/components/LoanDetailMenu";
import Loading from "@shared/components/Loading";
import NoInternetContent from "@shared/components/NoInternetContent";
import useNetwork from "@shared/hooks/useNetwork";
import Alert from "@nodes/@material-ui/lab/Alert";
// import NetworkDetector from "../HOC/NetworkDetector";
import { UseRoute, UseLoan, UseLoanPayments, UseUserConfig, UseOwnerConfig } from "@shared/server/fetcher";
import { printAmount, newDate, printDate } from "@shared/functions";
import { useSelector } from "react-redux";
import { selectCommon } from "../store/slices/common";

const LoanPayments = ({ history }) => {
  const isOnline = useNetwork();
  const { id: money_id, id2: debtor_id } = useParams();

  const common = useSelector(selectCommon);

  const { data: route, isLoading: routeLoading } = UseRoute(common.creditor_id, common.user_id);
  const { data: currentLoan, isLoading: isLoadingLoan } = UseLoan(
    money_id,
    debtor_id,
    common.creditor_id,
    common.user_id
  );

  const { data: userConfig, isLoading: userConfigLoading } = UseUserConfig(common.user_id);
  const { data: routeConfig, isLoading: routeLoadingConfig } = UseOwnerConfig(common.owner_id);

  const { data: payments, isLoading: isLoadingPayments } = UseLoanPayments(money_id, debtor_id);

  if (!payments && !isOnline) return <NoInternetContent />;
  if (isLoadingLoan || routeLoading) return <Loading />;

  return (
    <div className="mt-3 mb-5">
      <Container maxWidth="md" disableGutters>
        <DebtorNameHeader debtor={currentLoan}>
          <LoanDetailMenu history={history} loan={currentLoan} />
        </DebtorNameHeader>

        <Alert variant="outlined" severity="warning">
          <Typography variant="h6" component="p" gutterBottom>
            Aviso!!!
          </Typography>

          <Typography variant="body1" component="p" paragraph>
            Los recibos impresos de esta pagina posiblemente no esten 100% correcto.
          </Typography>

          <Typography variant="body1" component="p" paragraph>
            Por favor, Verifique el recibo antes de entregarlo y reporteme cualquier error.
          </Typography>
        </Alert>

        {/* {currentLoan.balance > 0 && ( */}
        <Box component="div" p={1}>
          <DisplayLoanStatus loan={currentLoan} />
        </Box>
        {/* )} */}

        {isLoadingPayments || userConfigLoading || routeLoadingConfig ? (
          <Loading />
        ) : (
          <Box component="div" p={1}>
            <Grid container className="mb-1">
              <Grid item xs={6}>
                <Box component="div" p={1}>
                  <Typography variant="body2" component="p">
                    Entregado
                  </Typography>

                  <Typography variant="h6" component="h4">
                    <span className="badge badge-dark p-2">{printDate(newDate(currentLoan.given_date))}</span>
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={6}>
                <Box component="div" p={1}>
                  <Typography variant="body2" component="p">
                    Monto
                  </Typography>
                  <Typography variant="h6" component="h4">
                    <span className="badge badge-dark p-2">{printAmount(currentLoan.wPayment)}</span>
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            <RenderLoanPayments
              data={{ route, loan: currentLoan, configs: { ...routeConfig, ...userConfig } }}
              history={history}
              payments={payments}
            />

            {/* <RenderLoanPaymentsDR
              data={{ route, loan: currentLoan, configs: { ...routeConfig, ...userConfig } }}
              history={history}
              payments={payments}
            /> */}
          </Box>
        )}
      </Container>
    </div>
  );
};

// export default NetworkDetector(LoanPayments);
export default LoanPayments;
