import { useState } from "react";
import { ReactCalculator } from "@nodes/simple-react-calculator";
import Dialog from "@nodes/@material-ui/core/Dialog";
import DialogActions from "@nodes/@material-ui/core/DialogActions";
import Button from "@nodes/@material-ui/core/Button";
import Container from "@nodes/@material-ui/core/Container";
import ClearIcon from "@nodes/@material-ui/icons/Clear";

const Calculator = ({ children }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <span onClick={() => setShowModal(!showModal)}>{children}</span>

      <Dialog open={showModal} onClose={() => setShowModal(!showModal)} fullScreen={true}>
        <Container maxWidth="md" disableGutters>
          <ReactCalculator />

          <DialogActions>
            <Button startIcon={<ClearIcon />} onClick={() => setShowModal(!showModal)} color="secondary">
              Cerrar
            </Button>
          </DialogActions>
        </Container>
        <br />
      </Dialog>
    </>
  );
};

export default Calculator;
