import { useEffect } from "@nodes/react";
import { useParams } from "@nodes/react-router-dom";
import Loading from "@shared/components/Loading";
import { useSelector } from "react-redux";
import { selectCommon } from "../store/slices/common";
import { useGetOwnerConfigQuery } from "../store/slices/api";
import { useHistory } from "@nodes/react-router-dom";
import { replaceNonDigits } from "@shared/functions";

const RedirectPage = () => {
  const history = useHistory();
  const { ced: cedula, dUn: dUniqueId, mId: money_id } = useParams();
  const common = useSelector(selectCommon);

  const { data: ownerConfig } = useGetOwnerConfigQuery(common.owner_id);

  useEffect(() => {
    if (!ownerConfig) return;
    let url = null;
    if (ownerConfig.autoDepurar === "1" && cedula && replaceNonDigits(cedula) > 0) {
      if (+money_id > 0) {
        url = `/debtor/loans/check/${dUniqueId}/${cedula}/${money_id}`;
      } else {
        url = `/debtor/loans/check/${dUniqueId}/${cedula}`;
      }
    } else {
      if (+money_id > 0) {
        url = `/loan/renewal/${dUniqueId}/${money_id}`;
      } else {
        if (dUniqueId === "newdebtor") {
          url = `/debtor/create/${cedula}`;
        } else {
          url = `/loan/create/${dUniqueId}`;
        }
      }
    }

    if (url) history.replace(url);
  }, [ownerConfig]);

  return <Loading />;
};

export default RedirectPage;
