import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { persistReducer, persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";
// import storage from "redux-persist/lib/storage";
// import localforage from "localforage";
import storage from "redux-persist-indexeddb-storage";
// import { createStateSyncMiddleware, initMessageListener } from "redux-state-sync";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import loans from "./slices/loans";
import gastos from "./slices/gastos";
import payments from "./slices/payments";
import newLoans from "./slices/newLoans";
import newDebtors from "./slices/newDebtors";
import syncronization from "./slices/syncronization";
import miscData from "./slices/miscData";
import common from "./slices/common";
import auth from "./slices/auth";
import ui from "./slices/ui";
import tempState from "./slices/tempState";
import api from "./slices/api";
// import { createOffline } from "@redux-offline/redux-offline";
// import offlineConfig from "@redux-offline/redux-offline/lib/defaults/index";
import { localStorageReduxPersist } from "@/config";
// import pushServer from "./midddleware/pushServer";
// import syncronize from "./midddleware/syncronize";

const persistVersion = 10;
const persistConfig = {
  key: localStorageReduxPersist,
  stateReconciler: autoMergeLevel2,
  version: persistVersion,
  // storage: localforage,
  storage: storage("wprestamo"), //Changing this value will logout all users receiving the update.
  blacklist: ["tempState"],
};

// const {
//   middleware: offlineMiddleware,
//   enhanceReducer: offlineEnhanceReducer,
//   enhanceStore: offlineEnhanceStore,
// } = createOffline({
//   ...offlineConfig,
//   persist: false,
// });

const reducers = combineReducers({
  [api.reducerPath]: api.reducer,
  ui,
  payments,
  newLoans,
  newDebtors,
  loans,
  gastos,
  tempState,
  syncronization,
  miscData,
  common,
  auth,
});

// const persistedReducer = persistReducer(persistConfig, offlineEnhanceReducer(reducers));
const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: { warnAfter: 512 },
      serializableCheck: {
        warnAfter: 512,
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(
      api.middleware
      // createStateSyncMiddleware({
      //   blacklist: [
      //     FLUSH,
      //     REHYDRATE,
      //     PAUSE,
      //     PERSIST,
      //     PURGE,
      //     REGISTER,
      //     "api/executeMutation/pending",
      //     "api/queries/queryResultPatched",
      //     "api/executeMutation/fulfilled",
      //     "api/internalSubscriptions/subscriptionsUpdated",
      //     "Offline/STATUS_CHANGED",
      //   ],
      // }),
      // offlineMiddleware
    ), //, syncronize, pushServer
  // enhancers: (defaultEnhancers) => defaultEnhancers.prepend(offlineEnhanceStore),
});

// meta: {
//   offline: {
//     // the network action to execute:
//     effect: { url: syncProps.endPoint, method: "POST", json: { ...data2, ...data } },
//     // action to dispatch when effect succeeds:
//     commit: { type: "FOLLOW_USER_COMMIT", meta: { ...syncProps, ...data2, ...data } },
//     // action to dispatch if network action fails permanently:
//     rollback: { type: "FOLLOW_USER_ROLLBACK", meta: { ...syncProps, ...data2, ...data } },
//   },

// initMessageListener(store);
setupListeners(store.dispatch);

// store.subscribe(() => {
//   console.log("store changed");
// });

export const persistedStore = persistStore(store);
export default store;
