import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import CardContent from "@material-ui/core/CardContent";
import { useSelector } from "react-redux";
import { selectAuth } from "../store/slices/auth";
import { Redirect, useHistory } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { useAuth } from "../Hooks/useAuth";

const Index = () => {
  const history = useHistory();
  const auth = useSelector(selectAuth);
  const { data } = useAuth();

  if (data.jwt !== null || auth.jwt) return <Redirect to="/dashboard" />;

  return (
    <Card className="mt-5 mb-5" variant="outlined">
      <CardContent>
        <Typography className="mt-3" variant="h5" gutterBottom>
          Aplicacion de Cobro!
        </Typography>

        <Typography className="mt-3" variant="body1" paragraph>
          Todo los datos colectados durante el uso de esta aplicacion permancen guardados unicamente en su telefono, por
          esa razon la app puede trabajar sin internet.
        </Typography>

        <Typography className="mt-3" variant="body1" paragraph>
          La aplicacion puede trabajar sin internet, pero es recomendable conectarse al internet lo mas posible para que
          la aplicacion pueda guardar los datos en vivo.
        </Typography>

        {/* <Typography className="mt-3" variant="body1" paragraph>
          No me hago responsable por datos perdido por no cerrar Cuadre al terminar de Cobrar.
        </Typography> */}

        <Button className="mt-2" fullWidth variant="outlined" color="primary" onClick={() => history.push("/login")}>
          Iniciar Sesion
        </Button>
      </CardContent>
    </Card>
  );
};

export default Index;
