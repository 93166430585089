import { useEffect, useState } from "react";
import PouchDB from "pouchdb-browser";

const usePouchSync = (localDb, remoteDb = null, common = null, options = {}) => {
  const [syncState, setSyncState] = useState({
    syncActive: false,
    lastError: null,
    replicationStatus: null,
  });

  useEffect(() => {
    if (!remoteDb || !common) return;

    const localUrl = "http://wilson382:browning-dunes-PATSY-chanel-instead@127.0.0.1:5984";
    const remoteUrl = "https://admin:rf451f4e14e165dcedce2po@server.wprestamos.com:6984";
    const repUrl = import.meta.env.VITE_ENV === "local" ? localUrl : remoteUrl;

    const repHandler = PouchDB.sync(
      localDb,
      `${repUrl}/c${common.cuadre_id}-w${common.owner_id}-${common.collect_date}-${remoteDb}`,
      {
        live: true,
        retry: true,
        ...options,
      }
    );

    // Update sync state
    setSyncState((prev) => ({ ...prev, syncActive: true }));

    // Event listeners
    repHandler
      // .on("change", (info) => {
      //   setSyncState((prev) => ({
      //     ...prev,
      //     replicationStatus: `Change detected: ${JSON.stringify(info)}`,
      //   }));
      // })
      .on("paused", (err) => {
        setSyncState((prev) => ({
          ...prev,
          replicationStatus: "Replication paused.",
          lastError: err || null,
        }));
      })
      .on("active", () => {
        setSyncState((prev) => ({
          ...prev,
          replicationStatus: "Replication active.",
        }));
      })
      .on("error", (err) => {
        console.error("Replication error:", err);
        setSyncState((prev) => ({
          ...prev,
          syncActive: false,
          lastError: err,
        }));
      });

    // Cleanup on unmount
    return () => {
      repHandler.cancel();
      setSyncState((prev) => ({
        ...prev,
        syncActive: false,
        replicationStatus: "Replication stopped.",
      }));
    };
  }, [localDb, remoteDb, common]);

  return syncState;
};

export default usePouchSync;
