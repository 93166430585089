import NewLoansCard from "@shared/components/NewLoansCard";
import NewLoansCard_W2593 from "@shared/components/NewLoansCard_W2593";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { printAmount } from "@shared/functions";
import BottomNavigation from "../components/BottomNavigation";
import Loading from "@shared/components/Loading";
import { useSelector } from "react-redux";
import { selectNewLoans, selectTotalNewLoans } from "../store/slices/newLoans";
import { selectCommon } from "../store/slices/common";
import { useGetOwnerConfigQuery, useGetRouteQuery, useGetUserConfigQuery, useGetUserQuery } from "../store/slices/api";
import useData from "../Hooks/useData";
import { pouches_db } from "../config";

const NewLoans = () => {
  const common = useSelector(selectCommon);

  const newLoans = useSelector(selectNewLoans);
  const newLoansTotal = useSelector(selectTotalNewLoans);

  // [ ] Published on December 13.
  // [ ] After exaclty 2 weeks re enable these codes.
  const newLoans2 = [];
  const l_loading = false;

  // const { data: newLoans2, isLoading: l_loading } = useData(pouches_db.NEW_LOANS);

  const { data: route } = useGetRouteQuery({
    creditor_id: common.creditor_id,
    user_id: common.user_id,
    collect_date: common.collect_date,
  });
  const { data: user } = useGetUserQuery(common.user_id);
  const { data: userConfig } = useGetUserConfigQuery(common.user_id);
  const { data: ownerConfig } = useGetOwnerConfigQuery(common.owner_id);

  if (l_loading) return <Loading />;

  return (
    <>
      {newLoans2.length + newLoansTotal.count === 0 ? (
        <div className="container mt-5 mb-5">
          <Typography variant="h5" componet="h3" gutterBottom>
            Entrega de Prestamos!
          </Typography>
          <Typography variant="body1" componet="p" paragraph>
            En esta pagina puede ver todo los nuevos prestamos registrado.
          </Typography>
          <Typography variant="body1" componet="p" paragraph>
            Por el momento, no se ha registrado ningun renovo o nuevo prestamo!
          </Typography>
        </div>
      ) : (
        <div className="mt-3 mb-5">
          <Box component="div" p={1}>
            <Typography variant="h6" componet="h4">
              {/* Added so that collector can know for sure how much they given */}
              {/* {user.permissions?.perm_efectivo ? ( */}
              {![2593, 2103].includes(+common.owner_id) ? (
                <span>
                  Entrega de Prestamos ({newLoansTotal.count}): {printAmount(newLoansTotal.entregas)}
                </span>
              ) : (
                <span>Entrega de Prestamos ({newLoansTotal.count})</span>
              )}
            </Typography>
          </Box>

          <Grid container>
            {[2593, 2103].includes(+common.owner_id) ? (
              <>
                {[...newLoans2, ...newLoans].map((loan) => (
                  <Grid key={loan.mUniqueId} item xs={12}>
                    <NewLoansCard_W2593
                      loan={loan}
                      route={{ ...route, ...common }}
                      user={user}
                      userConfig={{ ...userConfig, ...ownerConfig }}
                    />
                  </Grid>
                ))}
              </>
            ) : (
              <>
                {[...newLoans2, ...newLoans].map((loan) => (
                  <Grid key={loan.mUniqueId} item xs={12}>
                    <NewLoansCard
                      loan={loan}
                      route={{ ...route, ...common }}
                      user={user}
                      userConfig={{ ...userConfig, ...ownerConfig }}
                    />
                  </Grid>
                ))}
              </>
            )}
          </Grid>
        </div>
      )}

      <BottomNavigation />
    </>
  );
};

export default NewLoans;
