import Box from "@nodes/@material-ui/core/Box";
import Button from "@nodes/@material-ui/core/Button";
import { FaRegMoneyBillAlt, FaHandHoldingUsd } from "@nodes/react-icons/fa";
import { AiOutlineFileSync } from "@nodes/react-icons/ai";
import { makeStyles } from "@nodes/@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    color: "orange",
  },
});

const RenderLoanDetailButtons = ({ history, loan }) => {
  const classes = useStyles();

  return (
    <div>
      <Box paddingTop={3}>
        <Button
          fullWidth
          startIcon={<FaRegMoneyBillAlt size="1.2em" />}
          variant="outlined"
          color="primary"
          onClick={() => history.push(`/payment/create/${loan.money_id}/${loan.debtor_id}`)}>
          Aplicar Pago
        </Button>
      </Box>

      <Box paddingTop={3}>
        <Button
          fullWidth
          disabled={loan.is_active === "0" || loan.balance === 0 ? true : false}
          classes={{ root: classes.root }}
          startIcon={<AiOutlineFileSync size="1.2em" />}
          variant="outlined"
          color="default"
          onClick={() => history.push(`/redirect/page/${loan.dUniqueId}/${loan.money_id}/${loan.cedula}`)}>
          Renovar Prestamo
        </Button>
      </Box>

      <Box paddingTop={3}>
        <Button
          fullWidth
          startIcon={<FaHandHoldingUsd size="1.2em" />}
          variant="outlined"
          color="default"
          onClick={() => history.push(`/redirect/page/${loan.dUniqueId}/0/${loan.cedula}`)}>
          Nuevo Prestamo
        </Button>
      </Box>
    </div>
  );
};

export default RenderLoanDetailButtons;
