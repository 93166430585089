import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { alertError } from "../slices/snackbar";
import { createQueue } from "../slices/syncronization";
import { REHYDRATE } from "redux-persist";

export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({ baseUrl: import.meta.env.VITE_API_URL }),
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === REHYDRATE) {
      return action.payload?.[reducerPath];
    }
  },
  tagTypes: ["debtorsTag", "ownerConfigTag", "userTag", "routeTag", "userConfigTag", "permissionsTag"],
  keepUnusedDataFor: 86400 * 6,
  // refetchOnMountOrArgChange: true, //| false | number seconds
  // refetchOnFocus: true,
  // refetchOnReconnect: true,
  endpoints: (builder) => ({
    getDebtors: builder.query({
      query: ({ creditor_id, user_id }) => `/debtors/${creditor_id}/${user_id}`,
      providesTags: ["debtorsTag"],
    }),
    getOwnerConfig: builder.query({
      query: (owner_id) => `/owner/configv2/${owner_id}`,
      keepUnusedDataFor: 86400 * 30,
      providesTags: ["ownerConfigTag"],
    }),
    getRoute: builder.query({
      query: ({ creditor_id, user_id }) => `/route/info/${creditor_id}/${user_id}`,
      providesTags: ["routeTag"],
    }),
    getUser: builder.query({
      query: (user_id) => `/user/${user_id}`,
      keepUnusedDataFor: 86400 * 30,
      providesTags: ["userTag"],
    }),
    getUserConfig: builder.query({
      query: (owner_id) => `/user/configv2/${owner_id}`,
      keepUnusedDataFor: 86400 * 90,
      providesTags: ["userConfigTag"],
    }),
    getPermissions: builder.query({
      query: ({ creditor_id, user_id }) => `/user/route/permissions/${user_id}/${creditor_id}`,
      keepUnusedDataFor: 86400 * 90,
      providesTags: ["permissionsTag"],
    }),
    createDebtor: builder.mutation({
      query: (initialPost) => ({
        url: "/bgsync/cobro/debtor/create",
        method: "post",
        body: initialPost.postData,
      }),
      async onQueryStarted(data, { dispatch, queryFulfilled }) {
        const { postData, syncProps } = data;
        const arrgs = {
          creditor_id: postData.creditor_id,
          user_id: postData.user_id,
          collect_date: postData.collect_date,
        };

        dispatch(
          api.util.updateQueryData("getDebtors", arrgs, (draft) => {
            draft?.push(postData);
          })
        );
        try {
          await queryFulfilled;
        } catch (err) {
          console.log("error!", err);
          dispatch(createQueue({ postData, syncProps }));
        }
      },
    }),
    updateDebtor: builder.mutation({
      query: (initialPost) => ({
        url: "/bgsync/cobro/debtor/update",
        method: "post",
        body: initialPost.postData,
      }),
      async onQueryStarted(data, { dispatch, queryFulfilled }) {
        const { postData, syncProps } = data;
        const arrgs = {
          creditor_id: postData.creditor_id,
          user_id: postData.user_id,
          collect_date: postData.collect_date,
        };

        dispatch(
          api.util.updateQueryData("getDebtors", arrgs, (draft) => {
            let debtor = draft?.find((x) => x.dUniqueId === postData.dUniqueId);
            if (debtor) {
              debtor.name = postData.name;
              debtor.cedula = postData.cedula;
              debtor.phone = postData.phone;
              debtor.phone2 = postData.phone2;
              debtor.nickname = postData.nickname;
              debtor.address = postData.address;
              debtor.work_address = postData.work_address;
              debtor.next_to = postData.next_to;
              debtor.cosigner = postData.cosigner;
              debtor.occupation = postData.occupation;
              debtor.note = postData.note;
            }
          })
        );

        try {
          await queryFulfilled;
        } catch (err) {
          console.log("error!", err);
          dispatch(createQueue({ postData, syncProps }));
        }
      },
    }),
    updateUserConfig: builder.mutation({
      query: (initialPost) => ({
        url: "/user/configv2",
        method: "put",
        body: initialPost,
      }),
      async onQueryStarted(data, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          api.util.updateQueryData("getUserConfig", data.user_id, (draft) => {
            return { ...draft, printApp: data.printApp };
          })
        );

        try {
          await queryFulfilled;
        } catch {
          dispatch(alertError({ duration: 5000, message: "Error actualizando configuracion!" }));
          patchResult.undo();
        }
      },
    }),
    updateOwnerConfig: builder.mutation({
      query: (initialPost) => ({
        url: "/owner/configv2",
        method: "put",
        body: initialPost,
      }),
      async onQueryStarted(data, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          api.util.updateQueryData("getOwnerConfig", data.owner_id, (draft) => {
            return { ...draft, ...data };
          })
        );
        try {
          await queryFulfilled;
        } catch {
          dispatch(alertError({ duration: 5000, message: "Error actualizando configuracion!" }));
          patchResult.undo();
        }
      },
    }),
  }),
});

export const {
  useGetDebtorsQuery,
  useGetUserQuery,
  useGetRouteQuery,
  useGetUserConfigQuery,
  useGetOwnerConfigQuery,
  useGetPermissionsQuery,
  useUpdateDebtorMutation,
  useCreateDebtorMutation,
  useUpdateUserConfigMutation,
  useUpdateOwnerConfigMutation,
} = api;
export default api;
