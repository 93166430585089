import Typography from "@nodes/@material-ui/core/Typography";
import Alert from "@nodes/@material-ui/lab/Alert";
import Card from "@nodes/@material-ui/core/Card";
import CardContent from "@nodes/@material-ui/core/CardContent";
import { BsFlag } from "@nodes/react-icons/bs";

const NoLoanFound = () => {
  return (
    <div className="mt-2 mb-5">
      <Card raised>
        <CardContent>
          <div className="container mt-5 mb-5">
            <div className="mt-5 mb-3 text-center">
              <BsFlag className="text-danger" size="13em" />
            </div>
            <Alert variant="outlined" severity="error">
              <Typography variant="h6" component="p" gutterBottom className="font-weight-bold">
                No Prestamo!
              </Typography>
              <Typography variant="body1" component="p" paragraph>
                El prestamo que haz selecionado no esta disponible
              </Typography>
            </Alert>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default NoLoanFound;
