import React, { useState } from "react";
import Menu from "@nodes/@material-ui/core/Menu";
import MenuItem from "@nodes/@material-ui/core/MenuItem";
import MoreVertIcon from "@nodes/@material-ui/icons/MoreVert";
import Divider from "@nodes/@material-ui/core/Divider";
import IconButton from "@nodes/@material-ui/core/IconButton";
import PersonIcon from "@nodes/@material-ui/icons/Person";
import PrintIcon from "@nodes/@material-ui/icons/Print";
import CancelIcon from "@nodes/@material-ui/icons/Cancel";
import { printEntregaHandler, printLoanContractHandler, printReceiptHandler } from "../functions/print";
import VisibilityIcon from "@nodes/@material-ui/icons/Visibility";
import WhatsAppIcon from "@nodes/@material-ui/icons/WhatsApp";
import { BiEdit } from "@nodes/react-icons/bi";
import { mainApp } from "@/config";
import { TbHandFinger } from "@nodes/react-icons/tb";
import { useHistory } from "@nodes/react-router-dom";

export default function NewLoansMenu({ loan, route, user, userConfig }) {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePrintReceipt = () => {
    printEntregaHandler(loan, route, { ...user, ...userConfig });
    handleClose();
  };

  const handleAdelantoPaymentPrint = () => {
    const paymentAmount = loan.adelanto;
    const balance = +loan.npayments * +loan.wPayment - paymentAmount;
    const completed = loan.npayments - Math.ceil(balance / loan.wPayment);
    printReceiptHandler(
      { ...loan, paymentAmount, completed, balance, paymentMora: 0, adelantoPayment: true },
      { ...route, ...user, ...userConfig }
    );
    handleClose();
  };

  const handlePrintContract = () => {
    printLoanContractHandler(loan, route, { ...user, firstname: user.fname, ...userConfig });
    handleClose();
  };

  const handleWhatsapp = () => {
    window.location = `whatsapp://send?text=${printEntregaHandler(loan, route, {
      ...user,
      ...userConfig,
      printApp: "whatsapp",
    })}`;
    handleClose();
  };

  return (
    <div>
      <IconButton aria-label="settings" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>

      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {mainApp === 1 && (
          <span>
            <MenuItem
              className="text-primary"
              onClick={() => history.push(`/loan/detail/${loan.money_id}/${loan.debtor_id}`)}>
              <VisibilityIcon className="pr-1" />
              Prestamo Detalle
            </MenuItem>
            <Divider />
          </span>
        )}

        {mainApp === 0 && (
          <span>
            <MenuItem className="text-primary" onClick={() => history.push(`/loan/signature/${loan.money_id}`)}>
              <TbHandFinger className="pr-1" size="1.5em" /> Actualizar Firma
            </MenuItem>
            <Divider />
          </span>
        )}

        {mainApp === 0 && (
          <span>
            <MenuItem className="text-warning" onClick={() => history.push("/newloan/modify/" + loan.money_id)}>
              <BiEdit className="pr-1" size="1.4em" /> Modificar Entrega
            </MenuItem>
            <Divider />
          </span>
        )}

        {+loan.adelanto > 0 && (
          <span>
            <MenuItem onClick={handleAdelantoPaymentPrint} className="text-info">
              <PrintIcon className="pr-1" /> Imprimir Adelanto
            </MenuItem>
            <Divider />
          </span>
        )}

        <MenuItem onClick={handlePrintReceipt} className="text-info">
          <PrintIcon className="pr-1" />
          Imprimir Detalle
        </MenuItem>
        <Divider />

        <MenuItem onClick={handlePrintContract} className="text-info">
          <PrintIcon className="pr-1" />
          Imprimir Contrato
        </MenuItem>
        <Divider />

        <MenuItem onClick={handleWhatsapp} className="text-success">
          <WhatsAppIcon className="pr-1" fontSize="small" /> Enviar Whatsapp
        </MenuItem>
        <Divider />

        <MenuItem onClick={() => history.push(`/debtor/profile/${loan.dUniqueId}`)}>
          <PersonIcon className="pr-1" />
          Perfil de Cliente
        </MenuItem>
        <Divider />

        {mainApp === 0 && (
          <span>
            <MenuItem className="text-danger" onClick={() => history.push("/delete/newloan/" + loan.money_id)}>
              <CancelIcon className="pr-1" /> Cancelar Entrega
            </MenuItem>
          </span>
        )}
      </Menu>
    </div>
  );
}
