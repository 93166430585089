import useSWR from "@nodes/swr";
import backend from "@shared/server/backend";
import axios from "@nodes/axios";

// https://mtsknn.fi/blog/swr-show-stale-data-on-revalidation-error/

const defaultOptions = {
  dedupingInterval: 10 * 1000,
  focusThrottleInterval: 10 * 1000,
  errorRetryCount: 3,
  refreshInterval: 15 * 600000,
};

const defaultCobroOptions = {
  ...defaultOptions,
  dedupingInterval: 30 * 1000,
  focusThrottleInterval: 60 * 1000,
  errorRetryCount: 5,
  revalidateIfStale: true,
  revalidateOnFocus: true,
  revalidateOnReconnect: true,
};

const fetcher = async (url) => {
  try {
    const { data } = await backend.get(url);
    return data;
  } catch (err) {
    console.log(`Network Error, url ${url}`, err.message);
  }
};

const fetcher2 = async (url) => {
  const newUrl = import.meta.env.VITE_API_URL + url;
  try {
    const { data } = await axios.get(newUrl);
    return data;
  } catch (err) {
    console.log(`Network Error, url ${url}`, err.message);
  }
};

export const UseUser = (user_id) => {
  const endPoint = user_id ? `/user/${user_id}` : null;

  const { data, error, mutate } = useSWR(endPoint, fetcher, {
    ...defaultOptions,
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  return { data, isLoading: !data, failed: !data && !!error, error, mutate, key: endPoint };
};

export const UseUserPasswordReset = (user_id, salt, time) => {
  const endPoint = user_id && salt && time ? `/verify/password/reset/${user_id}/${salt}/${time}` : null;

  const { data, error, mutate } = useSWR(endPoint, fetcher, {
    ...defaultOptions,
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  return { data, isLoading: !data, failed: !data && !!error, error, mutate, key: endPoint };
};

export const UseSecurityQuestions = () => {
  const endPoint = "/questions";

  const { data, error, mutate } = useSWR(endPoint, fetcher, {
    ...defaultOptions,
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  return { data, isLoading: !data, failed: !data && !!error, error, mutate, key: endPoint };
};

export const UseBroadcastMessage = (env) => {
  const endPoint = `/broadcast/message/${env}`;

  const { data, error, mutate } = useSWR(endPoint, fetcher2, { dedupingInterval: 20 * 60000 });

  return { data, isLoading: !data, failed: !data && !!error, error, mutate, key: endPoint };
};

export const UseUserConfig = (user_id) => {
  const endPoint = user_id ? `/user/configv2/${user_id}` : null;

  const { data, error, mutate } = useSWR(endPoint, fetcher, { ...defaultOptions });

  return { data, isLoading: !data, failed: !data && !!error, error, mutate, key: endPoint };
};

export const UseCedulasNames = (prefix) => {
  const endPoint = prefix ? `/cedula/names/${prefix}` : null;

  const { data, error, mutate } = useSWR(endPoint, fetcher2, {
    ...defaultOptions,
    dedupingInterval: 30000,
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  return { data, isLoading: !data, failed: !data && !!error, error, mutate, key: endPoint };
};

export const UseUserLinkedRoutes = (user_id) => {
  const endPoint = user_id ? `/user/linked/routes/${user_id}` : null;

  const { data, error, mutate } = useSWR(endPoint, fetcher, { ...defaultOptions });

  return { data, isLoading: !data, failed: !data && !!error, error, mutate, key: endPoint };
};

export const UseUserLinkedRoutesData = (user_id) => {
  const endPoint = user_id ? `/cobro/data/routes/${user_id}` : null;

  const { data, error, mutate } = useSWR(endPoint, fetcher, { ...defaultOptions });

  return { data, isLoading: !data, failed: !data && !!error, error, mutate, key: endPoint };
};

export const UseDebtor = (debtor_id, user_id) => {
  const endPoint = debtor_id && user_id ? `/debtor/${debtor_id}/${user_id}` : null;

  const { data, error, mutate } = useSWR(endPoint, fetcher, { ...defaultOptions });

  return { data, isLoading: !data, failed: !data && !!error, error, mutate, key: endPoint };
};

export const UseDebtorOffline = (dUniqueId, user_id) => {
  const endPoint = dUniqueId && user_id ? `/debtor/offline/${dUniqueId}/${user_id}` : null;

  const { data, error, mutate } = useSWR(endPoint, fetcher2, { ...defaultOptions });

  return { data, isLoading: !data, failed: !data && !!error, error, mutate, key: endPoint };
};

export const UseDebtorLoans = (debtor_id, creditor_id, user_id) => {
  const endPoint = debtor_id && creditor_id && user_id ? `/debtor/loans/${debtor_id}/${creditor_id}/${user_id}` : null;

  const { data, error, mutate } = useSWR(endPoint, fetcher, { ...defaultOptions });

  return { data, isLoading: !data, failed: !data && !!error, error, mutate, key: endPoint };
};

export const UseDebtorImages = (debtor_id, creditor_id) => {
  const endPoint = debtor_id && creditor_id ? `/dream/objects/debtor-images/R${creditor_id}_dU${debtor_id}` : null;

  const { data, error, mutate } = useSWR(endPoint, fetcher, { ...defaultOptions });

  return { data, isLoading: !data, failed: !data && !!error, error, mutate, key: endPoint };
};

export const UseDebtorPaidLoans = (debtor_id, user_id) => {
  const endPoint = debtor_id && user_id ? `/debtor/paid/loans/${debtor_id}/${user_id}` : null;

  const { data, error, mutate } = useSWR(endPoint, fetcher, { ...defaultOptions });

  return { data, isLoading: !data, failed: !data && !!error, error, mutate, key: endPoint };
};

export const UseDebtors = (creditor_id, user_id) => {
  const endPoint = creditor_id && user_id ? `/debtors/${creditor_id}/${user_id}` : null;

  const { data, error, mutate } = useSWR(endPoint, fetcher, { ...defaultOptions, dedupingInterval: 5 * 60000 });

  return { data, isLoading: !data, failed: !data && !!error, error, mutate, key: endPoint };
};

export const UseCobroDebtors = (creditor_id, user_id, collect_date) => {
  const endPoint = creditor_id && user_id ? `/cobro/debtors/${creditor_id}/${user_id}/${collect_date}` : null;

  const { data, error, mutate } = useSWR(endPoint, fetcher2, { defaultCobroOptions });

  return { data, isLoading: !data, failed: !data && !!error, error, mutate, key: endPoint };
};

export const UseLoan = (money_id, debtor_id, creditor_id, user_id) => {
  const endPoint =
    money_id && debtor_id && creditor_id && user_id
      ? `/loan/detail/${debtor_id}/${money_id}/${creditor_id}/${user_id}`
      : null;

  const { data, error, mutate } = useSWR(endPoint, fetcher, { ...defaultOptions });

  return { data, isLoading: !data, failed: !data && !!error, error, mutate, key: endPoint };
};

export const UseOwnerLoans = (user_id) => {
  const endPoint = user_id ? `/owner/${user_id}/loans` : null;

  const { data, error, mutate } = useSWR(endPoint, fetcher, { ...defaultOptions });

  return { data, isLoading: !data, failed: !data && !!error, error, mutate, key: endPoint };
};

//TODO: This api  will be discontinuued in the futute
export const UseLoanSignature = (creditor_id, mUniqueId) => {
  // const endPoint = mUniqueId ? `/loan/signature/${mUniqueId}` : null;
  const endPoint = creditor_id && mUniqueId ? `/dream/objects/loan/signature/${creditor_id}/${mUniqueId}` : null;

  const { data, error, mutate } = useSWR(endPoint, fetcher, { ...defaultOptions });

  return { data, isLoading: !data, failed: !data && !!error, error, mutate, key: endPoint };
};

export const UseLoanSignaturev2 = (mUniqueId) => {
  // const endPoint = mUniqueId ? `/loan/signature/${mUniqueId}` : null;
  const endPoint = mUniqueId ? `/dreamhost/objects/loan/signature/${mUniqueId}` : null;

  const { data, error, mutate } = useSWR(endPoint, fetcher, { ...defaultOptions });

  return { data, isLoading: !data, failed: !data && !!error, error, mutate, key: endPoint };
};

export const UseLoanIdentityPhoto = (mUniqueId) => {
  const endPoint = mUniqueId ? `/dreamhost/objects/loan/identity/${mUniqueId}` : null;

  const { data, error, mutate } = useSWR(endPoint, fetcher, { ...defaultOptions });

  return { data, isLoading: !data, failed: !data && !!error, error, mutate, key: endPoint };
};

export const UseCobroDeltaChanges = (creditor_id, last) => {
  const endPoint = creditor_id && last ? `/delta/changes/check/${creditor_id}/${last}` : null;

  const refreshInterval = import.meta.env.PROD ? 30 * 600000 : 30 * 1000;

  const { data, error, mutate } = useSWR(endPoint, fetcher2, {
    refreshInterval,
    dedupingInterval: 30000,
    revalidateIfStale: true,
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
  });

  return { data, isLoading: !data, failed: !data && !!error, error, mutate, key: endPoint };
};

export const UseCobroUserPermissions = (user_id, creditor_id) => {
  const endPoint = user_id && creditor_id ? `/user/route/permissions/${user_id}/${creditor_id}` : null;

  const { data, error, mutate } = useSWR(endPoint, fetcher2, { defaultCobroOptions });

  return { data, isLoading: !data, failed: !data && !!error, error, mutate, key: endPoint };
};

export const UseDebtorCedulaImg = (cedula, timestamp) => {
  const endPoint = cedula && timestamp ? `/debtorcedula/img/${cedula}/${timestamp}` : null;

  const { data, error, mutate } = useSWR(endPoint, fetcher2, { defaultCobroOptions });

  return { data, isLoading: !data, failed: !data && !!error, error, mutate, key: endPoint };
};

export const UseDebtorCedulaData = (cedula) => {
  const endPoint = cedula ? `/debtorcedula/data/${cedula}` : null;

  const { data, error, mutate } = useSWR(endPoint, fetcher2, {
    defaultCobroOptions,
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  return { data, isLoading: !data, failed: !data && !!error, error, mutate, key: endPoint };
};

export const UseRoles = (user_id) => {
  const endPoint = user_id ? `/roles` : null;

  const { data, error, mutate } = useSWR(endPoint, fetcher2, { defaultCobroOptions });

  return { data, isLoading: !data, failed: !data && !!error, error, mutate, key: endPoint };
};

export const UseCustomRoles = (user_id) => {
  const endPoint = user_id ? `/roles/${user_id}` : null;

  const { data, error, mutate } = useSWR(endPoint, fetcher2, { defaultCobroOptions });

  return { data, isLoading: !data, failed: !data && !!error, error, mutate, key: endPoint };
};

export const UseRolesPermission = (user_id) => {
  const endPoint = user_id ? `/rolespermissions` : null;

  const { data, error, mutate } = useSWR(endPoint, fetcher2, { defaultCobroOptions });

  return { data, isLoading: !data, failed: !data && !!error, error, mutate, key: endPoint };
};

export const UseRolePermission = (role_id) => {
  const endPoint = role_id ? `/rolespermissions/${role_id}` : null;

  const { data, error, mutate } = useSWR(endPoint, fetcher2);

  return { data, isLoading: !data, failed: !data && !!error, error, mutate, key: endPoint };
};

export const UsePermissions = (user_id) => {
  const endPoint = user_id ? `/permissions` : null;

  const { data, error, mutate } = useSWR(endPoint, fetcher2, { defaultCobroOptions });

  return { data, isLoading: !data, failed: !data && !!error, error, mutate, key: endPoint };
};

export const UseCobroRoute = (creditor_id, user_id) => {
  const endPoint = creditor_id && user_id ? `/cobro/route/${creditor_id}/${user_id}` : null;

  const { data, error, mutate } = useSWR(endPoint, fetcher2, { defaultCobroOptions });

  return { data, isLoading: !data, failed: !data && !!error, error, mutate, key: endPoint };
};

export const UseCobroUser = (user_id) => {
  const endPoint = user_id ? `/user/${user_id}` : null;

  const { data, error, mutate } = useSWR(endPoint, fetcher2, { defaultCobroOptions });

  return { data, isLoading: !data, failed: !data && !!error, error, mutate, key: endPoint };
};

export const UseCobroUserConfig = (user_id) => {
  const endPoint = user_id ? `/user/configv2/${user_id}` : null;

  const { data, error, mutate } = useSWR(endPoint, fetcher2, { defaultCobroOptions });

  return { data, isLoading: !data, failed: !data && !!error, error, mutate, key: endPoint };
};

export const UseCommon = (creditor_id, user_id) => {
  const endPoint = creditor_id && user_id ? `/cobro/cuadre/init/${creditor_id}/${user_id}` : null;

  const { data, error, mutate } = useSWR(endPoint, fetcher2, { defaultCobroOptions });

  return { data, isLoading: !data, failed: !data && !!error, error, mutate, key: endPoint };
};

export const UseCobroOwnerConfig = (owner_id) => {
  const endPoint = owner_id ? `/owner/config/${owner_id}` : null;

  const { data, error, mutate } = useSWR(endPoint, fetcher2, { defaultCobroOptions });

  return { data, isLoading: !data, failed: !data && !!error, error, mutate, key: endPoint };
};

export const UseDebtorLoansCheck = (cedula, creditor_id) => {
  const endPoint = cedula && creditor_id ? `/debtor/loans/checkv2/${creditor_id}/${cedula}/ced/p` : null;

  const { data, error, mutate } = useSWR(endPoint, fetcher2, { defaultCobroOptions });

  return { data, isLoading: !data, failed: !data && !!error, error, mutate, key: endPoint };
};

export const UseLoanApproval = (money_id) => {
  const endPoint = money_id ? `/loan/approval/${money_id}` : null;

  const { data, error, mutate } = useSWR(endPoint, fetcher, { ...defaultOptions });

  return { data, isLoading: !data, failed: !data && !!error, error, mutate, key: endPoint };
};

export const UseCustomerInvoice = (user_id, invoicer_id) => {
  const endPoint = user_id && invoicer_id ? `/invoice/${user_id}/${invoicer_id}` : null;

  const { data, error, mutate } = useSWR(endPoint, fetcher, { ...defaultOptions });

  return { data, isLoading: !data, failed: !data && !!error, error, mutate, key: endPoint };
};

export const UseCustomerInvoices = (owner_id) => {
  const endPoint = owner_id ? `/customer/invoices/${owner_id}` : null;

  const { data, error, mutate } = useSWR(endPoint, fetcher, {
    ...defaultOptions,
    refreshInterval: 120 * 60000,
    dedupingInterval: 120 * 60000,
  });

  return { data, isLoading: !data, failed: !data && !!error, error, mutate, key: endPoint };
};

export const UseCustomerInvoicesv2 = (owner_id, limit) => {
  const endPoint = owner_id ? `/customer/invoicesv2/${owner_id}/${limit}` : null;

  const { data, error, mutate } = useSWR(endPoint, fetcher, {
    ...defaultOptions,
    refreshInterval: 120 * 60000,
    dedupingInterval: 120 * 60000,
  });

  return { data, isLoading: !data, failed: !data && !!error, error, mutate, key: endPoint };
};

export const UseCustomerBalance = (owner_id, mainOnly = 0) => {
  const endPoint = owner_id ? `/customer/balance/${owner_id}/${mainOnly}` : null;

  const { data, error, mutate } = useSWR(endPoint, fetcher, {
    ...defaultOptions,
    refreshInterval: 120 * 60000,
    dedupingInterval: 120 * 60000,
  });

  return { data, isLoading: !data, failed: !data && !!error, error, mutate, key: endPoint };
};

export const UseBankInformation = (owner_id) => {
  const endPoint = owner_id ? "/bank/account/information" : null;

  const { data, error, mutate } = useSWR(endPoint, fetcher, {
    ...defaultOptions,
  });

  return { data, isLoading: !data, failed: !data && !!error, error, mutate, key: endPoint };
};

export const UseBankInformationv2 = (owner_id) => {
  const endPoint = owner_id ? "/bank/account/information/v2" : null;

  const { data, error, mutate } = useSWR(endPoint, fetcher, {
    ...defaultOptions,
  });

  return { data, isLoading: !data, failed: !data && !!error, error, mutate, key: endPoint };
};

export const UseCustomerAllowAccess = (owner_id) => {
  const endPoint = owner_id ? `/customer/allowacces/${owner_id}` : null;

  const { data, error, mutate } = useSWR(endPoint, fetcher, {
    refreshInterval: 10000,
    refreshWhenHidden: true,
  });

  return { data, isLoading: !data, failed: !data && !!error, error, mutate, key: endPoint };
};

export const UseLoanPayments = (money_id, debtor_id) => {
  const endPoint = money_id && debtor_id ? `/loan/payments/${money_id}/${debtor_id}` : null;

  const { data, error, mutate } = useSWR(endPoint, fetcher, { ...defaultOptions, dedupingInterval: 5 * 60000 });

  return { data, isLoading: !data, failed: !data && !!error, error, mutate, key: endPoint };
};

export const UseLoans = (creditor_id, user_id) => {
  const endPoint = creditor_id && user_id ? `/loans/${creditor_id}/${user_id}` : null;

  const { data, error, mutate } = useSWR(endPoint, fetcher, {
    ...defaultOptions,
    dedupingInterval: 60000 * 5,
    refreshInterval: 60000 * 5,
  });

  return { data, isLoading: !data, failed: !data && !!error, error, mutate, key: endPoint };
};

export const UseLoansXcobrar = (creditor_id, user_id) => {
  const endPoint = creditor_id && user_id ? `/xcobrar/loans/${creditor_id}/${user_id}` : null;

  const { data, error, mutate } = useSWR(endPoint, fetcher, {
    ...defaultOptions,
    dedupingInterval: 60000 * 5,
    refreshInterval: 60000 * 5,
  });

  return { data, isLoading: !data, failed: !data && !!error, error, mutate, key: endPoint };
};

export const UseDebtorsReorder = (creditor_id, user_id) => {
  const endPoint = creditor_id && user_id ? `/debtors/organize/${creditor_id}/${user_id}` : null;

  const { data, error, mutate } = useSWR(endPoint, fetcher, {
    ...defaultOptions,
    dedupingInterval: 60000 * 5,
    refreshInterval: 60000 * 5,
  });

  return { data, isLoading: !data, failed: !data && !!error, error, mutate, key: endPoint };
};

export const UseRoute = (creditor_id, user_id) => {
  const endPoint = creditor_id && user_id ? `/route/info/${creditor_id}/${user_id}` : null;

  const { data, error, mutate } = useSWR(endPoint, fetcher, { ...defaultOptions, refreshInterval: 10 * 60000 });

  return { data, isLoading: !data, failed: !data && !!error, error, mutate, key: endPoint };
};

export const UseApertura = (creditor_id, user_id) => {
  const endPoint = creditor_id && user_id ? `/route/apertura/${creditor_id}/${user_id}` : null;

  const { data, error, mutate } = useSWR(endPoint, fetcher, { ...defaultOptions, dedupingInterval: 10 * 60000 });

  return { data, isLoading: !data, failed: !data && !!error, error, mutate, key: endPoint };
};

export const UsePastApertura = (creditor_id) => {
  const endPoint = creditor_id ? `/route/past/apertura/${creditor_id}` : null;

  const { data, error, mutate } = useSWR(endPoint, fetcher, { ...defaultOptions, dedupingInterval: 10 * 60000 });

  return { data, isLoading: !data, failed: !data && !!error, error, mutate, key: endPoint };
};

export const UseRouteConfig = (owner_id) => {
  const endPoint = owner_id ? `/owner/configv2/${owner_id}` : null;

  const { data, error, mutate } = useSWR(endPoint, fetcher, { ...defaultOptions });

  return { data, isLoading: !data, failed: !data && !!error, error, mutate, key: endPoint };
};

export const UseOwnerConfig = (owner_id) => {
  const endPoint = owner_id ? `/owner/configv2/${owner_id}` : null;

  const { data, error, mutate } = useSWR(endPoint, fetcher, { ...defaultOptions });

  return { data, isLoading: !data, failed: !data && !!error, error, mutate, key: endPoint };
};

export const UseRouteAccessCodes = (owner_id) => {
  const endPoint = owner_id ? `/central/access/codes/${owner_id}` : null;

  const { data, error, mutate } = useSWR(endPoint, fetcher, { ...defaultOptions });

  return { data, isLoading: !data, failed: !data && !!error, error, mutate, key: endPoint };
};

export const UseCuadre = (creditor_id, current_week) => {
  const endPoint = creditor_id && current_week ? `/route/cuadre/${creditor_id}/${current_week}` : null;

  const { data, error, mutate } = useSWR(endPoint, fetcher, {
    ...defaultOptions,
    dedupingInterval: 6000 * 2,
    refreshInterval: 60000 * 2,
  });

  return { data, isLoading: !data, failed: !data && !!error, error, mutate, key: endPoint };
};

export const UseCobroList = (creditor_id, current_week) => {
  const endPoint = creditor_id && current_week ? `/cobro/list/${creditor_id}/${current_week}` : null;

  const { data, error, mutate } = useSWR(endPoint, fetcher, {
    ...defaultOptions,
    dedupingInterval: 6000 * 5,
    refreshInterval: 60000 * 5,
  });

  return { data, isLoading: !data, failed: !data && !!error, error, mutate, key: endPoint };
};

export const UseCobroListChanges = (creditor_id, current_week) => {
  const endPoint = creditor_id && current_week ? `/cobro/list/changes/${creditor_id}/${current_week}` : null;

  const { data, error, mutate } = useSWR(endPoint, fetcher, {
    ...defaultOptions,
    dedupingInterval: 6000 * 5,
    refreshInterval: 60000 * 5,
  });

  return { data, isLoading: !data, failed: !data && !!error, error, mutate, key: endPoint };
};

export const UseGastos = (creditor_id, start_date, end_date) => {
  const endPoint = creditor_id && start_date && end_date ? `/spenses/${creditor_id}/${start_date}/${end_date}` : null;

  const { data, error, mutate } = useSWR(endPoint, fetcher, {
    ...defaultOptions,
    dedupingInterval: 6000 * 2,
    refreshInterval: 60000 * 2,
  });

  return { data, isLoading: !data, failed: !data && !!error, error, mutate, key: endPoint };
};

export const UseEntregas = (creditor_id, start_date, end_date) => {
  const endPoint = creditor_id && start_date && end_date ? `/entregas/${creditor_id}/${start_date}/${end_date}` : null;

  const { data, error, mutate } = useSWR(endPoint, fetcher, {
    ...defaultOptions,
    dedupingInterval: 6000 * 2,
    refreshInterval: 60000 * 2,
  });

  return { data, isLoading: !data, failed: !data && !!error, error, mutate, key: endPoint };
};

export const UseCollected = (creditor_id, start_date, end_date) => {
  const endPoint = creditor_id && start_date && end_date ? `/collected/${creditor_id}/${start_date}/${end_date}` : null;

  const { data, error, mutate } = useSWR(endPoint, fetcher, {
    ...defaultOptions,
    dedupingInterval: 6000 * 2,
    refreshInterval: 60000 * 2,
  });

  return { data, isLoading: !data, failed: !data && !!error, error, mutate, key: endPoint };
};

export const UseReferralsAccepted = (user_id) => {
  const endPoint = user_id ? `/referrals/accepted/${user_id}` : null;

  const { data, error, mutate } = useSWR(endPoint, fetcher, { ...defaultOptions, refreshInterval: 60000 * 60 * 12 });

  return { data, isLoading: !data, failed: !data && !!error, error, mutate, key: endPoint };
};

export const UseReferralsPending = (user_id) => {
  const endPoint = user_id ? `/referrals/pending/${user_id}` : null;

  const { data, error, mutate } = useSWR(endPoint, fetcher, { ...defaultOptions, refreshInterval: 60000 * 60 * 3 });

  return { data, isLoading: !data, failed: !data && !!error, error, mutate, key: endPoint };
};

export const UseEmployee = (owner_id, user_id) => {
  const endPoint = owner_id && user_id ? `/employee/${owner_id}/${user_id}` : null;

  const { data, error, mutate } = useSWR(endPoint, fetcher, { ...defaultOptions, refreshInterval: 60000 * 60 * 12 });

  return { data, isLoading: !data, failed: !data && !!error, error, mutate, key: endPoint };
};

export const UseEmployees = (creditor_id, user_id) => {
  const endPoint = creditor_id && user_id ? `/employees/${creditor_id}/${user_id}` : null;

  const { data, error, mutate } = useSWR(endPoint, fetcher, { ...defaultOptions, refreshInterval: 60000 * 60 * 12 });

  return { data, isLoading: !data, failed: !data && !!error, error, mutate, key: endPoint };
};

export const UseOwnerRoutes = (user_id, employee_id) => {
  const endPoint = employee_id && user_id ? `/owner/routes/${user_id}/${employee_id}` : null;

  const { data, error, mutate } = useSWR(endPoint, fetcher, { ...defaultOptions, refreshInterval: 60000 * 60 * 12 });

  return { data, isLoading: !data, failed: !data && !!error, error, mutate, key: endPoint };
};
