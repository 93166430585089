import { useEffect } from "react";
import { selectDebtorsReorder, selectLoans, updateLoansOrder } from "../store/slices/loans";
import { useDispatch, useSelector } from "react-redux";
// import { createQueue } from "../store/slices/syncronization";
// import { getUniqueID } from "@shared/functions";
import { selectCommon } from "../store/slices/common";
import usePouchQueues from "../Hooks/usePouchQueues";
import { syncTypes } from "./sync/getDataType";
import { pouches_db } from "../config";

function mapOrder(array, order, key) {
  array.sort(function (a, b) {
    var A = a[key],
      B = b[key];

    if (order.indexOf(A) > order.indexOf(B)) {
      return 1;
    } else {
      return -1;
    }
  });

  return array;
}

const UpdateDebtorsReordered = () => {
  const sync_db = usePouchQueues(pouches_db.SYNC_QUEUES);
  const history_db = usePouchQueues(pouches_db.SYNC_HISTORY);

  const dispatch = useDispatch();
  const loans = useSelector(selectLoans);
  const debtorsReorded = useSelector(selectDebtorsReorder);
  const common = useSelector(selectCommon);

  async function updateAllLoansOrder() {
    const unPaidLoans = loans.filter((loan) => loan.paymentAmount === 0 && loan.paymentMora === 0);
    const paidLoans = loans.filter((loan) => loan.paymentAmount > 0 || loan.paymentMora > 0);
    const debtorIds = debtorsReorded.map((loan) => loan.dUniqueId);
    const newOrderedLoans = mapOrder(unPaidLoans, debtorIds, "dUniqueId");

    const newLoansOrder = [...newOrderedLoans, ...paidLoans];

    // const syncProps = {
    //   syncronization_id: `R${common.creditor_id}_${common.collect_date}_${getUniqueID()}`,
    //   queueTime: Date.now(),
    //   syncPriority: 7,
    //   endPoint: "/bgsync/cobro/debtors/organize",
    //   syncType: "loansReorder",
    //   syncTitle: "Orden de prestamo actualizado",
    //   syncName: ``,
    //   uniqueType: "loansReorder",
    // };

    const _id = `R${common.creditor_id}_${common.collect_date}_${syncTypes.DEBTORS_REORDER}`;
    const syncQueueData = {
      _id,
      syncPriority: 7,
      syncType: syncTypes.DEBTORS_REORDER,
      postData: { debtors: debtorIds },
    };
    await sync_db.putSertQueue(syncQueueData);

    const syncTitle = "Orden de prestamo actualizado";
    const syncHistoryData = { _id, syncType: syncTypes.DEBTORS_REORDER, syncTitle, syncName: null };
    await history_db.putSertHistory(syncHistoryData);

    dispatch(updateLoansOrder(newLoansOrder));
    //dispatch(removeNonUniqueType(syncProps));
    // dispatch(createQueue({ postData: { debtors: debtorIds }, syncProps }));
  }

  useEffect(() => {
    updateAllLoansOrder();
    //eslint-disable-next-line
  }, []);

  return null;
};

export default UpdateDebtorsReordered;
