import Typography from "@nodes/@material-ui/core/Typography";
import Box from "@nodes/@material-ui/core/Box";

const getClassName = () => {
  if (import.meta.env.VITE_ENV === "production") {
    return "text-primary";
  } else if (import.meta.env.VITE_ENV === "staging") {
    return "text-info";
  } else if (import.meta.env.VITE_ENV === "local") {
    return "text-danger";
  } else {
    return "text-warning";
  }
};

const DisplayAppEnvName = ({ displayLabel = true, avoidProd = false }) => {
  if (avoidProd && import.meta.env.VITE_ENV === "production") return null;
  return (
    <Box p={1} component="div">
      <div className="text-center">
        {displayLabel && (
          <Typography variant="body1" className="font-italic" paragraph>
            App Version {import.meta.env.VITE_VERSION}
          </Typography>
        )}

        <Typography variant="h6" className={getClassName()}>
          {import.meta.env.VITE_ENV}
        </Typography>
      </div>
    </Box>
  );
};

export default DisplayAppEnvName;
