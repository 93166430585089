
// import FormGroup from "@nodes/@material-ui/core/FormGroup";
// import FormControlLabel from "@nodes/@material-ui/core/FormControlLabel";
// import Checkbox from "@nodes/@material-ui/core/Checkbox";
// import Grid from "@nodes/@material-ui/core/Grid";
import Box from "@nodes/@material-ui/core/Box";
import Typography from "@nodes/@material-ui/core/Typography";
import Card from "@nodes/@material-ui/core/Card";
// import CardContent from "@nodes/@material-ui/core/CardContent";
import NumberFormat from "@nodes/react-number-format";
// import { printAmount, printDate, newDate } from "../functions";

// const concatPayoffLabel = (loan) => {
//   // const renewalLoan = (
//   //   <Card>
//   //     <CardContent>
//   //       <Typography variant="h6">
//   //         {printAmount(loan.amount)} | {printDate(newDate(loan.given_date), "dd-MMM-yy")}
//   //       </Typography>
//   //       <Typography variant="h6">Balance {printAmount(loan.balance)}</Typography>
//   //     </CardContent>
//   //   </Card>
//   // );
//   // return renewalLoan;

//   return (
//     <Typography variant="body2">
//       Saldar {printAmount(loan.amount)} | {printDate(newDate(loan.given_date), "dd-MMM-yy")} Balance{" "}
//       {printAmount(loan.balance)}!
//     </Typography>
//   );
// };

//eslint-disable-next-line
const RenderOldBalancePayOff = ({ oldBalance }) => {
  if (oldBalance.totalBalance === 0) return null;

  return (
    <div>
      <Card variant="outlined">
        <Box m={1}>
          {/* <Typography variant="subtitle1" component="p">
            Cliente tiene prestamo(s) con balance!
          </Typography> */}
          <Typography variant="subtitle1" component="p" className="text-warning font-weight-bold">
            <NumberFormat
              value={oldBalance}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"Descontar Balance actual $"}
            />
          </Typography>
        </Box>
      </Card>

      {/* <Grid container>
        {oldBalance.loansBalance.map((loan) => (
          <Grid key={loan.money_id} item xs={12}>
            <FormGroup row>
              <Box paddingBottom={0}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={loan.isChecked}
                      onChange={() => handlePayoffBalanceToggle(loan.money_id, loan.isChecked)}
                      name={loan.money_id}
                      value={loan.balance}
                    />
                  }
                  label={concatPayoffLabel(loan)}
                />
              </Box>
            </FormGroup>
          </Grid>
        ))}
      </Grid> */}
    </div>
  );
};

export default RenderOldBalancePayOff;
