/**
 * Sorts an array of objects based on `syncPriority` and `queuedTime`.
 *
 * @param {Array<Object>} array - The array of objects to sort.
 * @returns {Array<Object>} - The sorted array.
 */

const sortBySyncPriorityAndQueuedTime = (dataList) => {
  return dataList.sort((a, b) => {
    if (a.syncPriority !== b.syncPriority) {
      // Sort by syncPriority (ascending)
      return a.syncPriority - b.syncPriority;
    } else {
      // If syncPriority is the same, sort by queuedTime (ascending)
      return a.queuedTime - b.queuedTime;
    }
  });
};

export default sortBySyncPriorityAndQueuedTime;
