import { useState, useEffect } from "react";
import SearchBox from "@shared/components/common/SearchBox";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import Box from "@material-ui/core/Box";
import { Redirect } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
// import FullLoadingScreen from "@shared/components/FullLoadingScreen";
import { printAmount, newSearchArray, sortArrayObjs } from "@shared/functions";
import { getXcobrarLoans } from "@/Selectors";
import { CgUserList } from "react-icons/cg";
import { FcEmptyFilter, FcClearFilters } from "react-icons/fc";
import { AiOutlinePushpin } from "react-icons/ai";
import { MdVisibility } from "react-icons/md";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import Skeleton from "@shared/components/SkeletonElement";
import BottomNavigation from "../components/BottomNavigation";
import XcobrarLoan from "@shared/components/XcobrarLoan";
import UpdateDebtorsReordered from "../components/UpdateDebtorsReordered";
// import Loading from "@shared/components/Loading";
// import backend from "@shared/server/backend";
import LoadingInspect from "@shared/components/LoadingInspect";
import LinearProgressWithLabel from "@shared/components/LinearProgressWithLabel";
import { useSelector, useDispatch } from "react-redux";
import { selectDebtorsReorded, selectLastFetch, selectLoans, fetchLoansThunk } from "../store/slices/loans";
import { selectCommon } from "../store/slices/common";
// import { selectDevice } from "../store/slices/miscData";
// import { UseCuadre } from "@shared/server/fetcher";
// import { selectCuadre } from "../store/slices/cuadre";
import { useGetPermissionsQuery } from "../store/slices/api";
import { selectDisplay } from "../store/slices/ui";
// import { selectAuth } from "../store/slices/auth";

const XCobrar = () => {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const [xCobrar, setXcobrar] = useState({ count: 0, amount: 0, list: [], xCobrarTotal: 0, xCobrarCount: 0 });
  const [selectedFilter, setSelectedFilter] = useState("xCobrar");
  const [filterDescription, setFilterDescription] = useState(null);
  const [filteredLoans, setFilteredLoans] = useState([]);
  const [showHidden, setShowHidden] = useState(false);
  const [hiddenLoans, setHiddenLoans] = useState([]);
  const [nonHiddenLoans, setNonHiddenLoans] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [pageState, setPageState] = useState("idle");

  // const auth = useSelector(selectAuth);
  const displayNav = useSelector(selectDisplay);
  const updatedDebtorsReorded = useSelector(selectDebtorsReorded);
  const loansLastFetch = useSelector(selectLastFetch);
  const loans = useSelector(selectLoans);
  const common = useSelector(selectCommon);
  // const device = useSelector(selectDevice);
  // const reduxCuadre = useSelector(selectCuadre);
  // const dispatch = useDispatch();

  // const { data: swrCuadre } = UseCuadre(common.creditor_id, common.collect_week_date);
  // const cuadre = swrCuadre ?? reduxCuadre;

  const { data: permissions } = useGetPermissionsQuery({ creditor_id: common.creditor_id, user_id: common.user_id });
  // const { data: cuadre } = useGetCuadreQuery({
  //   creditor_id: common.creditor_id,
  //   collect_week_date: common.collect_week_date,
  // });

  // const insertDiagnostic = async () => {
  //   await backend.put("/update/route/cobro", { creditor_id: common.creditor_id });

  //   await dispatch(diagnosticThunk({ page: "login" }));
  // };

  useEffect(() => {
    // if (auth.diagnostic === "idle") {
    //   insertDiagnostic();
    // }

    if (!loansLastFetch) {
      dispatch(fetchLoansThunk(common));
    }

    if (!loans) return;
    let loansResult;
    if (selectedFilter === "xCobrar") {
      const xCobrarLoans = getXcobrarLoans(loans);
      setXcobrar(xCobrarLoans);

      // loansResult = xCobrarLoans.list;
      loansResult = xCobrarLoans.list.filter((loan) => loan.isHidden !== true);
      setFilterDescription("Prestamos x Cobrar y Vencidos recientemente!");
    } else if (selectedFilter === "Al Dia") {
      loansResult = loans.filter((loan) => loan.statusText === "Al Dia");
      setFilterDescription("Prestamos x Cobrar al Dia!");
    } else if (selectedFilter === "atrasos") {
      loansResult = loans.filter((loan) => loan.statusText === "Atrasos");
      setFilterDescription("Prestamos con pagos en Atrasos!");
    } else if (selectedFilter === "sale_hoy") {
      loansResult = loans.filter((loan) => loan.sale_hoy === true);
      setFilterDescription("Prestamos saliente de esta semana!");
    } else if (selectedFilter === "xRenewal") {
      const xRenewalLoans = loans.filter((loan) => loan.xRenewal === true);
      const sorted = sortArrayObjs(xRenewalLoans, "current_week", "completed");
      loansResult = sorted.reverse();
      setFilterDescription("Prestamos para renovar en sus ultima 3 semanas!");
    } else if (selectedFilter === "new_loan") {
      loansResult = loans.filter((loan) => loan.new_loan === true);
      setFilterDescription("Prestamos nuevo, renovado la semana pasada!");
    } else if (selectedFilter === "adelantos") {
      loansResult = loans.filter((loan) => loan.statusText === "Adelanto");
      setFilterDescription("Prestamos con pagos de Adelanto!");
    } else if (selectedFilter === "vencidos") {
      loansResult = loans.filter((loan) => loan.statusText === "Vencido");
      setFilterDescription("Prestamos vencido con balance!");
    }

    setFilteredLoans(loansResult);
    setPageState("loaded");
  }, [loans, selectedFilter]);

  useEffect(() => {
    if (pageState === "idle") return;

    if (searchText.length > 2) {
      setNonHiddenLoans(newSearchArray(loans, searchText));
    } else {
      setNonHiddenLoans(filteredLoans);
    }
    setPageState("ready");
    //eslint-disable-next-line
  }, [filteredLoans, searchText]);

  if (displayNav === false) return <Redirect to="/swr/fetcher" />;
  if (pageState !== "ready" || !common || !permissions)
    return (
      <LoadingInspect
        data={[
          {
            loaded: pageState === "ready",
            label: "Page State",
          },
          {
            loaded: common && Object.keys(common).length > 0,
            label: "Common",
          },
          {
            loaded: permissions && Object.keys(permissions).length > 0,
            label: "Permissions",
          },
        ]}
      />
    );

  const progress = xCobrar.xCobrarTotal > 0 ? (xCobrar.amount / xCobrar.xCobrarTotal) * 100 : 0;

  const handleSearch = (query) => {
    setSearchText(query);
  };

  const handleFilterLoans = (filter) => {
    handleClose();
    setSelectedFilter(filter);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleHiddenLoans = () => {
    setShowHidden(true);
    setHiddenLoans(loans.filter((loan) => loan.isHidden === true));
  };

  // const lastPaymentId = localStorage.getItem("lastPaymentId");
  // const lastPaymentId = null;

  return (
    <div className="mt-2">
      {updatedDebtorsReorded === true && <UpdateDebtorsReordered />}

      {/* {lastPaymentId && (
        <div className="text-left bg-warning pl-2 pb-2">
          <Typography variant="h6" component="p" gutterBottom className="font-weight-bold">
            Pago no fue guardado!
          </Typography>

          <Typography variant="body1" component="p" paragraph>
            Su ultimo pago realizado no fue guardado correctamente.
          </Typography>

          <Typography variant="body1" component="p" paragraph>
            Por favor realize el pago nuevamente.
          </Typography>
        </div>
      )} */}

      <Box component="div" p={1}>
        <Grid container>
          <Grid item xs={8}>
            <Typography variant="h5" component="h4" gutterBottom>
              Lista de Cobro!
            </Typography>
          </Grid>

          <Grid item xs={2} align="right">
            <Link to={"/debtors/organize"}>
              <Button className="text-info">
                <CgUserList size="1.8em" />
              </Button>
            </Link>
          </Grid>
          <Grid item xs={2} align="right">
            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
              {selectedFilter === "xCobrar" ? <FcEmptyFilter size="1.8em" /> : <FcClearFilters size="1.8em" />}
            </Button>
            <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
              <MenuItem onClick={() => handleFilterLoans("xCobrar")}>
                {selectedFilter === "xCobrar" && <AiOutlinePushpin className="text-primary" size="1.3em" />}
                Mostrar X Cobrar
              </MenuItem>
              <Divider />
              <MenuItem onClick={() => handleFilterLoans("new_loan")}>
                {selectedFilter === "new_loan" && <AiOutlinePushpin className="text-primary" size="1.3em" />}
                Prestamos Nuevos
              </MenuItem>
              <Divider />
              <MenuItem onClick={() => handleFilterLoans("sale_hoy")}>
                {selectedFilter === "sale_hoy" && <AiOutlinePushpin className="text-primary" size="1.3em" />}
                Prestamos Saliente
              </MenuItem>
              <Divider />
              <MenuItem onClick={() => handleFilterLoans("xRenewal")}>
                {selectedFilter === "xRenewal" && <AiOutlinePushpin className="text-primary" size="1.3em" />}
                Prestamos Renovo
              </MenuItem>
              <Divider />
              <MenuItem onClick={() => handleFilterLoans("Al Dia")}>
                {selectedFilter === "Al Dia" && <AiOutlinePushpin className="text-primary" size="1.3em" />}
                Mostrar Al Dia
              </MenuItem>
              <Divider />
              <MenuItem onClick={() => handleFilterLoans("adelantos")}>
                {selectedFilter === "adelantos" && <AiOutlinePushpin className="text-primary" size="1.3em" />}
                Mostrar en Adelantos
              </MenuItem>
              <Divider />
              <MenuItem onClick={() => handleFilterLoans("atrasos")}>
                {selectedFilter === "atrasos" && <AiOutlinePushpin className="text-primary" size="1.3em" />}
                Mostrar en Atrasos
              </MenuItem>
              <Divider />
              <MenuItem onClick={() => handleFilterLoans("vencidos")}>
                {selectedFilter === "vencidos" && <AiOutlinePushpin className="text-primary" size="1.3em" />}
                Mostrar Vencidos
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>
        <Box>
          {+common.owner_id !== 2593 && +common.owner_id !== 2149 && (
            <Typography variant="subtitle1" component="p" className="font-weight-bold">
              {/* Prestamos restante ({route.cuadre_count - xCobrar.count}):{" "}
             {printAmount(route.cuadre_amount - xCobrar.amount)} */}
              Prestamos restante{" "}
              {progress > 43 || permissions.perm_xcobrar ? (
                <span>
                  ({xCobrar.failedList?.length}): {printAmount(xCobrar.failedAmount)}
                </span>
              ) : (
                <span>({xCobrar.failedList?.length})</span>
              )}
            </Typography>
          )}

          {+common.owner_id === 2149 && (
            <Typography variant="subtitle1" component="p" className="font-weight-bold">
              {/* Prestamos restante ({route.cuadre_count - xCobrar.count}):{" "}
             {printAmount(route.cuadre_amount - xCobrar.amount)} */}
              Prestamos restante{" "}
              {progress > 85 && (
                <span>
                  ({xCobrar.failedList?.length}): {printAmount(xCobrar.failedAmount)}
                </span>
              )}
            </Typography>
          )}
          <LinearProgressWithLabel value={progress} />
        </Box>
        <SearchBox onChange={handleSearch} placeHolder="Escriba para buscar Prestamo..." value={searchText} />

        <Typography variant="body2" style={{ marginTop: -6, marginBottom: 6 }}>
          <i>{filterDescription}</i>
        </Typography>
      </Box>

      {!loansLastFetch && (
        <>
          <Typography variant="h6" gutterBottom align="center">
            Descargando lista de prestamos!
          </Typography>
          {[1, 2, 3, 4, 5, 6].map((n) => (
            <Skeleton key={n} />
          ))}
        </>
      )}
      {/* {!loansLastFetch && <FullLoadingScreen />} */}

      {loansLastFetch && loans.length === 0 && (
        <div className="container mt-5 mb-5">
          <Typography variant="h5" componet="h3" gutterBottom>
            Prestamos x Cobrar!
          </Typography>
          <Typography variant="body1" componet="p" paragraph>
            Aqui se muestra la lista de Cobro.
          </Typography>
          <Typography variant="body1" componet="p" paragraph>
            Por el momento, la lista de cobro esta vacia!
          </Typography>
        </div>
      )}

      <div>
        {loansLastFetch &&
          nonHiddenLoans.map(function (loan, index) {
            return <XcobrarLoan key={index} loan={loan} />;
          })}
      </div>

      {!showHidden && (
        <div>
          <Button
            fullWidth
            size="small"
            onClick={handleHiddenLoans}
            startIcon={<MdVisibility />}
            variant="outlined"
            color="default">
            Mostrar prestamos oculto!
          </Button>
          <br />
          <br />
        </div>
      )}

      {showHidden && hiddenLoans.length > 0 && (
        <div>
          <Typography variant="h6" componet="p" align="center" gutterBottom>
            Prestamos Ocultado!
          </Typography>
          {hiddenLoans.map(function (loan, index) {
            return <XcobrarLoan key={index} loan={loan} />;
          })}
        </div>
      )}

      <div className="mb-5"></div>

      <BottomNavigation />
    </div>
  );
};

export default XCobrar;
