export const phoneNumber = "3473892028";
export const emailAddress = "ayuda@wprestamos.com";
export const twilioNumber = "3473892028";
export const publicAddress = "wprestamos.com/";
export const publicCobroAddress = "cobro.wprestamos.com/";
export const MAX_PENDING_MONTHS_ALLOWED = 2;

//Pay users for referrals after this amount of months
export const referralsSignUpMonths = 4;

//Free Trial Periods
export const freeTrialsMonth = 3;
