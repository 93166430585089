import { useState } from "react";
import Dialog from "@nodes/@material-ui/core/Dialog";
import Box from "@nodes/@material-ui/core/Box";
import DialogContent from "@nodes/@material-ui/core/DialogContent";
import SignaturePad from "./SignaturePad";
import { makeStyles } from "@nodes/@material-ui/core";
import Divider from "@nodes/@material-ui/core/Divider";
import Typography from "@nodes/@material-ui/core/Typography";
import useMediaQuery from "../hooks/useMediaQuery";

const useStyles = makeStyles(() => ({
  label: {
    fontSize: "20px",
  },
  root: {
    paddingLeft: 1,
    paddingRight: 1,
  },
}));

export default function SignatureDialog({ signature, setSignature, name }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const fullScreen = useMediaQuery("min-width:820px");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSetSignature = (signature) => {
    setOpen(false);
    setSignature(signature);
  };

  return (
    <div>
      <Box onClick={handleClickOpen} component="div" p={1}>
        {signature ? (
          <img alt="Imagen Cliente" src={signature} style={{ height: "25vh", width: "100%" }} />
        ) : (
          // <div style={{ border: "solid dashed 1px", width: "100%", height: 90 }}></div>
          <img alt="Imagen Cliente" src="/../../images/signature.png" style={{ height: "25vh", width: "100%" }} />
        )}
        <Divider />
        <Divider />
        <Typography variant="body1">{name}</Typography>
      </Box>

      <Dialog
        classes={{ root: classes.root }}
        fullScreen={!fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title">
        <DialogContent className={classes.root}>
          <SignaturePad setSignature={handleSetSignature} name={name} />
        </DialogContent>
      </Dialog>
    </div>
  );
}
