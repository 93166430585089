import React, { useState } from "react";
import Menu from "@nodes/@material-ui/core/Menu";
import MenuItem from "@nodes/@material-ui/core/MenuItem";
// import MoreVertIcon from "@nodes/@material-ui/icons/MoreVert";
// import EditIcon from "@nodes/@material-ui/icons/Edit";
// import DeleteIcon from "@nodes/@material-ui/icons/Delete";
import Divider from "@nodes/@material-ui/core/Divider";
import PersonIcon from "@nodes/@material-ui/icons/Person";
import IconButton from "@nodes/@material-ui/core/IconButton";
import { FaHandHoldingUsd, FaUserEdit, FaUserCog, FaHandHoldingHeart, FaRegAddressCard } from "@nodes/react-icons/fa";
import { BsCloudUpload, BsArrowsMove } from "@nodes/react-icons/bs";
import { FcDataConfiguration } from "@nodes/react-icons/fc";
import DeleteForeverIcon from "@nodes/@material-ui/icons/DeleteForever";
import { mainApp } from "@/config";
import { useHistory } from "@nodes/react-router-dom";

export default function DebtorMenu({ debtor }) {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { pathname } = history.location;
  const debtorProfileUrl = `/debtor/profile/${debtor.dUniqueId}`;
  return (
    <div>
      <IconButton aria-label="settings" onClick={handleClick}>
        <FaUserCog />
      </IconButton>

      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem className="text-info" onClick={() => history.push(`/debtor/edit/${debtor.dUniqueId}`)}>
          {/* <EditIcon className="pr-1" /> */}
          <FaUserEdit size="1.6em" className="pr-1" />
          Modificar Cliente
        </MenuItem>
        <Divider />

        <MenuItem onClick={() => history.push(`/redirect/page/${debtor.dUniqueId}/0/${debtor.cedula}`)}>
          <FaHandHoldingUsd size="1.6em" className="pr-1" />
          Nuevo Prestamo
        </MenuItem>
        <Divider />

        <MenuItem onClick={() => history.push(`/debtor/upload/${debtor.dUniqueId}/profile`)}>
          <BsCloudUpload size="1.6em" className="pr-1" />
          Subir Foto
        </MenuItem>
        <Divider />

        {pathname !== debtorProfileUrl && debtor.dUniqueId && (
          <span>
            <MenuItem onClick={() => history.push(debtorProfileUrl)}>
              <PersonIcon className="pr-1" />
              Perfil del Cliente
            </MenuItem>
            <Divider />
          </span>
        )}

        {mainApp === 1 && (
          <span>
            {pathname === debtorProfileUrl && debtor.debtor_id && (
              <span>
                <MenuItem onClick={() => history.push(`/debtor/view/images/${debtor.debtor_id}/${debtor.dUniqueId}`)}>
                  <FaRegAddressCard size="1.6em" className="pr-1" />
                  Ver Fotos
                </MenuItem>
                <Divider />
              </span>
            )}

            {pathname === debtorProfileUrl && debtor.debtor_id && (
              <span>
                <MenuItem onClick={() => history.push(`/debtor/paid/${debtor.debtor_id}`)}>
                  <FaHandHoldingHeart size="1.6em" className="pr-1" />
                  Prestamos Saldado
                </MenuItem>
                <Divider />
              </span>
            )}

            {pathname === debtorProfileUrl && debtor.debtor_id && (
              <span>
                <MenuItem onClick={() => history.push(`/debtor/move/${debtor.debtor_id}`)}>
                  <BsArrowsMove size="1.6em" className="pr-2" />
                  Mover de Ruta
                </MenuItem>
                <Divider />
              </span>
            )}

            {pathname === debtorProfileUrl && debtor.debtor_id && (
              <span>
                <MenuItem onClick={() => history.push(`/debtor/config/${debtor.debtor_id}`)}>
                  <FcDataConfiguration size="1.8em" className="pr-2" />
                  Ajustes
                </MenuItem>
                <Divider />
              </span>
            )}

            {pathname === debtorProfileUrl && debtor.debtor_id && (
              <span>
                <MenuItem className="text-danger" onClick={() => history.push(`/debtor/delete/${debtor.debtor_id}`)}>
                  <DeleteForeverIcon className="pr-1" />
                  Eliminar Cliente
                </MenuItem>
                <Divider />
              </span>
            )}
          </span>
        )}
      </Menu>
    </div>
  );
}
