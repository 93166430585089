import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Divider from "@material-ui/core/Divider";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
// import Loading from "@shared/components/Loading";
import Chip from "@material-ui/core/Chip";
import RenderOwnerConfig from "@shared/components/RenderOwnerConfig";
import { useSelector } from "react-redux";
import { FcSynchronize } from "react-icons/fc";
import LoadingInspect from "@shared/components/LoadingInspect";
import { selectCommon } from "../store/slices/common";
import CircularProgress from "@nodes/@material-ui/core/CircularProgress";
// import { localStoragePrintApp } from "@/config";
import {
  useGetOwnerConfigQuery,
  useGetPermissionsQuery,
  useGetRouteQuery,
  useGetUserConfigQuery,
  useGetUserQuery,
  useUpdateOwnerConfigMutation,
  useUpdateUserConfigMutation,
} from "../store/slices/api";
import { useState } from "react";
import { useAlert } from "react-alert";

const UserConfig = () => {
  const alert = useAlert();
  const [refetchLoader, setRefetchLoader] = useState("idle");
  const common = useSelector(selectCommon);

  const { data: route, refetch: refetchRoute } = useGetRouteQuery({
    creditor_id: common.creditor_id,
    user_id: common.user_id,
    collect_date: common.collect_date,
  });
  const { data: user } = useGetUserQuery(common.user_id);
  const { data: userConfig, refetch: refetchUserConfig } = useGetUserConfigQuery(common.user_id);
  const { data: ownerConfig, refetch: refetchOwnerConfig } = useGetOwnerConfigQuery(common.owner_id);
  const { data: permissions, refetch: refetchPermissions } = useGetPermissionsQuery({
    creditor_id: common.creditor_id,
    user_id: common.user_id,
  });

  const [updateUserConfig] = useUpdateUserConfigMutation();
  const [updateOwnerConfig] = useUpdateOwnerConfigMutation();

  const handleOwnerConfigChange = (name, toggle) => {
    let result = [];

    result[name] = +toggle === 1 ? "0" : "1";

    updateOwnerConfig({ owner_id: common.owner_id, ...result });
  };

  const handlePrintAppChange = (event) => {
    updateUserConfig({ user_id: common.user_id, printApp: event.target.value });
    // localStorage.setItem(localStoragePrintApp, event.target.value);
  };

  const handleRefetch = async () => {
    setRefetchLoader("loading");

    const res1 = await refetchUserConfig();
    if (res1.status === "fulfilled") {
      alert.success("Impresora actualizada!", { timeout: 4000, position: "bottom center" });
    } else {
      alert.error("Error, intente de nuevo!", { timeout: 4000, position: "bottom center" });
    }

    const res2 = await refetchOwnerConfig();
    if (res2.status === "fulfilled") {
      alert.success("Configuraciones actualizada!", { timeout: 4000, position: "bottom center" });
    } else {
      alert.error("Error, intente de nuevo!", { timeout: 4000, position: "bottom center" });
    }

    const res3 = await refetchPermissions();
    if (res3.status === "fulfilled") {
      alert.success("Persmisos actualizado!", { timeout: 4000, position: "bottom center" });
    } else {
      alert.error("Error, intente de nuevo!", { timeout: 4000, position: "bottom center" });
    }

    const res4 = await refetchRoute();
    if (res4.status === "fulfilled") {
      alert.success("Ruta actualizada!", { timeout: 4000, position: "bottom center" });
    } else {
      alert.error("Error, intente de nuevo!", { timeout: 4000, position: "bottom center" });
    }

    setRefetchLoader("completed");
  };

  // if (!user || !route || !userConfig || !ownerConfig || !permissions) return <Loading />;
  if (!user || !route || !userConfig || !ownerConfig || !permissions)
    return (
      <LoadingInspect
        data={[
          {
            loaded: common && Object.keys(common).length > 0,
            label: "Common",
          },
          {
            loaded: user && Object.keys(user).length > 0,
            label: "User",
          },
          {
            loaded: userConfig && Object.keys(userConfig).length > 0,
            label: "userConfig",
          },
          {
            loaded: route && Object.keys(route).length > 0,
            label: "route",
          },
          {
            loaded: ownerConfig && Object.keys(ownerConfig).length > 0,
            label: "ownerConfig",
          },
          {
            loaded: permissions && Object.keys(permissions).length > 0,
            label: "permissions",
          },
        ]}
      />
    );

  return (
    <div className="container mt-3 mb-5">
      <div className="m-2" id="user-panel" style={{ display: "inline-flex" }}>
        <Typography variant="h5" component="h3">
          Configuraciones
        </Typography>

        {refetchLoader === "loading" ? (
          <span className="pl-2">
            <CircularProgress size="1.4em" />
          </span>
        ) : (
          <Typography variant="h5" component="p" className="pl-2">
            <FcSynchronize onClick={handleRefetch} />
          </Typography>
        )}
      </div>

      <Card>
        <div className="m-2" id="user-panel" style={{ display: "inline-flex" }}>
          <span className="pr-1">
            <b>
              <Chip label={`W${common.user_id}`} />
            </b>
          </span>

          <Typography variant="subtitle1" component="p">
            {user.fname} {user.lname}
          </Typography>
        </div>

        <CardContent>
          <FormControl component="fieldset">
            <FormLabel component="legend">Que aplicacion de imprimir usar?</FormLabel>
            <RadioGroup
              row
              aria-label="gender"
              name="printApp"
              value={userConfig.printApp}
              onChange={handlePrintAppChange}>
              <FormControlLabel
                value="RawBt"
                control={<Radio />}
                label={
                  <span>
                    <img alt="" src="/../../rawbt_24x24.png" /> RawBt
                  </span>
                }
              />
              <FormControlLabel
                value="QuickPrinter"
                control={<Radio />}
                label={
                  <span>
                    <img alt="" src="/../../quickprinter_24x24.png" /> Quick Printer
                  </span>
                }
              />
            </RadioGroup>
          </FormControl>
        </CardContent>
      </Card>

      <Divider className="m-3" />

      <RenderOwnerConfig
        route={route}
        permissions={permissions}
        ownerConfig={ownerConfig}
        handleOwnerConfigChange={handleOwnerConfigChange}
      />
    </div>
  );
};

export default UserConfig;
