import Rating from "@nodes/react-rating";
import { BsStar, BsStarFill } from "@nodes/react-icons/bs";

const RenderLoanRating = ({ rating }) => {
  if (!rating) return null;
  return (
    <Rating
      initialRating={rating}
      emptySymbol={<BsStar size="1.3em" className="text-danger" />}
      fullSymbol={<BsStarFill className="text-warning" size="1.3em" />}
      start={0}
      step={20}
      stop={100}
      readonly={true}
      quiet={true}
    />
  );
};

export default RenderLoanRating;
