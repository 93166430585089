import Typography from "@nodes/@material-ui/core/Typography";
import Box from "@nodes/@material-ui/core/Box";
import Card from "@nodes/@material-ui/core/Card";
import { phoneNumber } from "../constants";
import NumberFormat from "@nodes/react-number-format";
import { formatPhoneNumber } from "../functions";

const RenderPaymentAccount = ({ bankInformation: bank }) => {
  return (
    <div>
      <Typography variant="body1" paragraph>
        Para hacer un pago mediante transferencia/dep&oacute;sito o en persona, lo puede hacer a travez de los siguiente
        opciones:
      </Typography>
      <Typography variant="h6" gutterBottom>
        <b>1. Cuenta de Banco</b>
      </Typography>
      <Typography variant="subtitle1" className="text-info" paragraph>
        <b>
          Por favor, mandar la foto del comprobante de transferencia o deposito, sin comprobante no se aplica el pago!
        </b>
      </Typography>
      <Card variant="outlined" className="mb-2">
        <Box component="div" p={1}>
          <Typography variant="body1">Nombre.: {bank.BANK_OWNER_NAME}</Typography>
          <Typography variant="body1">
            N&uacute;mero de Cedula.:{" "}
            <NumberFormat value={bank.BANK_CEDULA} displayType={"text"} format="###-#######-#" />
          </Typography>

          <br />

          {bank?.BANKS_ACCOUNTS.map((item, index) => (
            <Typography key={index} variant="body1" gutterBottom>
              {index + 1}. {item.LABEL_NAME} No.: {item.ACC_NUMBER}
            </Typography>
          ))}
        </Box>
      </Card>
      <br />
      <Typography variant="h6" gutterBottom>
        <b>2. En Persona</b>
      </Typography>

      {bank.CASH_PERSON_NAME && (
        <Typography variant="body1" paragraph>
          En persona a {bank.CASH_PERSON_NAME}, en el callej&oacute;n de nolis detr&aacute;s del cementerio, se puede
          comunicar al n&uacute;mero de Tel:{" "}
          <a href={`tel:+1${bank.CASH_PERSON_PHONE}`}>{formatPhoneNumber(bank.CASH_PERSON_PHONE)}</a>.
        </Typography>
      )}
      <Typography variant="body1" gutterBottom>
        <i>
          Comuniquese con la administracion del sistema, en caso de que necesites hacer un acuerdo de pago o para
          cualquier pregunta al n&uacute;mero WhatsApp:
          <a style={{ color: "#000" }} href={`https://wa.me/+1${phoneNumber}`}>
            <b> {formatPhoneNumber(phoneNumber)}</b>
          </a>
        </i>
      </Typography>
    </div>
  );
};

export default RenderPaymentAccount;
