import Skeleton from "@nodes/@material-ui/lab/Skeleton";
import Card from "@nodes/@material-ui/core/Card";
import Box from "@nodes/@material-ui/core/Box";

const SkeletonElement = () => {
  return (
    <Box paddingBottom={1}>
      <Card raised>
        <table className="table table-sm">
          <tbody>
            <tr>
              <td>
                <Skeleton component="h2" />
              </td>
            </tr>
            <tr>
              <td>
                <Skeleton />
              </td>
            </tr>
            <tr>
              <td>
                <Skeleton />
              </td>
            </tr>
          </tbody>
        </table>
      </Card>
    </Box>
  );
};

export default SkeletonElement;
