import Box from "@nodes/@material-ui/core/Box";
import { useHistory } from "@nodes/react-router-dom";
import Typography from "@nodes/@material-ui/core/Typography";
import { string, object, ref } from "@nodes/yup";
import Card from "@nodes/@material-ui/core/Card";
import CardContent from "@nodes/@material-ui/core/CardContent";
import Button from "@nodes/@material-ui/core/Button";
import Container from "@nodes/@material-ui/core/Container";
import { Form, RenderInput } from "@shared/components/Form";
import { useParams } from "@nodes/react-router";
import Loading from "@shared/components/Loading";
import { UseUserPasswordReset } from "@shared/server/fetcher";
import { useAlert } from "react-alert";
import { resetPassword } from "@shared/api";

const PasswordNew = () => {
  const history = useHistory();
  const alert = useAlert();
  const { id: user_id, salt, time } = useParams();

  const { data: response, isLoading } = UseUserPasswordReset(user_id, salt, time);

  const handleResetPassword = async (data) => {
    try {
      await resetPassword(data);
      history.replace("/login");
      alert.info("Contraseña Guardada!");
    } catch (error) {
      console.log(error.message);
    }
  };

  if (isLoading) return <Loading />;

  if (!response.user_id)
    return (
      <Card className="mt-5 mb-5" raised>
        <CardContent>
          <Typography className="mt-3" component="p" variant="h5">
            Enlance Invalido!
          </Typography>

          <Typography className="mt-3 mb-5" component="p" variant="body1">
            Haga clic en el siguiente boton para ir a la pagina de Incio!
          </Typography>

          <Container maxWidth="xs">
            <Button fullWidth variant="contained" color="primary" onClick={() => history.replace("/")}>
              Ir a Inicio
            </Button>
          </Container>
        </CardContent>
      </Card>
    );

  const initialValues = {
    user_id,
    password: "",
    cpassword: "",
    salt,
    expTimestamp: time,
  };

  const validation = object({
    password: string()
      .required("Contraseña es requerida!")
      .min(7, "Nueva contraseña debe ser minimo 7 letras / numeros!")
      .max(25, "Nueva contraseña debe ser maximo 25 letras / numeros!"),
    cpassword: string()
      .required("Escriba la nueva contraseña nuevamente!")
      .oneOf([ref("password"), null], "Las 2 contraseña no coinciden!"),
  });

  const button = { label: "Guardar Contraseña", variant: "contained", color: "primary" };

  const submithandler = (data) => handleResetPassword(data);
  return (
    <div className="container mt-5 mb-5">
      <Typography variant="h6" component="h4" paragraph>
        Elija una nueva contraseña!
      </Typography>

      <Typography variant="subtitle1" component="p" gutterBottom>
        Su usuario es <b>{response.username}</b>
      </Typography>

      <Typography variant="subtitle1" component="p" paragraph>
        Escriba su nueva contraseña 2 veces en las siguientes casillas
      </Typography>

      <Form initialValues={initialValues} validation={validation} submithandler={submithandler} button={button}>
        <Box paddingBottom={3}>{RenderInput("password", "Nueva Contraseña", "password")}</Box>
        <Box paddingBottom={4}>{RenderInput("cpassword", "Nueva Contraseña", "password")}</Box>
      </Form>
      <br />
      <br />
    </div>
  );
};

export default PasswordNew;
