import Dialog from "@nodes/@material-ui/core/Dialog";
import DialogActions from "@nodes/@material-ui/core/DialogActions";
import DialogContent from "@nodes/@material-ui/core/DialogContent";
import DialogTitle from "@nodes/@material-ui/core/DialogTitle";
import Button from "@nodes/@material-ui/core/Button";
import ClearIcon from "@nodes/@material-ui/icons/Clear";
import CheckIcon from "@nodes/@material-ui/icons/Check";
import useMediaQuery from "../hooks/useMediaQuery";

export default function CropDialog({ open, onHide, children, onSave }) {
  const fullScreen = useMediaQuery("min-width:820px");
  return (
    <Dialog open={open} onClose={onHide} fullScreen={!fullScreen}>
      <DialogTitle id="crop-dialog-title">Cortar Vista Previa</DialogTitle>
      <DialogContent>{children}</DialogContent>

      <DialogActions>
        <Button startIcon={<ClearIcon />} onClick={onHide} color="secondary">
          Cancelar
        </Button>
        <Button startIcon={<CheckIcon />} onClick={onSave} color="primary" autoFocus>
          Guardar
        </Button>
      </DialogActions>
      <br />
    </Dialog>
  );
}
