// import FullLoadingScreen from "@shared/components/FullLoadingScreen";
import { useSelector } from "react-redux";
import RenderLoadingStates from "../components/RenderLoadingStates";
import { selectCommon } from "../store/slices/common";
import HomeIcon from "@material-ui/icons/Home";
import PeopleIcon from "@material-ui/icons/People";
import Typography from "@material-ui/core/Typography";
import SettingsApplicationsIcon from "@material-ui/icons/SettingsApplications";
import { FaUserAlt, FaUserCog, FaUserLock } from "react-icons/fa";
import {
  useGetDebtorsQuery,
  useGetOwnerConfigQuery,
  useGetPermissionsQuery,
  useGetRouteQuery,
  useGetUserConfigQuery,
  useGetUserQuery,
} from "../store/slices/api";

const SWRFetcher = () => {
  const common = useSelector(selectCommon);

  const { creditor_id, user_id, owner_id, collect_date } = common;

  const { data: routeData, status: routeStatus } = useGetRouteQuery({ creditor_id, user_id, collect_date });
  const { data: userData, status: userStatus } = useGetUserQuery(user_id);
  const { data: userConfigData, status: userConfigStatus } = useGetUserConfigQuery(user_id);
  const { data: ownerConfigData, status: ownerConfigStatus } = useGetOwnerConfigQuery(owner_id);
  const { data: permissionsData, status: permissionsStatus } = useGetPermissionsQuery({ creditor_id, user_id });
  const { data: debtorsData, status: debtorsStatus } = useGetDebtorsQuery({ creditor_id, user_id, collect_date });

  const data = [
    { icon: <HomeIcon />, label: "Cargando Ruta", status: routeData && routeStatus === "fulfilled" },
    {
      icon: <FaUserLock size="1.5em" />,
      label: "Cargando Permisos",
      status: permissionsData && permissionsStatus === "fulfilled",
    },
    {
      icon: <PeopleIcon />,
      label: "Cargando Clientes",
      status: debtorsData && debtorsStatus === "fulfilled",
    },
    {
      icon: <FaUserAlt size="1.4em" />,
      label: "Cargando Usuario",
      status: userData && userStatus === "fulfilled",
    },
    {
      icon: <FaUserCog size="1.7em" />,
      label: "Cargando Configuracion",
      status: userConfigData && userConfigStatus === "fulfilled",
    },
    {
      icon: <SettingsApplicationsIcon />,
      label: "Cargando Config Cuenta",
      status: ownerConfigData && ownerConfigStatus === "fulfilled",
    },
  ];

  return (
    <div className="container">
      <RenderLoadingStates data={data} />
      <Typography variant="body1" className="text-danger" paragraph>
        Por favor, mantengase conectado al internet hasta que esta pagina se quite automaticamente.
      </Typography>
    </div>
  );
};

export default SWRFetcher;
