import Card from "@nodes/@material-ui/core/Card";
import CardActions from "@nodes/@material-ui/core/CardActions";
import Divider from "@nodes/@material-ui/core/Divider";
// import Button from "@nodes/@material-ui/core/Button";
import CardHeader from "@nodes/@material-ui/core/CardHeader";
import Avatar from "@nodes/react-avatar";
import IconButton from "@nodes/@material-ui/core/IconButton";
import Typography from "@nodes/@material-ui/core/Typography";
import { FaHandHoldingUsd } from "@nodes/react-icons/fa";
import PersonIcon from "@nodes/@material-ui/icons/Person";
import { printDate, newDate } from "../functions";
import { useHistory } from "@nodes/react-router-dom";

const DebtorCard = ({ debtor }) => {
  const history = useHistory();
  return (
    <Card raised className="mb-1">
      <CardHeader
        avatar={<Avatar size={30} name={debtor.name} textSizeRatio={1.8} maxInitials={2} />}
        title={
          <Typography variant="body1" component="h5">
            {debtor.name}
          </Typography>
        }
        subheader={`Creado en ${printDate(newDate(debtor.created_date), "dd-MMM-y")}`}
      />
      <Divider />
      <CardActions disableSpacing>
        <IconButton
          aria-label="add sector"
          onClick={() => history.push(`/redirect/page/${debtor.dUniqueId}/0/${debtor.cedula}`)}>
          <FaHandHoldingUsd />
        </IconButton>
        <IconButton aria-label="share" onClick={() => history.push(`/debtor/profile/${debtor.dUniqueId}`)}>
          <PersonIcon />
        </IconButton>
      </CardActions>
    </Card>
  );
};

export default DebtorCard;
