import RenderUserProfile from "@shared/components/RenderUserProfile";
// import DisplayAppEnvName from "@shared/components/DisplayAppEnvName";
// import Loading from "@shared/components/Loading";
import { Link } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography ";
import { useSelector } from "react-redux";
import { useGetUserQuery } from "../store/slices/api";
import { selectCommon } from "../store/slices/common";

const Profile = () => {
  const common = useSelector(selectCommon);
  const { data: user } = useGetUserQuery(common.user_id);

  return (
    <div className="container mt-4 mb-5">
      {user ? (
        <RenderUserProfile user={user} />
      ) : (
        <Box component="div">
          <div className="alert alert-danger">
            <Typography variant="body2" component="p">
              Informacion de usuario no esta disponible!
            </Typography>
          </div>
        </Box>
      )}
    </div>
  );
};

export default Profile;
