import backend from "@shared/server/backend";

export const resetPassword = async (data) => {
  const response = await backend.post("/user/password/reset", { data });
  return response.data;
};

export const updatePassword = async (data) => {
  const response = await backend.post("/user/password", { data });
  return response.data;
};

export const updatePersonal = async (data) => {
  const response = await backend.post("/user/personalInfo", {
    data: { ...data, firstname: data.fname, lastname: data.lname },
  });

  return response.data;
};

export const updateUsername = async (data) => {
  const response = await backend.post("/user/username", { data });
  return response.data;
};

export const verifyAccessCode = async (data) => {
  const { accessCode, owner_id, type } = data;
  const response = await backend.post("/cobro/verify/accesscodes", { accessCode, owner_id, type });
  return response.data;
};

export const uploadDebuggingData = async (state) => {
  const response = await backend.post("/debugging", { state });
  return response.data;
};

export const updateDebtorCedula = async (debtor_id, { cedula }) => {
  const response = await backend.post("/save/debtor/cedula", { debtor_id, cedula });
  return response.data;
};

export const createReferralLink = async (data) => {
  const response = await backend.post("/referrals/link/create", { ...data });
  return response.data;
};

export const createOldReferral = async (data) => {
  const response = await backend.post("/referrals/old/create", { ...data });
  return response.data;
};

export const uploadCobroCuadre = async (data) => {
  const response = await backend.post("/v6/cobro/logout", { data });
  return response.data;
};
