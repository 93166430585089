// import { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import RenderBackButton from "@shared/components/RenderBackButton";
// import Button from "@material-ui/core/Button";
// import Loading from "@shared/components/Loading";
// import Box from "@material-ui/core/Box";
// import Divider from "@material-ui/core/Divider";
// import Button from "@material-ui/core/Button";
// import Box from "@material-ui/core/Box";
// import { dexieSyncDB } from "@shared/context/dexieDB";
// import { useLiveQuery } from "dexie-react-hooks";
// import { printAmount } from "@shared/functions";
// import TemporarilyDisabled from "@shared/pages/TemporarilyDisabled";
import { GoStop } from "react-icons/go";

const LoanCanNotRenew = ({ loan, restriction }) => {
  return (
    <div className="container text-center mt-5">
      <div className="mt-4 mb-4 text-warning">
        <GoStop size="8em" />
      </div>

      <Typography variant="h6" paragraph>
        No renovo permitido!
      </Typography>

      {restriction > 0 ? (
        <>
          <Typography variant="body1" paragraph>
            Este prestamo ahun no cumple con los requisitos para ser renovado!
          </Typography>

          <Typography variant="body1">
            <b>Puede renovar con {loan.npayments - restriction} cuotas pagada!</b>
          </Typography>
        </>
      ) : (
        <Typography variant="body1" paragraph>
          Por el momento no esta permitido realizar renovos!
        </Typography>
      )}

      <div className="mt-4 mb-2">
        <RenderBackButton />
      </div>
    </div>
  );
};

export default LoanCanNotRenew;
