import React, { useState } from "react";
import Menu from "@nodes/@material-ui/core/Menu";
import MenuItem from "@nodes/@material-ui/core/MenuItem";
import MoreVertIcon from "@nodes/@material-ui/icons/MoreVert";
import Divider from "@nodes/@material-ui/core/Divider";
import IconButton from "@nodes/@material-ui/core/IconButton";
import { FaRegMoneyBillAlt, FaHandHoldingUsd } from "@nodes/react-icons/fa";
import { AiOutlineFileSync } from "@nodes/react-icons/ai";
import VisibilityIcon from "@nodes/@material-ui/icons/Visibility";

export default function LoanMenu({ loan, history }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton aria-label="settings" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem
          className="text-success"
          onClick={() => history.push(`/payment/create/${loan.money_id}/${loan.debtor_id}`)}>
          <FaRegMoneyBillAlt size="1.5em" className="pr-1" />
          Aplicar Pago
        </MenuItem>
        <Divider />
        <MenuItem
          className="text-warning"
          onClick={() => history.push(`/redirect/page/${loan.dUniqueId}/${loan.money_id}/${loan.cedula}`)}>
          <AiOutlineFileSync size="1.4em" className="pr-1" />
          Renovar Prestamo
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => history.push(`/redirect/page/${loan.dUniqueId}/0/${loan.cedula}`)}>
          <FaHandHoldingUsd size="1.4em" className="pr-1" />
          Nuevo Prestamo!
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => history.push(`/loan/detail/${loan.money_id}/${loan.debtor_id}`)}
          className="text-primary">
          <VisibilityIcon className="pr-1" />
          Prestamo Detalle
        </MenuItem>
      </Menu>
    </div>
  );
}
