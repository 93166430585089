import { useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import { useParams } from "react-router";
import { Redirect, Link } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import useMediaQuery from "@shared/hooks/useMediaQuery";
import Loading from "@shared/components/Loading";
import RenderCustomerBalance from "@shared/components/RenderCustomerBalance";
import RenderPaymentAccount from "@shared/components/RenderPaymentAccount";
import RenderContactInfo from "@shared/components/RenderContactInfo";
import "@shared/pages/styles/invoice.css";
import { useDispatch } from "react-redux";
import { UseCustomerAllowAccess, UseBankInformation } from "@shared/server/fetcher";
import { unSetCustomerId } from "../store/slices/auth";

const CustomerDisabled = () => {
  const dispatch = useDispatch();
  const { id: owner_id } = useParams();
  const { data: customer, isLoading } = UseCustomerAllowAccess(owner_id);
  const { data: bankInformation, isLoading: bankLoading } = UseBankInformation(owner_id);
  const fullScreen = useMediaQuery("min-width:820px");

  useEffect(() => {
    dispatch(unSetCustomerId());
  }, []);

  if (isLoading || bankLoading) return <Loading />;

  if (customer && customer.action === "navigate") return <Redirect to="/login" />;

  return (
    <div className="page-content container mt-3 mb-5">
      <div className="page-header text-blue-d2">
        <h1 className="page-title text-secondary-d1">
          Factura
          {customer.invoice_id && (
            <small className="page-info">
              <i className="fa fa-angle-double-right text-80"></i> # {customer.invoice_id}
            </small>
          )}
        </h1>
        <br />
      </div>

      <Alert variant="outlined" severity="error">
        <Typography variant="h6" component="p">
          <b>Atencion!</b>
        </Typography>
        <Typography variant="body1" component="p">
          Ruta desabilitada por falta de pago!
        </Typography>
      </Alert>

      <RenderCustomerBalance data={customer} />

      <RenderPaymentAccount bankInformation={bankInformation} />

      <hr />

      <Card variant="outlined">
        <Box p={2}>
          <RenderContactInfo />
        </Box>
      </Card>

      <Box component="div" p={2}>
        <Link to={"/login"}>
          <Button fullWidth={!fullScreen} variant="outlined" color="secondary">
            Volver Atras
          </Button>
        </Link>
      </Box>
    </div>
  );
};

export default CustomerDisabled;
