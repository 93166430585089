import CardHeader from "@nodes/@material-ui/core/CardHeader";
import Typography from "@nodes/@material-ui/core/Typography";
import PersonIcon from "@nodes/@material-ui/icons/Person";

const DebtorNameHeader = ({ debtor, children }) => {
  return (
    <CardHeader
      action={children}
      title={
        <Typography variant="body1" component="p" className="font-weight-bold">
          <PersonIcon /> {debtor.name} {debtor.nickname && `(${debtor.nickname})`}
        </Typography>
      }
    />
  );
};

export default DebtorNameHeader;
