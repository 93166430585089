
import LinearProgress from "@nodes/@material-ui/core/LinearProgress";
import Typography from "@nodes/@material-ui/core/Typography";
import Box from "@nodes/@material-ui/core/Box";
import { makeStyles } from "@nodes/@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    height: 9,
  },
  stickToBottom: {
    backgroundColor: "#e4e4e4",
    width: "100%",
    position: "fixed",
    bottom: 0,
  },
});

export default function LinearProgressWithLabel(props) {
  const classes = useStyles();
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress className={classes.root} variant="determinate" {...props} color="primary" />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">
          <strong>
            <b>{`${Math.floor(props.value)}%`}</b>
          </strong>
        </Typography>
      </Box>
    </Box>
  );
}
