import { useState } from "react";
import Modal from "@nodes/react-bootstrap/Modal";
import Typography from "@nodes/@material-ui/core/Typography";
import Box from "@nodes/@material-ui/core/Box";
import { Form, RenderInput } from "./Form";
import { number, object } from "@nodes/yup";
import CreateIcon from "@nodes/@material-ui/icons/Create";

const UpdateBankamount = ({ common, updateBankAmount }) => {
  const [showModal, setShowModal] = useState(false);

  const initialValues = {
    creditor_id: common.creditor_id,
    current_week: common.today_date,
    bankAmount: common.bank_amount === 0 ? "" : common.bank_amount,
  };

  const validation = object({
    bankAmount: number()
      .required("Cantidad es requerida!")
      .min(0, "Cantidad es muy corta!")
      .max(999999, "Cantidad es muy alta!"),
  });

  const button = { label: "Actualizar", variant: "contained", color: "primary" };
  const submithandler = (data) => {
    if (+data.bankAmount !== +common.bank_amount) {
      updateBankAmount(data);
    }
    setShowModal(false);
  };

  return (
    <>
      <span className="p-1 text-primary" onClick={() => setShowModal(!showModal.show)}>
        <CreateIcon />
      </span>

      <Modal show={showModal} onHide={() => setShowModal(false)} backdrop="static" keyboard={false} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <Typography variant="h5" className="text-primary">
              Caja / Banco
            </Typography>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container mb-4">
            <Typography variant="body1" paragraph>
              Escriba hacia abajo la cantidad de la caja / banco.
            </Typography>

            <Form initialValues={initialValues} validation={validation} submithandler={submithandler} button={button}>
              <Box paddingBottom={3}>
                {RenderInput("bankAmount", "Cantidad de Banco / Caja", "number", true, "outlined")}
              </Box>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={() => setShowModal(false)}>
            Cerrar
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UpdateBankamount;
