import Container from "@nodes/@material-ui/core/Container";
import Typography from "@nodes/@material-ui/core/Typography";
import Box from "@nodes/@material-ui/core/Box";

const PendingDataWarning = ({ pendingData }) => {
  return (
    <Container className="mt-5 mb-5 p-2">
      <Box component="div" p={1}>
        <Typography variant="h5" component="h5" paragraph>
          <b>Atenci&oacute;n!</b>
        </Typography>

        <Typography variant="body1" component="p" paragraph>
          Este dispositivo a&uacute;n tiene {pendingData?.length} dato(s) que son requeridos sin guardar.
        </Typography>

        <Typography variant="body1" component="p" paragraph>
          Por favor, mant&eacute;ngase conectado al internet hasta que esta pagina desaparezca automaticamente.
        </Typography>

        <Typography variant="body1" component="p">
          Si necesita ayuda no dude en escribirme a mi Whatsapp
          <br />
          <br />
          <a href="https://wa.me/13473892028">
            <b>+1 (347) 389-2028</b>
          </a>
          <br />
          <br />
          Gracias, Wilson B
        </Typography>
      </Box>
    </Container>
  );
};

export default PendingDataWarning;
