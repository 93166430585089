import { createSlice } from "@reduxjs/toolkit";
import { fetchCommonThunk } from "./common";

const initialState = {
  cuadre_id: null,
  gastos: [],
  total: 0,
};

export const gastosSlice = createSlice({
  name: "gastos",
  initialState,
  reducers: {
    // resetGastos: () => initialState,
    createGasto: (state, { payload }) => {
      const { description, amount, gUniqueId } = payload;
      state.gastos.unshift({ description, amount, gUniqueId });
      state.total += +payload.amount;
    },

    deleteGasto: (state, action) => {
      state.gastos.map((x) => {
        if (x.gUniqueId === action.payload.gUniqueId) {
          x.deleted = true;

          state.total -= +action.payload.amount;
        }
      });
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchCommonThunk.fulfilled, (state, { payload }) => {
      if (state.cuadre_id === payload.cuadre_id) return state;
      return { ...initialState, cuadre_id: payload.cuadre_id };
    });
  },
});

export const selectTotalGastos = (state) => state.gastos.total;
export const selectGastos = (state) => state.gastos.gastos.filter((x) => !x.deleted);
export const selectGastosAll = (state) => state.gastos.gastos;

export const { createGasto, deleteGasto } = gastosSlice.actions;
export default gastosSlice.reducer;
