import { useState } from "react";
import { mixed, addMethod, string, object } from "@nodes/yup";
import { Form, RenderInput } from "@shared/components/Form";
import Box from "@nodes/@material-ui/core/Box";
import Alert from "@nodes/@material-ui/lab/Alert";
import Modal from "@nodes/react-bootstrap/Modal";
import Card from "@nodes/@material-ui/core/Card";
import Divider from "@nodes/@material-ui/core/Divider";
import CardHeader from "@nodes/@material-ui/core/CardHeader";
import CardContent from "@nodes/@material-ui/core/CardContent";
import RenderCedulaImage from "@shared/components/RenderCedulaImage";
import Grid from "@nodes/@material-ui/core/Grid";
import NumberFormat from "@nodes/react-number-format";
import Typography from "@nodes/@material-ui/core/Typography";
import RenderLoanDetailCredit from "@shared/components/RenderLoanDetailCredit";
import RenderRouteDetailCredit from "@shared/components/RenderRouteDetailCredit";
import DisplayLoanStatus from "@shared/components/DisplayLoanStatus";
import { CgSearchLoading } from "@nodes/react-icons/cg";
import Loading from "@shared/components/Loading";
import { printAmount, getLoansBreakDown, validateCedula, validatePhone, replaceNonDigits } from "@shared/functions";
import RenderCreditCheckLoans from "@shared/components/RenderCreditCheckLoans";
import { UseDebtorCedulaData, UseDebtorCedulaImg } from "@shared/server/fetcher";
import backend from "@shared/server/backend";
import { useGetRouteQuery } from "../store/slices/api";
import { selectCommon } from "../store/slices/common";
import { useSelector } from "react-redux";

const dataCreditSearch = async (creditor_id, cedula, name, phone) => {
  cedula = cedula ? cedula : "c";
  name = name ? name : "ced";
  phone = phone ? phone : "p";
  try {
    const { data } = await backend.get(`/debtor/loans/checkv2/${creditor_id}/${cedula}/${name}/${phone}`);
    return data;
  } catch (err) {
    console.log("AppWorkflow - updateDebtor from dataContext - Catch error");
    console.log(err.message);
  }
};

const DataCreditoSearch = () => {
  addMethod(mixed, "validateCedula", validateCedula);
  addMethod(mixed, "validatePhone", validatePhone);
  const [modalData, setModalData] = useState({});
  const [loansBreakDown, setLoansBreakDown] = useState(null);
  const [cedula, setCedula] = useState(null);

  const common = useSelector(selectCommon);

  const { data: cedulaData } = UseDebtorCedulaData(cedula);
  const { data: cedulaImage, isLoading: cedulaLoading } = UseDebtorCedulaImg(cedula, cedulaData?.timestamp);

  const { data: route } = useGetRouteQuery({
    creditor_id: common.creditor_id,
    user_id: common.user_id,
    collect_date: common.collect_date,
  });

  if (!route) return <Loading />;

  const initialValues = {
    name: "",
    phone: "",
    cedula: "",
  };

  const validation = object({
    cedula: mixed().validateCedula(),
  });

  const validation2 = object({
    name: string().required("Nombre es requerido!").min(3, "Nombre es muy corto!"),
    phone: mixed().validatePhone(),
  });

  const button = {
    icon: <CgSearchLoading size="1.3em" />,
    label: "Buscar Data Credito",
    variant: "contained",
    color: "primary",
  };

  const submithandler = async (data) => {
    const cleanedCedula = replaceNonDigits(data.cedula);
    if (+cleanedCedula > 0) {
      setCedula(cleanedCedula);
    }
    const loans = await dataCreditSearch(route.creditor_id, data.cedula, data.name, data.phone);
    const res = getLoansBreakDown(loans);
    setLoansBreakDown(res);
  };

  return (
    <div className="container mt-5 mb-5">
      <Typography variant="h5" component="h3" gutterBottom>
        Data Credito Interno!
      </Typography>

      <Typography variant="subtitle1" component="p" paragraph>
        Aqui puede buscar un cliente en el data Credito Interno para ver prestamos registrado para ese mismo cliente.
      </Typography>

      {cedulaData && (
        <div className="container mt-3">
          <Typography variant="body1" component="p" gutterBottom>
            <b>{cedulaData.name}</b>
          </Typography>
        </div>
      )}

      {cedula && <RenderCedulaImage cedula={cedulaImage} loading={cedulaLoading && cedulaData ? true : false} />}

      <Typography variant="h6" component="p" gutterBottom align="center">
        <b>{<NumberFormat value={cedula} displayType={"text"} format="###-#######-#" />}</b>
      </Typography>

      {loansBreakDown?.activeCount === 0 &&
        loansBreakDown?.saldadoCount === 0 &&
        loansBreakDown?.vencidosCount === 0 && (
          <Box component={"div"} p={1}>
            <Alert variant="outlined" severity="info">
              <Typography variant="h6" component="p">
                No prestamos encontrado!
              </Typography>
            </Alert>
          </Box>
        )}

      {loansBreakDown && (
        <>
          <Grid container>
            <Grid item xs={8}>
              <Typography variant="subtitle1" component="p">
                Deuda Total:
              </Typography>
              <Typography variant="h5" component="h3" className="font-weight-bold">
                {printAmount(loansBreakDown?.balanceTotal || 0)}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1" component="p">
                Monto Total:
              </Typography>
              <Typography variant="h5" component="h3" className="font-weight-bold">
                {printAmount(loansBreakDown?.wPaymentTotal || 0)}
              </Typography>
            </Grid>
          </Grid>

          <Divider />
          <Divider />
          <br />
        </>
      )}

      <RenderCreditCheckLoans loansBreakDown={loansBreakDown} setModalData={setModalData} />

      <Grid container spacing={4}>
        <Grid item xs={12} lg={6}>
          <Card>
            <CardHeader title="1. Busqueda con C&eacute;dula" />
            <CardContent>
              <Form initialValues={initialValues} validation={validation} submithandler={submithandler} button={button}>
                <Box paddingBottom={5}>{RenderInput("cedula", "Numero de Cedula", "tel")}</Box>
              </Form>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} lg={6}>
          <Card>
            <CardHeader title="2. Busqueda con N&uacute;mero" />
            <CardContent>
              <Form
                initialValues={initialValues}
                validation={validation2}
                submithandler={submithandler}
                button={button}>
                <Box paddingBottom={3}>{RenderInput("name", "Nombre Completo")}</Box>
                <Box paddingBottom={5}>{RenderInput("phone", "Numero de Telefono", "tel")}</Box>
              </Form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Modal
        show={modalData.name ? true : false}
        onHide={() => setModalData({})}
        backdrop="static"
        keyboard={false}
        centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <Typography variant="h6">
              {modalData.name}
              {/* <b className="p-2">
                    <Chip label={`C${modalData.debtor_id}`} />
                  </b> */}
            </Typography>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Box component="div" p={1}>
            <DisplayLoanStatus loan={modalData} />
          </Box>

          <Box component="div" p={1}>
            <RenderLoanDetailCredit loan={modalData} />
          </Box>

          {((modalData.balance > 0 && modalData.current_week > modalData.npayments) ||
            modalData.user_id === route.user_id) && (
            <Box component="div" p={1}>
              <RenderRouteDetailCredit loan={modalData} />
            </Box>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={() => setModalData({})}>
            Cerrar
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DataCreditoSearch;
