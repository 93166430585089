import { useState, useEffect, useCallback, useRef } from "react";
import FloatingButton from "@shared/components/FloatingButton";
import SearchBox from "@shared/components/common/SearchBox";
import DebtorCard from "@shared/components/DebtorCard";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
// import { BiCloudDownload } from "react-icons/bi";
import { RemoveAccents, indentifySearchType } from "@shared/functions";
import useDebounce from "@shared/hooks/useDebounce";
// import SubmitButton from "@shared/components/SubmitButton";
// import Loading from "@shared/components/Loading";
import LoadingInspect from "@shared/components/LoadingInspect";
import { useSelector } from "react-redux";
import { selectNewDebtors } from "../store/slices/newDebtors";
import { selectCommon } from "../store/slices/common";
import { useGetDebtorsQuery } from "../store/slices/api";
import { FcSynchronize } from "react-icons/fc";
// import { UseDebtors } from "@shared/server/fetcher";
import CircularProgress from "@nodes/@material-ui/core/CircularProgress";
import { useAlert } from "react-alert";
import useData from "../Hooks/useData";
import { pouches_db } from "../config";

const Debtors = () => {
  const alert = useAlert();
  const [refetchLoader, setRefetchLoader] = useState("idle");
  const newDebtors = useSelector(selectNewDebtors);
  const { data: newDebtors2, isLoading: d_loading } = useData(pouches_db.NEW_DEBTORS);
  const common = useSelector(selectCommon);

  const [searchText, setSearchText] = useState("");
  const [filteredDebtors, setFilteredDebtors] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);

  const { data: debtors, refetch } = useGetDebtorsQuery({
    creditor_id: common.creditor_id,
    user_id: common.user_id,
    collect_date: common.collect_date,
  });

  // const { data: debtors2 } = UseDebtors(common.creditor_id, common.user_id);

  const loader = useRef(null);
  const debtorsXPage = 10;

  const handleObserver = useCallback((entries) => {
    const target = entries[0];
    if (target.isIntersecting) {
      setPage((prev) => prev + 1);
    }
  }, []);

  useEffect(() => {
    if (!debtors) {
      refetch();
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver);
    if (loader.current) observer.observe(loader.current);
  }, [handleObserver]);

  useDebounce(
    () => {
      if (!debtors) return;
      if (searchText.length < 3) {
        setFilteredDebtors([...newDebtors2, ...newDebtors, ...debtors].slice(0, page * debtorsXPage));
        setLoading(false);
      } else {
        setFilteredDebtors(
          [...newDebtors2, ...newDebtors, ...debtors]
            .filter(
              (item) =>
                RemoveAccents(item.debtorSearch).toLowerCase().indexOf(indentifySearchType(searchText.toLowerCase())) >
                -1
            )
            .slice(0, debtorsXPage + 10)
        );
      }
    },
    [debtors, newDebtors, searchText, page],
    searchText.length < 3 ? 0 : 800
  );

  const handleRefetch = async () => {
    setRefetchLoader("loading");

    const res = await refetch();

    if (res.status === "fulfilled") {
      alert.success("Clientes actualizado!", { timeout: 4000, position: "bottom center" });
    } else {
      alert.error("Error, intente de nuevo!", { timeout: 4000, position: "bottom center" });
    }

    setRefetchLoader("completed");
  };

  const handleSearch = (query) => {
    setSearchText(query);
  };

  return (
    <div className="mb-3">
      {/* <div className="mb-5">Debtors 1: {debtors?.length}</div>
      <div className="mb-5">Debtors 2: {debtors2?.length}</div> */}
      <FloatingButton icon={<PersonAddIcon />} tooltip="Agregar Cliente" goto="/cedula/search" />
      <div style={{ minHeight: "110%" }}>
        {debtors?.length === 0 ? (
          <div className="container mt-5 mb-5">
            <div className="m-2" id="user-panel" style={{ display: "inline-flex" }}>
              <Typography variant="h5" componet="h3" gutterBottom>
                No ay clientes registrado!
              </Typography>

              {refetchLoader === "loading" ? (
                <span className="pl-2">
                  <CircularProgress size="1.4em" />
                </span>
              ) : (
                <Typography variant="h5" component="p" className="pl-2">
                  <FcSynchronize onClick={handleRefetch} />
                </Typography>
              )}
            </div>

            <Typography variant="body1" componet="p" paragraph>
              En esta pagina puede ver todo los clientes.
            </Typography>
            <Typography variant="body1" componet="p" paragraph>
              Por el momento, no tiene ningun cliente!
            </Typography>
          </div>
        ) : (
          <>
            {loading || d_loading ? (
              <LoadingInspect
                data={[
                  {
                    loaded: loading === true,
                    label: "Main State",
                  },
                  {
                    loaded: debtors && debtors.length > 0,
                    label: "Debtors",
                  },
                ]}
              />
            ) : (
              <div className="mt-3 mb-5">
                <Box component="div" p={1}>
                  <div className="m-2" id="user-panel" style={{ display: "inline-flex" }}>
                    <Typography variant="h5" componet="h3">
                      Lista de Clientes ({debtors?.length})
                    </Typography>

                    {refetchLoader === "loading" ? (
                      <span className="pl-2">
                        <CircularProgress size="1.4em" />
                      </span>
                    ) : (
                      <Typography variant="h5" component="p" className="pl-2">
                        <FcSynchronize onClick={handleRefetch} />
                      </Typography>
                    )}
                  </div>

                  <SearchBox onChange={handleSearch} placeHolder="Escriba para buscar clientes..." value={searchText} />
                </Box>

                <Grid container>
                  {filteredDebtors.map((debtor) => (
                    <Grid key={debtor.dUniqueId} item xs={12}>
                      <DebtorCard debtor={debtor} />
                    </Grid>
                  ))}
                </Grid>
              </div>
            )}
          </>
        )}
      </div>

      <Box ref={loader} component="div" p={2}>
        {!loading && searchText.length < 3 && debtors?.length > page * debtorsXPage && (
          <Typography variant="h5" paragraph>
            Por favor espere...
          </Typography>
        )}
      </Box>
    </div>
  );
};

export default Debtors;
