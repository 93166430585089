import React, { useState } from "react";
import IconButton from "@nodes/@material-ui/core/IconButton";
import OutlinedInput from "@nodes/@material-ui/core/OutlinedInput";
import InputLabel from "@nodes/@material-ui/core/InputLabel";
import InputAdornment from "@nodes/@material-ui/core/InputAdornment";
import FormControl from "@nodes/@material-ui/core/FormControl";
import Visibility from "@nodes/@material-ui/icons/Visibility";
import VisibilityOff from "@nodes/@material-ui/icons/VisibilityOff";

export default function Password({ name, label, password, setPassword }) {
  const [showPassword, setShowPassword] = useState(false);
  const [lostFocus, setLostFocus] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (e) => {
    setPassword(e.target.value);
    if (password.length > 0) setLostFocus(false);
  };

  const handleBlur = () => {
    if (password.length === 0) setLostFocus(true);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <OutlinedInput
        autoComplete="false"
        id={name}
        error={lostFocus ? true : false}
        name={name}
        type={showPassword ? "text" : "password"}
        value={password}
        onChange={handleChange}
        onBlur={handleBlur}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end">
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
        labelWidth={80}
      />
    </FormControl>
  );
}
