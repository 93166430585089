import { useState, useEffect } from "@nodes/react";
import Alert from "@nodes/@material-ui/lab/Alert";
import Typography from "@nodes/@material-ui/core/Typography";
import Box from "@nodes/@material-ui/core/Box";

const StorageQoutaWarning = () => {
  const [displayWarning, setDisplayWarning] = useState(false);
  const alertLimit = 25; //in Megabytes

  async function checkAlvailableStorage() {
    if (navigator.storage && navigator.storage.estimate) {
      const quota = await navigator.storage.estimate();
      // quota.usage -> Number of bytes used.
      // quota.quota -> Maximum number of bytes available.
      //   const percentageUsed = (quota.usage / quota.quota) * 100;
      //   console.log(`You've used ${percentageUsed}% of the available storage.`);
      const remaining = Math.floor((quota.quota - quota.usage) / 1e6);
      if (remaining < alertLimit) {
        console.log(`Available Storage too low: ${remaining} Megabytes.`);
        setDisplayWarning(true);
      }
    }
  }

  useEffect(() => {
    checkAlvailableStorage();
  }, []);

  if (displayWarning === false) return null;
  return (
    <Box component="div" p={1}>
      <Alert variant="outlined" severity="error">
        <Typography variant="body1" component="p">
          Almacenamiento disponible no suficiente!
        </Typography>
      </Alert>
    </Box>
  );
};

export default StorageQoutaWarning;
