import { useParams } from "@nodes/react-router";
import Card from "@nodes/@material-ui/core/Card";
import CardHeader from "@nodes/@material-ui/core/CardHeader";
import CardContent from "@nodes/@material-ui/core/CardContent";
import Box from "@nodes/@material-ui/core/Box";
import Divider from "@nodes/@material-ui/core/Divider";
import { string, ref, object } from "@nodes/yup";
import { Form, RenderInput } from "@shared/components/Form";
import Typography from "@nodes/@material-ui/core/Typography";
import { useHistory } from "@nodes/react-router-dom";
import RenderBackButton from "@shared/components/RenderBackButton";
import { useAlert } from "react-alert";
import { updatePassword } from "@shared/api";

const UserPassword = () => {
  const alert = useAlert();
  const history = useHistory();
  const { id } = useParams();

  const initialPasswordValues = {
    user_id: id,
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  };

  const validationPassword = object({
    currentPassword: string().required("Ingrese su contraseña actual!"),
    newPassword: string()
      .required("Contraseña es requerida!")
      .min(5, "Su contraseña debe ser minimo 7 letras / numeros!")
      .max(25, "Su contraseña debe ser maximo 25 letras / numeros!"),
    confirmNewPassword: string()
      .required("Vuelva a ingresar la misma contraseña!")
      .oneOf([ref("newPassword"), null], "Ingrese la misma contraseña 2 veces!"),
  });

  const buttonPassword = {
    label: "Actualizar Contraseña",
    variant: "contained",
    color: "primary",
  };

  const submithandlerPassword = async (data) => {
    try {
      await updatePassword(data);
      history.replace("/profile");
      alert.info("Contraseña Actualizada!");
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div className="mt-5">
      <Card raised>
        <CardHeader title="Contraseña" />
        <CardContent>
          <Typography variant="body1" paragraph>
            En esta pagina puede cambiar la contrase&ntilde;a!
          </Typography>
          <div>
            <Form
              initialValues={initialPasswordValues}
              validation={validationPassword}
              submithandler={submithandlerPassword}
              button={buttonPassword}>
              <Box paddingBottom={3}>{RenderInput("currentPassword", "Contraseña Actual", "password")}</Box>
              <Divider />
              <Box paddingBottom={2}>{RenderInput("newPassword", "Nueva Contraseña", "password")}</Box>
              <Box paddingBottom={4}>{RenderInput("confirmNewPassword", "Confirme Nueva Contraña", "password")}</Box>
            </Form>
            <div className="mt-4">
              <RenderBackButton />
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default UserPassword;
