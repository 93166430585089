import { useState, useEffect } from "react";
import CircularProgress from "@nodes/@material-ui/core/CircularProgress";
import Button from "@nodes/@material-ui/core/Button";

const SubmitButton = ({ text, startIcon, color = "primary", callBack, autoFetch = true }) => {
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    let isMounted = true;

    if (autoFetch) {
      handleRequest();
    }

    return () => {
      isMounted = false; // Cleanup flag on unmount
    };

    async function handleRequest() {
      setLoading(true);
      await callBack();
      if (isMounted) setLoading(false); // Update state only if mounted
    }
    // eslint-disable-next-line
  }, []);

  const handleOnClick = async () => {
    let isMounted = true;

    async function handleRequest() {
      setLoading(true);
      await callBack();
      if (isMounted) setLoading(false); // Update state only if mounted
    }

    handleRequest();

    return () => {
      isMounted = false; // Cleanup flag on unmount
    };
  };

  return (
    <Button
      fullWidth
      disabled={isLoading}
      color={color}
      startIcon={isLoading ? <CircularProgress size="1rem" /> : startIcon}
      onClick={handleOnClick}
      variant="contained">
      {isLoading ? "Por favor, espere!" : text}
    </Button>
  );
};

export default SubmitButton;
