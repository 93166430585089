import { useState, useEffect, useCallback } from "react";
import PouchDB from "pouchdb-browser";

const cache = {}; // Simple in-memory cache
const cacheTimestamps = {}; // Store cache timestamps to manage staleness

/**
 * Custom hook to fetch data from PouchDB with stale-while-revalidate.
 * @param {string} dbName - The PouchDB database name.
 * @param {string|null} [searchId=null] - The ID to search for. If null, fetches all documents.
 * @param {object} [options={}] - PouchDB query options.
 * @param {number} [cacheTime=300000] - Cache validity duration in milliseconds (default 5 minutes).
 * @returns {{ data: any, isLoading: boolean, refresh: Function, error: Error|null }} - Data, loading state, refresh function, and error state.
 */
export default function useData(dbName, newConfigs) {
  const configs = { defaultValue: [], _id: null, options: {}, cacheTime: 600000, ...newConfigs };

  const { _id, defaultValue, options, cacheTime } = configs;

  const [data, setData] = useState(defaultValue);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const cacheKey = `${dbName}:${_id || "all"}`;

  // Define fetchData outside of useEffect so it can be reused
  const fetchData = useCallback(async () => {
    const db = new PouchDB(dbName);

    // Return cached data immediately if available and valid
    if (cache[cacheKey] && Date.now() - cacheTimestamps[cacheKey] < cacheTime) {
      setData(cache[cacheKey]);
    } else {
      setLoading(true);
    }

    // Fetch fresh data to update the cache
    try {
      let freshData;

      if (_id) {
        // Fetch by ID
        freshData = await db.get(_id, options);
      } else {
        // Fetch all documents
        const result = await db.allDocs({ include_docs: true, ...options });
        freshData = options?.include_deleted
          ? result.rows.map((row) => row.doc)
          : result.rows.map((row) => row.doc).filter((x) => !x.deleted);
      }

      cache[cacheKey] = freshData; // Update cache
      cacheTimestamps[cacheKey] = Date.now(); // Update timestamp

      setData(freshData);
      setError(null);
      setLoading(false);
    } catch (err) {
      setError(err);
      setData(null);
      setLoading(false);
    }
  }, [cacheKey, cacheTime, dbName, options, _id]);

  useEffect(() => {
    let isMounted = true;

    // Wrap fetchData to ensure it respects component mounting
    const fetchDataIfMounted = async () => {
      if (isMounted) {
        await fetchData();
      }
    };

    fetchDataIfMounted();

    return () => {
      isMounted = false; // Prevent state updates after unmounting
    };
  }, [dbName, _id, cacheTime]);

  return { data, isLoading, refresh: fetchData, error };
}
