import { useState, useEffect } from "react";
// import Check from "@nodes/@material-ui/icons/Check";
import ErrorOutlineIcon from "@nodes/@material-ui/icons/ErrorOutline";
import Loading from "@shared/components/Loading";

const LoadingInspect = ({ data = [] }) => {
  const [displaySpinner, setDisplaySpinner] = useState(true);

  useEffect(() => {
    const interval = setTimeout(() => {
      setDisplaySpinner(false);
    }, 4000);

    return () => clearTimeout(interval);
  }, []);

  if (displaySpinner) return <Loading />;

  return (
    <div className="mt-5 d-flex justify-content-center">
      {data.map((item) =>
        item.loaded === true ? null : (
          <div key={item.label} className="pr-2">
            <h6>{item.label}</h6>
            <ErrorOutlineIcon className="text-danger" />
          </div>
        )
      )}
    </div>
  );
};

export default LoadingInspect;
