import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoute";
import Index from "./pages/Index";
import Logout from "./pages/Logout";
import Profile from "./pages/Profile";
import ClearAppData from "./pages/ClearAppData";
import UploadSyncState from "./pages/UploadSyncState";
import UploadAppState from "./pages/UploadAppState";
import Syncronization from "./pages/Syncronization";
import Contact from "./pages/Contact";

const CuadreRoute = () => {
  const history = useHistory();

  return (
    <>
      <Switch>
        <ProtectedRoute path="/profile" render={(props) => <Profile {...props} />} />
        <Route path="/logout" render={(props) => <Logout {...props} />} />
        <Route path="/upload/app/state" render={(props) => <UploadAppState {...props} />} />
        <Route path="/upload/app/sync" render={(props) => <UploadSyncState {...props} />} />
        <Route path="/clear/app/data/:reloaded?" render={(props) => <ClearAppData {...props} />} />
        <Route path="/Syncronization" render={(props) => <Syncronization {...props} />} />
        <Route path="/contact" render={(props) => <Contact {...props} />} />
        <Route path="/" exact render={(props) => <Index {...props} />} />
        <Redirect
          to={{
            pathname: "/logout",
            state: { from: history.location.pathname },
          }}
        />
      </Switch>
    </>
  );
};

export default CuadreRoute;
