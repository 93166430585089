import { useState } from "react";
import { formatPhoneNumber } from "../functions";
import Menu from "@nodes/@material-ui/core/Menu";
import MenuItem from "@nodes/@material-ui/core/MenuItem";
import { AiOutlinePhone, AiOutlineWhatsApp } from "@nodes/react-icons/ai";
import { FcPhone } from "@nodes/react-icons/fc";

const RenderPhoneNumber = ({ phone }) => {
  //   if (!phone) return "No Telefono";

  const [anchorEl, setAnchorEl] = useState(null);

  const toggleMenuDisplay = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePhoneCall = () => {
    let url = `tel:1${phone}`;
    window.location.href = url;
  };

  const hanldeWhatsappCall = () => {
    const url = `https://wa.me/1${phone}`;
    window.location.href = url;
  };

  return (
    <div>
      <span aria-controls="simple-menu" aria-haspopup="true" onClick={toggleMenuDisplay}>
        <FcPhone size="1.4em" /> {formatPhoneNumber(phone)}
      </span>

      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handlePhoneCall}>
          <AiOutlinePhone size="1.4em" /> Llamar {formatPhoneNumber(phone)}
        </MenuItem>
        <MenuItem onClick={hanldeWhatsappCall}>
          <AiOutlineWhatsApp size="1.4em" /> WhatsApp {formatPhoneNumber(phone)}
        </MenuItem>
      </Menu>
    </div>
  );
};

export default RenderPhoneNumber;
